import React, { useState } from "react";
import dayjs from "dayjs";

import { Button, Grid, Typography, Avatar, useTheme } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLineUpDown, faAbacus } from "@fortawesome/pro-solid-svg-icons";

import {
  LoadingButton,
  TextField,
  AnimatedLogo,
  Checkbox,
} from "@aclymatepackages/atoms";
import { sumTonsCo2e } from "@aclymatepackages/other-helpers";
import { ucFirstLetters } from "@aclymatepackages/formatters";

import CollapsibleAlert from "../../atoms/notifications/CollapsibleAlert";
import SlideLayout from "../../layouts/SlideLayout";

import {
  useCachedFirebaseCrud,
  useAccountData,
} from "../../../helpers/firebase";
import useEmissionsContext from "../../../helpers/contexts/emissions";
import { filterByDateRange } from "../../../helpers/utils/dates";
import { useFindAccountingBlockingEmissions } from "../../../helpers/utils/emissions";
import useAccountingData from "../../../helpers/hooks/accountingData";
import { analyticsTrack } from "../../../helpers/analytics";

const AccountingChecklistStep = ({ isChecked, title, disabled, onCheck }) => {
  return (
    <Grid item>
      <Checkbox
        label={title}
        value={isChecked}
        disabled={disabled}
        editValue={onCheck}
      />
    </Grid>
  );
};

const AccountingSlideLayoutContent = ({
  actionText,
  setOpen,
  dataLoading,
  accountingYear,
  isBaselineInput,
}) => {
  const disclaimers = [
    `All of my emissions are complete and accurate through ${accountingYear}.`,
    `All of the information for my offices is complete and correct for ${accountingYear}.`,
    `All of my employees and their commute schedules are complete and accurate for ${accountingYear}.`,
  ];

  const findAccountingBlockingEmissions = useFindAccountingBlockingEmissions();

  const [{ unClosedYears = [{}] }] = useAccountingData();
  const { newCollectionDoc } = useCachedFirebaseCrud();
  const { allEmissions } = useEmissionsContext();
  const accountingDate = new Date(accountingYear, 11, 31);

  const { isBlocked } = findAccountingBlockingEmissions(accountingDate);

  const [saveDataLoading, setSaveDataLoading] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const [checkedStep, setCheckedStep] = useState(0);
  const allChecked = checkedStep === disclaimers.length;

  const onSave = async () => {
    setSaveDataLoading(true);

    const monthsArray = [...new Array(12)].map((_, idx) => {
      const startDate = dayjs(new Date(accountingYear, idx, 1));
      const daysInMonth = dayjs(startDate).daysInMonth();
      return {
        startDate,
        endDate: dayjs(new Date(accountingYear, idx, daysInMonth)),
      };
    });

    const monthlySubcategoryEmissionsTons = monthsArray.map(
      ({ startDate, endDate }) => {
        const emissionsThisMonth = allEmissions.filter((emission) =>
          filterByDateRange({
            filterStartDate: startDate,
            filterEndDate: endDate,
            ...emission,
          })
        );

        const uniqueEmissionSubcategories = [
          ...new Set(emissionsThisMonth.map(({ subcategory }) => subcategory)),
        ];

        return Object.fromEntries(
          uniqueEmissionSubcategories.map((subcategory) => {
            const subcategoryEmissions = emissionsThisMonth.filter(
              (emission) => emission.subcategory === subcategory
            );
            const subcategoryTonsCo2e = sumTonsCo2e(subcategoryEmissions);
            return [subcategory, subcategoryTonsCo2e];
          })
        );
      }
    );

    await newCollectionDoc("accounting", {
      date: accountingDate,
      baseline: isBaselineInput,
      monthlySubcategoryEmissionsTons,
    });

    analyticsTrack(isBaselineInput ? "Baseline Set" : "Closed Acct Year", {
      year: accountingYear,
    });
    if (
      (!isBaselineInput && unClosedYears.length === 1) ||
      (isBaselineInput && dayjs().year() - accountingYear)
    ) {
      analyticsTrack("Silver Unlock");
    }
    return setOpen(false);
  };

  const isProduction = process.env.REACT_APP_ENVIRONMENT === "production";

  if (dataLoading) {
    return <AnimatedLogo />;
  }

  const alertTitle = `You have employees and/or utilities that you need to confirm before you can ${actionText}`;
  const alertSubtitle = `You can't ${actionText} until you resolve this issue`;

  if (isBlocked && isProduction) {
    return (
      <CollapsibleAlert
        title={alertTitle}
        subtitle={alertSubtitle}
        severity="warning"
      />
    );
  }

  return (
    <Grid container spacing={2} direction="column">
      {isBlocked && (
        <Grid item>
          <CollapsibleAlert
            severity="warning"
            title={alertTitle}
            subtitle={alertSubtitle}
          />
        </Grid>
      )}
      <Grid item>
        <Typography variant="body1">
          Please confirm the following information to {actionText} for the year{" "}
          {accountingYear}
        </Typography>
      </Grid>
      {disclaimers.map((disclaimer, idx) => (
        <AccountingChecklistStep
          key={`accounting-checklist-step-${idx}`}
          title={disclaimer}
          isChecked={idx < checkedStep}
          disabled={checkedStep > idx || idx > checkedStep}
          onCheck={() => setCheckedStep((currentStep) => currentStep + 1)}
        />
      ))}
      {allChecked && (
        <Grid
          item
          container
          spacing={2}
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Grid item>
            <TextField
              label="Confirmation"
              helperText={`Please confirm that you understand by typing "I understand"`}
              value={confirmationText}
              setValue={setConfirmationText}
            />
          </Grid>
          <Grid item>
            <LoadingButton
              isLoading={saveDataLoading}
              label={actionText}
              variant="contained"
              color="primary"
              disabled={confirmationText.toLowerCase() !== "i understand"}
              onClick={onSave}
              size="small"
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const AccountingSlideLayout = ({ setOpen, type, ...otherProps }) => {
  const { palette } = useTheme();

  const isBaselineInput = type === "baseline";
  const actionText = isBaselineInput
    ? "set your baseline"
    : "close your accounting";

  return (
    <SlideLayout
      isSlideOpen
      setIsSlideOpen={setOpen}
      header={
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Avatar style={{ backgroundColor: palette.secondary.main }}>
              <FontAwesomeIcon
                icon={isBaselineInput ? faChartLineUpDown : faAbacus}
                style={{ color: "white" }}
                size="lg"
              />
            </Avatar>
          </Grid>
          <Grid item>
            <Typography variant="h4">{ucFirstLetters(actionText)}</Typography>
          </Grid>
        </Grid>
      }
      content={
        <AccountingSlideLayoutContent
          isBaselineInput={isBaselineInput}
          actionText={actionText}
          setOpen={setOpen}
          {...otherProps}
        />
      }
    />
  );
};

const BaselineSlider = ({ setOpen }) => {
  const [{ startDate }, accountDataLoading] = useAccountData();

  return (
    <AccountingSlideLayout
      setOpen={setOpen}
      type="baseline"
      dataLoading={accountDataLoading}
      accountingYear={dayjs(startDate).year()}
    />
  );
};

const CloseAccountingSlider = ({ setOpen }) => {
  const [{ unClosedYears = [{}] }] = useAccountingData();

  const [{ date }] = unClosedYears;
  const year = dayjs(date).year();

  return (
    <AccountingSlideLayout
      setOpen={setOpen}
      type="accounting"
      accountingYear={year}
    />
  );
};

const CloseAccountingButton = ({
  type,
  color = "primary",
  disabled,
  accountingYear,
  size = "medium",
  ButtonComponent,
}) => {
  const [accountingActionModalOpen, setAccountingActionModalOpen] =
    useState(false);

  const dialogBoxTypes = {
    baseline: <BaselineSlider setOpen={setAccountingActionModalOpen} />,
    accounting: (
      <CloseAccountingSlider
        setOpen={setAccountingActionModalOpen}
        accountingYear={accountingYear}
      />
    ),
  };

  const inputBox = dialogBoxTypes[type];

  const buttonProps = {
    onClick: () => setAccountingActionModalOpen(true),
    disabled,
  };

  const button = ButtonComponent ? (
    <ButtonComponent {...buttonProps} />
  ) : (
    <Button variant="contained" color={color} size={size} {...buttonProps}>
      {type === "baseline" ? "set your baseline" : "close your accounting"}
    </Button>
  );

  return (
    <>
      {accountingActionModalOpen && inputBox}
      {button}
    </>
  );
};
export default CloseAccountingButton;
