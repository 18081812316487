import { fetchOurApi } from "./apiCalls";

export const extractGoogleAddressComponents = (components) => {
  const findComponent = (find) =>
    components.find((component) => component.types.includes(find));

  const zipCode = findComponent("postal_code")?.long_name;
  const city = findComponent("locality")?.long_name;
  const state = findComponent("administrative_area_level_1")?.short_name;
  const county = findComponent("administrative_area_level_2")?.long_name;
  const country = findComponent("country")?.short_name;

  return { zipCode, city, state, county, country };
};

export const findGoogleAddressStringDetails = async (placeId) => {
  const placeDetailCallback = (data) => {
    const {
      result: {
        address_components: components,
        geometry: { location },
        name,
      },
    } = data;

    const googleAddressComponents = extractGoogleAddressComponents(components);

    return {
      ...googleAddressComponents,
      coordinates: location,
      name,
      placeId,
    };
  };

  return await fetchOurApi({
    path: `/google-maps/place-details/${placeId}`,
    method: "GET",
    callback: (data) => placeDetailCallback(data),
  });
};

export const setAddress = (editAddress) => async (place) => {
  if (place) {
    const { place_id, description } = place;
    const googleAddressStringDetails = await findGoogleAddressStringDetails(
      place_id
    );
    return editAddress({
      description,
      ...googleAddressStringDetails,
      placeId: place_id,
    });
  }
  return editAddress(null);
};

const fetchAddressAndAirport = async (place) => {
  const { place_id, description, formatted_address } = place;
  const address = await findGoogleAddressStringDetails(place_id);

  const { coordinates } = address;
  const airport = await fetchOurApi({
    path: `/airports/nearest-airport/lat:${coordinates.lat},lng:${coordinates.lng}`,
    method: "GET",
    callback: (res) => res,
  });

  return {
    address: {
      ...address,
      description: description || formatted_address,
      placeId: place_id,
    },
    airport,
  };
};

export const setAddressUpdateAirport = (editData) => async (place) => {
  if (place) {
    const { address, airport } = await fetchAddressAndAirport(place);
    editData("defaultAirport")(airport);
    return editData("address")(address);
  }

  return editData("address")(null);
};

export const parseZipCodeFromAddress = (address) =>
  address.match(/\s[0-9]{5}/gm)[0].trim();

export const parseStateFromAddress = (address) => {
  const statesArray = [
    "al",
    "ak",
    "ar",
    "az",
    "ca",
    "co",
    "ct",
    "dc",
    "de",
    "fl",
    "ga",
    "hi",
    "ia",
    "id",
    "il",
    "in",
    "ks",
    "ky",
    "la",
    "ma",
    "md",
    "me",
    "mi",
    "mn",
    "mo",
    "ms",
    "mt",
    "nc",
    "nd",
    "ne",
    "nh",
    "nj",
    "nm",
    "nv",
    "ny",
    "oh",
    "ok",
    "or",
    "pa",
    "ri",
    "sc",
    "sd",
    "tn",
    "tx",
    "ut",
    "va",
    "vt",
    "wa",
    "wi",
    "wv",
    "wy",
  ];
  const possibleStateMatch = address
    .toLowerCase()
    .match(/\s[a-z]{2}(,|\s)/)[0]
    .trim();

  if (!possibleStateMatch) {
    return null;
  }
  const knownStateMatch = statesArray.includes(possibleStateMatch);

  return knownStateMatch && possibleStateMatch;
};
