import React, { useState } from "react";

import { Grid, Button, Typography, Divider, IconButton } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";

import {
  CategoriesAvatar,
  DefaultPaper,
  TextField,
} from "@aclymatepackages/atoms";
import {
  EmissionsPieChart,
  PlacesAutocomplete,
} from "@aclymatepackages/modules";
import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { numbersRegExpTest } from "@aclymatepackages/reg-exp";
import { subcategories } from "@aclymatepackages/subcategories";
import { formatDecimal, ucFirstLetters } from "@aclymatepackages/formatters";

import StatePercentagesInterface from "./StatePercentagesInterface";

import EmissionsDetailsTable from "../../modules/tables/EmissionsDetailsTable";
import SettingsObjectDetailsSlider from "../dashboard/SettingsObjectDetailsSlider";
import DatePicker from "../../atoms/mui/DatePicker";

import {
  calculateEmployeeEmissions,
  isAttendeeStateBreakdownPercentageError,
} from "../../../helpers/components/events";
import { setAddress } from "../../../helpers/utils/geography";
import {
  useAccountData,
  useCachedFirebaseCrud,
} from "../../../helpers/firebase";
import useEmissionsContext from "../../../helpers/contexts/emissions";

const getEventPieChartTooltipData = (subcategory) => {
  const predefinedSubcategoryNames = {
    electricity: "Venue Electricity",
    gas: "Venue Gas",
    waste: "Attendee Emissions",
    shipping: "Employee Emissions",
  };

  const existingSubcategoryNames = subcategories.reduce(
    (acc, { subcategory, name }) => {
      if (!predefinedSubcategoryNames.hasOwnProperty(subcategory)) {
        return { ...acc, [subcategory]: name };
      }
      return acc;
    },
    {}
  );

  const allSubcategoryNames = {
    ...existingSubcategoryNames,
    ...predefinedSubcategoryNames,
  };

  return allSubcategoryNames[subcategory];
};

const EventDetailsContent = ({ modifyEvent, setModifyEvent }) => {
  const { name, address, sqFootage, attendeeCount, startDate, endDate } =
    modifyEvent;

  const [{ startDate: companyStartDate }] = useAccountData();

  const editModifyEvent = (field) => (value) =>
    editObjectData(setModifyEvent, field, value);

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <TextField
          label="Name"
          value={name}
          setValue={editModifyEvent("name")}
        />
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="Venue Sq. Footage"
            value={sqFootage}
            setValue={editModifyEvent("sqFootage")}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Total Attendees"
            value={attendeeCount}
            setValue={editModifyEvent("attendeeCount")}
          />
        </Grid>
      </Grid>
      <Grid item>
        <PlacesAutocomplete
          label="Venue Address"
          place={address || null}
          editPlace={setAddress(editModifyEvent("address"))}
          size="small"
        />
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            label="Start Date"
            date={startDate}
            minDate={companyStartDate}
            maxDate={endDate}
            editDate={(date) => editModifyEvent("startDate")(new Date(date))}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            label="End Date"
            date={endDate}
            minDate={startDate}
            editDate={(date) => editModifyEvent("endDate")(new Date(date))}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const EventDetailsFooter = ({ modifyEvent }) => {
  const { name, address, sqFootage, startDate, endDate, id } = modifyEvent;

  const { updateCollectionDoc } = useCachedFirebaseCrud();

  //TODO: I left out a check for attendeeCount because it's going to be moved from here
  const isSaveDisabled =
    !name ||
    !address ||
    !sqFootage ||
    !numbersRegExpTest(sqFootage) ||
    !startDate ||
    !endDate;

  return (
    <Grid container justifyContent="flex-end">
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          disabled={isSaveDisabled}
          onClick={() => updateCollectionDoc("events", id, modifyEvent)}
        >
          Save Event
        </Button>
      </Grid>
    </Grid>
  );
};

const useEventDetailsTab = (event) => {
  const [modifyEvent, setModifyEvent] = useState(event);

  return {
    content: (
      <EventDetailsContent
        modifyEvent={modifyEvent}
        setModifyEvent={setModifyEvent}
      />
    ),
    footer: <EventDetailsFooter modifyEvent={modifyEvent} event={event} />,
  };
};

const useEventAttendeesTab = ({ selectedEvent }) => {
  const { updateCollectionDoc } = useCachedFirebaseCrud();

  const { attendeesStateBreakdown = [], id: eventId } = selectedEvent;

  const [editStateBreakdown, setEditStateBreakdown] = useState(false);
  const [stateBreakdown, setStateBreakdown] = useState(attendeesStateBreakdown);

  const onIconButtonClick = () =>
    setEditStateBreakdown((isEditing) => {
      if (isEditing) {
        updateCollectionDoc("events", eventId, {
          attendeesStateBreakdown: stateBreakdown,
        });
      }

      return !isEditing;
    });

  return {
    content: (
      <Grid container direction="column" spacing={2}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h4">
              Breakdown of Attendees by State
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={onIconButtonClick}
              disabled={
                editStateBreakdown &&
                isAttendeeStateBreakdownPercentageError(stateBreakdown)
              }
            >
              {editStateBreakdown ? <SaveIcon /> : <EditIcon />}
            </IconButton>
          </Grid>
        </Grid>
        <Grid item>
          {editStateBreakdown ? (
            <StatePercentagesInterface
              stateBreakdown={stateBreakdown}
              setStateBreakdown={setStateBreakdown}
            />
          ) : (
            <Grid container direction="column" spacing={2}>
              {stateBreakdown.map(({ state, percentage }, idx) => (
                <React.Fragment key={`state-breakdown-row-${idx}`}>
                  {!!state && !!percentage && (
                    <>
                      <Grid
                        item
                        container
                        spacing={2}
                        justifyContent="space-between"
                      >
                        <Grid item>
                          <Typography variant="h5">
                            {ucFirstLetters(state)}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="h5">{percentage}%</Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                    </>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>
    ),
  };
};

const EventPieChartTooltip = ({ payload }) => {
  const [payloadObj] = payload || [{}];

  const { payload: activePayload = {} } = payloadObj || [{}];

  const { payload: tooltipPayload = {} } = activePayload || {};

  const { emissionPercentage, subcategory, scope, color } = tooltipPayload;

  const name = getEventPieChartTooltipData(subcategory);

  return (
    <DefaultPaper>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <CategoriesAvatar
            scope={scope}
            subcategory={subcategory}
            color={color}
          />
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" color="textPrimary">
            {name}
          </Typography>
          <Typography variant="caption" color="textSecondary">{`${formatDecimal(
            emissionPercentage
          )}% of total emissions`}</Typography>
        </Grid>
      </Grid>
    </DefaultPaper>
  );
};

const useEventEmissionsTab = ({
  selectedEvent,
  setSelectedTransaction,
  closeSelectedEvent,
}) => {
  const { transactions } = useEmissionsContext();

  const [showCharts, setShowCharts] = useState(true);
  const onAccordionClick = () => setShowCharts((currentState) => !currentState);

  const eventTransactions = transactions?.filter(
    ({ event = {} }) => event.name === selectedEvent.name
  );

  const {
    venueElectricTons,
    venueGasTons,
    attendeeEmissionsTons,
    employees = [],
  } = selectedEvent;

  const employeeEmissions = calculateEmployeeEmissions(employees);

  const combinedData = [
    ...eventTransactions,
    {
      subcategory: "electricity",
      tonsCo2e: venueElectricTons,
      scope: 2, // Assuming scope 2 for electricity
      name: "Venue Electricity",
    },
    {
      subcategory: "gas",
      tonsCo2e: venueGasTons,
      scope: 1, // Assuming scope 1 for gas
      name: "Venue Gas",
    },
    {
      subcategory: "waste",
      tonsCo2e: attendeeEmissionsTons,
      scope: 1,
      name: "Attendee Emissions",
    },
    {
      subcategory: "shipping",
      tonsCo2e: employeeEmissions,
      scope: 3,
      name: "Employee Emissions",
    },
  ];

  return {
    content: (
      <Grid
        container
        rowGap={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item width="100%">
          <EmissionsPieChart
            dataArray={combinedData}
            CustomTooltip={EventPieChartTooltip}
          />
        </Grid>
        <Grid item width="100%">
          <EmissionsDetailsTable
            type="events"
            emissions={eventTransactions}
            showCharts={showCharts}
            onChange={onAccordionClick}
            setSelectedTransaction={setSelectedTransaction}
            closeSelectedObjectSlider={closeSelectedEvent}
          />
        </Grid>
      </Grid>
    ),
  };
};

const EventDetailsSlider = ({
  selectedEvent,
  setSelectedEvent,
  setSelectedTransaction,
  closeSelectedEvent,
}) => {
  const { id } = selectedEvent;

  const { updateCollectionDoc } = useCachedFirebaseCrud();

  const { content: detailsContent, footer } = useEventDetailsTab(selectedEvent);
  const { content: attendeesContent } = useEventAttendeesTab({
    selectedEvent,
  });
  const { content: emissionsContent } = useEventEmissionsTab({
    selectedEvent,
    setSelectedTransaction,
    closeSelectedEvent,
  });

  return (
    <SettingsObjectDetailsSlider
      type="events"
      setSelectedObject={setSelectedEvent}
      settingsObject={selectedEvent}
      footerInputSubcategories={subcategories
        .filter(({ tags = [] }) => tags.includes("events"))
        .map(({ subcategory }) => ({
          subcategory,
        }))}
      onNameSave={(name) => updateCollectionDoc("events", id, { name })}
      views={[
        {
          label: "Emissions",
          value: "emissions",
          content: emissionsContent,
          contentPosition: "flex-start",
        },
        {
          label: "Details",
          value: "details",
          content: detailsContent,
          footer,
        },
        {
          label: "Attendees",
          value: "attendees",
          content: attendeesContent,
        },
        // {
        //   label: "Reports",
        //   value: "reports",
        //   content: <div>Reports</div>,
        // },
      ]}
    />
  );
};
export default EventDetailsSlider;
