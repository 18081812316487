import React, { createContext, useState, useContext } from "react";
import useAccountingData from "../hooks/accountingData";

const PurchasingContext = createContext();

export const PurchasingContextProvider = ({ children }) => {
  const [{ accountingYears = [] }, accountingDataLoading] = useAccountingData();

  const [selectedScopeChips, setSelectedScopeChips] = useState([]);
  const [purchaseTons, setPurchaseTons] = useState(0);
  const [purchaseDollars, setPurchaseDollars] = useState(0);
  const [selectedTab, setSelectedTab] = useState("volume");

  const nonDiamondYears = accountingYears.filter(
    ({ badge }) => badge !== "diamond"
  );

  return (
    <PurchasingContext.Provider
      value={{
        selectedScopeChips,
        setSelectedScopeChips,
        purchaseTons,
        setPurchaseTons,
        purchaseDollars,
        setPurchaseDollars,
        selectedTab,
        setSelectedTab,
        nonDiamondYears,
        dataLoaing: accountingDataLoading,
      }}
    >
      {children}
    </PurchasingContext.Provider>
  );
};

const usePurchasingContext = () => useContext(PurchasingContext);
export default usePurchasingContext;
