import React, { useState } from "react";

import { Grid, Typography, Divider } from "@mui/material";

import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { numbersRegExpTest } from "@aclymatepackages/reg-exp";
import { TextField } from "@aclymatepackages/atoms";

import PlacesAutocomplete from "../autocomplete/PlacesAutocomplete";

import { buildInitialTransactionInputValueFromSchema } from "../../../helpers/components/inputs";
import { isObjectEmpty } from "../../../helpers/otherHelpers";
import {
  fetchDirectionsMileage,
  fetchTotalMileageCarbon,
} from "../../../helpers/utils/apiCalls";
import { setAddress } from "../../../helpers/utils/geography";

const ToFromInputBlock = ({ rideFrom, setFrom, rideTo, setTo }) => (
  <Grid container spacing={2}>
    <Grid item sm={6}>
      <PlacesAutocomplete
        place={rideFrom}
        editPlace={setFrom}
        label="Ride Starting Point"
        size="small"
        id="rides-starting-point-input"
      />
    </Grid>
    <Grid item sm={6}>
      <PlacesAutocomplete
        place={rideTo}
        editPlace={setTo}
        label="Ride Destination"
        size="small"
        id="rides-endpoint-input"
      />
    </Grid>
  </Grid>
);

const MileageInput = ({ value, setValue }) => (
  <TextField
    label="Estimated Trip Mileage"
    value={value}
    setValue={setValue}
    helperText={value && !numbersRegExpTest(value) && "This must be a number"}
    id="rides-estimated-mileage"
  />
);

const DollarInput = ({ value, setValue }) => (
  <TextField
    label="Estimated Dollar Amount"
    value={value}
    setValue={setValue}
    helperText={value && !numbersRegExpTest(value) && "This must be a number"}
    id="rides-estimated-dollar-amount"
  />
);

const RidesInputBlock = ({
  rideFrom,
  setFrom,
  rideTo,
  setTo,
  rideMileageEstimate,
  editRideMileageEstimate,
  dollarAmount,
  editDollarAmountEstimate,
}) => {
  const inputRows = [
    {
      RowComponent: ToFromInputBlock,
      props: { rideFrom, setFrom, rideTo, setTo },
      title: "Enter Ride Start and End Points (Best)",
    },
    {
      RowComponent: MileageInput,
      props: { value: rideMileageEstimate, setValue: editRideMileageEstimate },
      title: "Enter Estimated Mileage (Better)",
    },
    {
      RowComponent: DollarInput,
      props: { value: dollarAmount, setValue: editDollarAmountEstimate },
      title: "Enter Ride Cost (Good)",
    },
  ];

  return (
    <Grid container direction="column" spacing={1}>
      {inputRows.map(({ RowComponent, props, title }, idx) => (
        <>
          <Grid item key={`rides-input-row-${idx}`}>
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
            <RowComponent {...props} />
          </Grid>
          {idx !== inputRows.length - 1 && (
            <Grid
              item
              container
              spacing={1}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={3}>
                <Divider />
              </Grid>
              <Grid item>
                <Typography variant="h5">OR</Typography>
              </Grid>
              <Grid item xs={3}>
                <Divider />
              </Grid>
            </Grid>
          )}
        </>
      ))}
    </Grid>
  );
};

const useRidesInput = ({ transaction, onSave, setCalcLoading }) => {
  const inputSchema = [
    { field: "rideFrom" },
    { field: "rideTo" },
    { field: "mileageEstimate" },
    { field: "dollarAmount" },
  ];

  const [inputData, setInputData] = useState(
    buildInitialTransactionInputValueFromSchema(transaction, inputSchema)
  );
  const editInputData = (field) => (value) =>
    editObjectData(setInputData, field, value);

  const [ridesTonsCo2e, setRidesTonsCo2e] = useState(0);

  const { rideTo, rideFrom, mileageEstimate, dollarAmount } = inputData;

  const setDestination = (setter, otherDestination) => async (destination) => {
    if (!destination) {
      setRidesTonsCo2e(0);
      return setter("");
    }

    if (otherDestination && !isObjectEmpty(destination)) {
      editInputData("mileageEstimate")("");
      editInputData("dollarAmount")("");
      setCalcLoading(true);
      const { place_id: placeId } = destination;
      const totalMileage = await fetchDirectionsMileage(
        { placeId },
        otherDestination
      );
      await fetchTotalMileageCarbon(totalMileage, setRidesTonsCo2e);
      setCalcLoading(false);
    }

    return setAddress(setter)(destination);
  };

  const editRideMileageEstimate = async (miles) => {
    editInputData("mileageEstimate")(miles);
    if (miles) {
      editInputData("rideFrom")("");
      editInputData("rideTo")("");
      editInputData("dollarAmount")("");
      setCalcLoading(true);
      await fetchTotalMileageCarbon(miles, setRidesTonsCo2e);
      return setCalcLoading(false);
    }

    return setRidesTonsCo2e(0);
  };

  const editDollarAmountEstimate = async (amount) => {
    editInputData("dollarAmount")(amount);

    if (amount) {
      const estimatedAvgMiles = amount / 0.6; // Average cost per mile

      editInputData("rideFrom")("");
      editInputData("rideTo")("");
      editInputData("mileageEstimate")("");
      setCalcLoading(true);
      await fetchTotalMileageCarbon(estimatedAvgMiles, setRidesTonsCo2e);
      return setCalcLoading(false);
    }
    return setRidesTonsCo2e(0);
  };

  const descriptionObj =
    rideTo && rideFrom
      ? { description: `${rideFrom.description} -> ${rideTo.description}` }
      : { mileageEstimate };

  const inputBlock = (
    <RidesInputBlock
      rideFrom={rideFrom}
      setFrom={setDestination(editInputData("rideFrom"), rideTo)}
      rideTo={rideTo}
      setTo={setDestination(editInputData("rideTo"), rideFrom)}
      rideMileageEstimate={mileageEstimate}
      editRideMileageEstimate={editRideMileageEstimate}
      dollarAmount={dollarAmount}
      editDollarAmountEstimate={editDollarAmountEstimate}
    />
  );

  const onTransactionSave = () =>
    onSave({
      ...descriptionObj,
      ...inputData,
      tonsCo2e: ridesTonsCo2e,
    });

  const saveEnabled =
    (rideTo && rideFrom) ||
    (!!mileageEstimate && numbersRegExpTest(mileageEstimate)) ||
    !!dollarAmount;

  return {
    inputBlock,
    onTransactionSave,
    saveEnabled,
    tonsCo2e: ridesTonsCo2e,
  };
};
export default useRidesInput;
