import React, { useState, useContext, useRef, useEffect } from "react";

import {
  Box,
  Grid,
  Typography,
  Snackbar,
  IconButton,
  StyledEngineProvider,
  Alert,
  ThemeProvider,
  useTheme,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import {
  faCreditCardBlank,
  faUserHeadset,
  faCaretCircleLeft,
  faCaretCircleRight,
} from "@fortawesome/pro-solid-svg-icons";
import { mergeDarkTheme } from "@aclymatepackages/themes";

import Link from "../atoms/mui/Link";
import { useLayoutHelpers } from "../../helpers/otherHelpers";
import {
  AccountAccessContext,
  AccountAccessContextProvider,
} from "../../helpers/contexts/accountAccess";
import CircularProgressWithLabel from "../atoms/loading/CircularProgressWithLabel";

const carouselSteps = [
  {
    title: "Invite Your Team",
    subtitle:
      "Invite as many coworkers as you need to help you manage your account.",
    icon: faUsers,
  },
  {
    title: "Real Human Beings",
    subtitle:
      "Our expert team of Climate Navigators is there to help you every step of the way.",
    icon: faUserHeadset,
  },
  {
    title: "No Credit Card Required",
    subtitle:
      "There's no commitment needed to calculate and understand your carbon footprint.",
    icon: faCreditCardBlank,
  },
];

const BenefitsCarousel = ({ accountCreationType }) => {
  const theme = useTheme();
  const [carouselStep, setCarouselStep] = useState(100000);
  const [circleWidth, setCircleWidth] = useState(0);
  const [hover, setHover] = useState(false);
  const circleRef = useRef();

  useEffect(() => {
    if (circleRef.current && !circleWidth) {
      setCircleWidth(circleRef.current.offsetWidth);
    }
  }, [circleRef, circleWidth]);

  useEffect(() => {
    const advanceStep = () => {
      if (!hover) {
        return setCarouselStep((currentStep) => currentStep + 1);
      }
    };

    const stepInterval = setInterval(() => advanceStep(), 3000);

    return () => clearInterval(stepInterval);
  }, [hover]);

  const { title, subtitle } = carouselSteps[carouselStep % 3] || {};

  const positionIcon = (operator, idx) => {
    const radius = circleWidth / 2;
    return `${
      radius + radius * Math[operator]((idx * 120 * Math.PI) / 180) - 60
    }px`;
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={mergeDarkTheme}>
        <Box
          position="relative"
          style={{ margin: "auto", maxWidth: "500px" }}
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
        >
          <Box
            ref={circleRef}
            style={{
              position: "relative",
              width: "70%",
              maxWidth: "350px",
              margin: "auto",
              aspectRatio: "1/1",
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
            p={3}
          >
            <Typography variant="h3" align="center" color="textPrimary">
              {title}
            </Typography>
            <Box
              ref={circleRef}
              style={{
                position: "absolute",
                inset: 0,
                transform: `rotate(${120 * carouselStep}deg)`,
                transition: "transform 0.5s",
                borderRadius: "100%",
                boxSizing: "border-box",
                border: "20px solid white",
              }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={2}
            >
              {carouselSteps.map(({ icon, subtitle: thisSubtitle }, idx) => (
                <Box
                  key={`carousel-benefit-${idx}`}
                  style={{
                    position: "absolute",
                    top: positionIcon("cos", idx),
                    left: positionIcon("sin", idx),
                    color: `rgba(255, 255, 255, ${
                      subtitle === thisSubtitle ? 1 : 0.7
                    })`,
                    backgroundColor:
                      accountCreationType === "my"
                        ? theme.palette.primary.dark
                        : theme.palette.secondary.dark,
                    borderRadius: "100%",
                    height: "80px",
                    width: "80px",
                    boxSizing: "border-box",
                    transform: `rotate(${-120 * carouselStep}deg)`,
                    transition: "transform 0.5s",
                  }}
                  p={1}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <FontAwesomeIcon icon={icon} size="3x" />
                </Box>
              ))}
            </Box>
          </Box>
          <div
            style={{
              position: "absolute",
              top: "65%",
              left: theme.spacing(2),
            }}
          >
            <IconButton
              onClick={() => setCarouselStep((currentStep) => currentStep - 1)}
              size="large"
            >
              <FontAwesomeIcon icon={faCaretCircleLeft} size="1x" />
            </IconButton>
          </div>
          <div
            style={{
              position: "absolute",
              top: "65%",
              right: theme.spacing(2),
            }}
          >
            <IconButton
              onClick={() => setCarouselStep((currentStep) => currentStep + 1)}
              size="large"
            >
              <FontAwesomeIcon icon={faCaretCircleRight} size="1x" />
            </IconButton>
          </div>
          <Box pt={6}>
            <Typography variant="h6" align="center" color="textSecondary">
              {subtitle}
            </Typography>
          </Box>
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const AccountAccessLayoutBlock = ({
  title,
  subtitle,
  formDataLoading,
  accountCreationType,
  children,
}) => {
  const { isMedium } = useLayoutHelpers();
  const theme = useTheme();
  const { accountAccessError, setAccountAccessError } =
    useContext(AccountAccessContext);

  const onSnackbarClose = () => setAccountAccessError("");

  const heightObj = isMedium ? { minHeight: "100vh" } : { height: "100vh" };

  return (
    <>
      {!!accountAccessError && (
        <Snackbar
          open={!!accountAccessError}
          autoHideDuration={6000}
          onClose={onSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Alert severity="error" onClose={onSnackbarClose}>
            {accountAccessError}
          </Alert>
        </Snackbar>
      )}
      <Grid
        container
        style={{
          ...heightObj,
          boxSizing: "border-box",
          overflowX: "hidden",
        }}
        alignItems="stretch"
      >
        <Grid item md={6} xs={12}>
          <Box
            style={{ height: "100%", boxSizing: "content-box" }}
            display="flex"
            flexDirection="column"
          >
            <Box flexGrow={0} px={4} pt={4} pb={2}>
              <Link href="https://aclymate.com">
                <img
                  src="/images/aclymate-full-logo.png"
                  style={{ maxWidth: "250px" }}
                  alt="full-aclymate-logo"
                />
              </Link>
            </Box>
            <Box
              flexGrow={1}
              py={2}
              px={4}
              display="flex"
              justifyContent="center"
              flexDirection="column"
            >
              {formDataLoading ? (
                <Grid item container justifyContent="center">
                  <CircularProgressWithLabel />
                </Grid>
              ) : (
                <Grid container direction="column" spacing={3}>
                  <Grid item>
                    <Typography
                      variant="h2"
                      style={{ color: theme.palette["business-travel"].main }}
                      component="h1"
                    >
                      {title}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      {subtitle}
                    </Typography>
                  </Grid>
                  {children}
                </Grid>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box
            style={{
              backgroundColor:
                accountCreationType === "my"
                  ? theme.palette.primary.dark
                  : theme.palette.secondary.dark,
              height: "100%",
              boxSizing: "content-box",
            }}
            display="flex"
            flexDirection="column"
            position="relative"
          >
            <Box
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                bottom: 0,
                opacity: 0.1,
              }}
            >
              <img
                src="/images/website/account-access-footer.svg"
                style={{
                  width: "calc(100% + 3px)",
                  marginBottom: "-5px",
                  marginLeft: "-2px",
                }}
                alt="forest silhouette"
              />
            </Box>
            <Box
              p={5}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              flexGrow={1}
            >
              <BenefitsCarousel accountCreationType={accountCreationType} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

const AccountAccessLayout = ({ children, ...otherProps }) => (
  <AccountAccessContextProvider>
    <AccountAccessLayoutBlock {...otherProps}>
      {children}
    </AccountAccessLayoutBlock>
  </AccountAccessContextProvider>
);

export default AccountAccessLayout;
