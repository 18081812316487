import { useContext } from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

import { formatDate } from "@aclymatepackages/formatters";
import { calculateCurrentCarbonBalanceTons } from "@aclymatepackages/emissions-calcs";
import { STARTER_TIER_MAXIMUM_NUMBER_EMPLOYEES } from "@aclymatepackages/constants";

import { useAccountData, useCachedDisplayData } from "../firebase";
import useEmissionsContext from "../contexts/emissions";
import { StripeCustomerContext } from "../contexts/stripeCustomer";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(duration);

export const useEmployeeCount = () => {
  const [employees, employeesLoading] = useCachedDisplayData("employees");
  const [{ employeeCount }, accountDataLoading] = useAccountData();

  return [
    employeeCount || employees?.length,
    employeesLoading || accountDataLoading,
  ];
};

export const useCompletedOnboardingSteps = () => {
  const [
    { isRemote, employeeCountConfirmed, employeeSurveyLinkSent },
    companyDataLoading,
  ] = useAccountData();
  const { paymentMethod, isPaymentMethodLoading } =
    useContext(StripeCustomerContext) || {};
  const { card } = paymentMethod || {};

  const [offices, officesLoading] = useCachedDisplayData("offices");
  const [vendors, vendorsLoading] = useCachedDisplayData("vendors");
  const [employees, employeesLoading] = useCachedDisplayData("employees");
  const [offsets, offsetsLoading] = useCachedDisplayData("offsets");

  const dataLoading =
    companyDataLoading ||
    isPaymentMethodLoading ||
    officesLoading ||
    vendorsLoading ||
    employeesLoading ||
    offsetsLoading;

  const completedOnboardingSteps = {
    officesComplete: !!offices.length || isRemote,
    vendorsComplete: !!vendors.length,
    employeesComplete:
      !!employees.length || (employeeCountConfirmed && employeeSurveyLinkSent),
    billingComplete: !!card,
    offsetsComplete: !!offsets.length,
  };

  return [completedOnboardingSteps, dataLoading];
};

export const useCurrentCarbonBalanceTons = () => {
  const [offsets, offsetsLoading] = useCachedDisplayData("offsets");

  const { recurringEmissionsLoading, allEmissions } =
    useEmissionsContext() || {};

  const currentCarbonBalanceTonsLoading =
    offsetsLoading || recurringEmissionsLoading;

  const filteredOffsets = offsets?.length
    ? offsets.filter(({ status }) => status !== "failed")
    : [];

  const currentCarbonBalanceTons = calculateCurrentCarbonBalanceTons({
    allEmissions,
    offsets: filteredOffsets,
  });

  return [currentCarbonBalanceTons, currentCarbonBalanceTonsLoading];
};

export const useSubscriptionType = (subscriptionType = "saas") => {
  const { subscriptions = [], isSubscriptionsLoading } =
    useContext(StripeCustomerContext) || {};

  const subscriptionObj = subscriptions.find(
    ({ product }) => product === subscriptionType
  );

  const {
    status: backendStatus,
    upcomingInvoice,
    trialDate,
  } = subscriptionObj || {};
  const { upcomingInvoice: upcomingInvoiceObj } = upcomingInvoice || {};
  const { dueDate } = upcomingInvoiceObj || {};

  const findSubscriptionStatus = () => {
    if (isSubscriptionsLoading) {
      return null;
    }

    if (subscriptionType !== "saas") {
      return backendStatus;
    }

    const knownFrontendStatuses = ["active", "expired", "canceled", "trialing"];
    if (knownFrontendStatuses.includes(backendStatus)) {
      return backendStatus;
    }

    return "expired";
  };

  const status = findSubscriptionStatus();

  return [
    {
      ...subscriptionObj,
      upcomingInvoice: upcomingInvoiceObj,
      status,
      isPaid: status === "active",
      cancellationDate: formatDate(dueDate),
      trialDate,
    },
    isSubscriptionsLoading,
  ];
};

export const useShowOnboardingPage = () => {
  const [
    { officesComplete, vendorsComplete, employeesComplete },
    completedOnboardingLoading,
  ] = useCompletedOnboardingSteps();

  const showOnboardingPage = !(
    officesComplete &&
    vendorsComplete &&
    employeesComplete
  );

  return [showOnboardingPage, completedOnboardingLoading];
};

export const useOffsetBucketSubscription = () => {
  const [
    naturalAndWorkingLandsSubscription,
    naturalAndWorkingLandsSubscriptionLoading,
  ] = useSubscriptionType("naturalAndWorkingLands");
  const [cleanTransitionSubscription, cleanTransitionSubscriptionLoading] =
    useSubscriptionType("cleanTransition");
  const [climateJusticeSubscription, climateJusticeSubscriptionLoading] =
    useSubscriptionType("climateJustice");

  const findSubscription = () => {
    if (naturalAndWorkingLandsSubscription?.status) {
      return naturalAndWorkingLandsSubscription;
    }
    if (cleanTransitionSubscription?.status) {
      return cleanTransitionSubscription;
    }
    if (climateJusticeSubscription?.status) {
      return climateJusticeSubscription;
    }
    return {};
  };

  return [
    findSubscription(),
    naturalAndWorkingLandsSubscriptionLoading ||
      cleanTransitionSubscriptionLoading ||
      climateJusticeSubscriptionLoading,
  ];
};

export const useStarterTierSubscriptionFlags = () => {
  const [{ type }] = useSubscriptionType("saas");
  const [employees] = useCachedDisplayData("employees");

  const isStarterTier = type === "starter";

  const willEmployeeLimitBeReachedFromNewEmployees = (newEmployees) =>
    isStarterTier &&
    employees.length + newEmployees.length >
      STARTER_TIER_MAXIMUM_NUMBER_EMPLOYEES;

  return {
    isStarterTier,
    isEmployeeLimitReached:
      isStarterTier &&
      employees.length >= STARTER_TIER_MAXIMUM_NUMBER_EMPLOYEES,
    willEmployeeLimitBeReachedFromNewEmployees,
  };
};
