import React, { useState, useEffect } from "react";
import { useParams } from "react-router";

import {
  Container,
  Grid,
  Typography,
  CircularProgress,
  IconButton,
  Box,
  useTheme,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

import { nameToFirstName } from "@aclymatepackages/converters";
import {
  DefaultPaper,
  LoadingButton,
  TextField,
} from "@aclymatepackages/atoms";

import { useNewEmployeeForm } from "../../hooks/employees";

import MultiPartForm from "../flows/MultipartForm";

import { fetchOurApi } from "../../../helpers/utils/apiCalls";

const Layout = ({ children }) => {
  const theme = useTheme();

  return (
    <Box
      style={{
        backgroundImage:
          "url('/images/platform/employee-survey-background.jpg')",
        backgroundSize: "cover",
      }}
    >
      <Grid
        container
        direction="column"
        style={{
          height: "100vh",
          boxSizing: "border-box",
          overflow: "hidden",
        }}
        wrap="nowrap"
      >
        <Grid
          item
          style={{
            flexGrow: 1,
            overflowY: "auto",
            position: "relative",
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            style={{ minHeight: "100%", padding: theme.spacing(2) }}
          >
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const surveyThankYouStep = [
  {
    label: "Thank you",
    title: "Thank you",
    subtitle:
      "Thank you for completing the survey! You may now close this window.",
    titleAlign: "center",
    noButton: true,
  },
];

const SurveyAuthentication = ({
  passwordPin,
  setPasswordPin,
  checkEmailAndPasswordLoading,
  loginError,
  checkEmailAndPin,
  companyName,
}) => {
  return (
    <Container maxWidth="sm">
      <DefaultPaper>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Grid item style={{ marginBottom: "50px", paddingTop: "20px" }}>
            <img
              src="/images/aclymate-full-logo.png"
              alt="RWT logo"
              width={250}
            />
          </Grid>
          <Grid item>
            <Typography variant="h5" align="center">
              {`${companyName} Employee Survey`}
            </Typography>
          </Grid>
          <Grid item container xs={12}>
            <TextField
              label="Enter your password pin"
              value={passwordPin}
              setValue={(value) => setPasswordPin(value)}
            />
          </Grid>
          {loginError && (
            <Grid item>
              <Typography align="center" variant="subtitle2" color="error">
                {loginError}
              </Typography>
            </Grid>
          )}
          <Grid item>
            <LoadingButton
              label="Login"
              variant="contained"
              color="primary"
              disabled={!passwordPin}
              isLoading={checkEmailAndPasswordLoading}
              onClick={() => checkEmailAndPin()}
            />
          </Grid>
        </Grid>
      </DefaultPaper>
    </Container>
  );
};

const NewEmployeeSurvey = ({
  companyName,
  companyStartDate,
  employeeData,
  mostRecentAccountingDate,
}) => {
  const { companyId } = useParams();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [passwordPin, setPasswordPin] = useState("");
  const [checkEmailAndPasswordLoading, setCheckEmailAndPasswordLoading] =
    useState(false);
  const [loginError, setLoginError] = useState("");

  const newEmployeeForm = useNewEmployeeForm({
    existingEmployee: employeeData,
    surveyThankYouStep,
    companyName,
    companyStartDate,
    mostRecentAccountingDate,
  });

  const { name = "" } = employeeData || {};
  const welcomeRow = {
    label: `Welcome ${employeeData ? nameToFirstName(name) : ""}`,
    title: `Welcome ${employeeData ? nameToFirstName(name) : ""}`,
    titleAlign: "center",
    input: (
      <Typography variant="subtitle1">
        {companyName} is using Aclymate to automatically calculate and offset
        their monthly carbon emissions. As an employee of {companyName}, your
        commuting and home office emissions make up part of your company's
        footprint. In this short survey, we ask you to provide us with the
        information we need to automatically calculate your commuting and home
        office emissions every month and help {companyName} become carbon
        neutral.
      </Typography>
    ),
    buttonText: "Let's get started",
  };

  const completeNewEmployeeForm = [welcomeRow, ...newEmployeeForm];

  const checkEmailAndPin = async () => {
    setCheckEmailAndPasswordLoading(true);
    await fetchOurApi({
      path: `/individuals/check-valid-user`,
      method: "POST",
      data: {
        companyId,
        passwordPin,
      },
      callback: ({ success, message }) => {
        if (message) {
          setLoginError(message);
        }
        return setIsAuthenticated(success);
      },
    }).catch((e) => console.log(e));
    return setCheckEmailAndPasswordLoading(false);
  };

  return (
    <>
      {isAuthenticated || !!employeeData ? (
        <MultiPartForm
          type="employeeSurvey"
          forms={completeNewEmployeeForm}
          submitLoadingText="Please wait..."
        />
      ) : (
        <SurveyAuthentication
          passwordPin={passwordPin}
          setPasswordPin={setPasswordPin}
          checkEmailAndPasswordLoading={checkEmailAndPasswordLoading}
          loginError={loginError}
          checkEmailAndPin={checkEmailAndPin}
          companyName={companyName}
        />
      )}
    </>
  );
};

const EmployeeSurvey = () => {
  const { companyId, employeeId = "", surveyType } = useParams();

  const [dataLoading, setDataLoading] = useState(true);
  const [dbData, setDbData] = useState({});
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    //We fetch from backend instead of front-end Firebase SDK because Firestore security rules restrict access to unauthorized users
    const fetchSurveyData = async (path) =>
      await fetchOurApi({
        accountId: `v2-companies-${companyId}`,
        path: `/surveys/${path}`,
        method: "GET",
        callback: (res) => res,
      });

    const fetchAndSetSurveyData = async () => {
      const [companyData, employeeData] = await Promise.all([
        fetchSurveyData(`company/${companyId}/data`),
        employeeId
          ? fetchSurveyData(`employee/${employeeId}/${companyId}`)
          : Promise.resolve(null),
      ]);

      setDbData({ companyData, employeeData });
      return setDataLoading(false);
    };

    if (companyId && !dataFetched) {
      setDataFetched(true);
      fetchAndSetSurveyData();
    }
  }, [employeeId, companyId, dbData, dataFetched]);

  const { employeeData = {}, companyData = {} } = dbData;
  const { status } = employeeData || {};
  const {
    name: companyName,
    startDate,
    isSurveyAccessible,
    mostRecentAccountingDate,
  } = companyData || {};

  const InvalidPage = ({ title, subtitle }) => (
    <Container maxWidth="sm">
      <DefaultPaper>
        <Typography variant="h5" align="center" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body1" align="center">
          {subtitle}
        </Typography>
      </DefaultPaper>
    </Container>
  );

  const displaySurvey = () => {
    if (!companyId || !surveyType) {
      return (
        <InvalidPage
          title="An error has occurred."
          subtitle="Please reload this page directly from the email you received."
        />
      );
    }

    if (dataLoading) {
      return (
        <Grid container justifyContent="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      );
    }

    if (!isSurveyAccessible) {
      return (
        <InvalidPage
          title={`${companyName} needs to upgrade their subscription`}
          subtitle="Your company's current subscription needs to be upgraded to access this survey."
        />
      );
    }

    if (status === "terminated") {
      return (
        <InvalidPage
          title="This page is expired"
          subtitle="If you believe you are seeing this message in error, please
              contact the Aclymate administrator for your company."
        />
      );
    }

    return (
      <NewEmployeeSurvey
        companyName={companyName}
        companyStartDate={startDate}
        employeeData={employeeData}
        mostRecentAccountingDate={mostRecentAccountingDate}
      />
    );
  };

  return (
    <>
      <Layout
        headerAction={
          <IconButton color="inherit" size="large">
            <FontAwesomeIcon icon={faQuestionCircle} />
          </IconButton>
        }
      >
        <Grid item style={{ maxHeight: "100%" }}>
          {displaySurvey()}
        </Grid>
      </Layout>
    </>
  );
};
export default EmployeeSurvey;
