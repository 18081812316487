import React from "react";

import { Autocomplete } from "@aclymatepackages/atoms";

import { useCachedDisplayData } from "../../../helpers/firebase";

const DbAutocomplete = ({
  multiple,
  collection,
  availableOptions,
  setValue,
  queries = [],
  ...otherProps
}) => {
  const [dbCollectionData] = useCachedDisplayData(collection, queries);

  const isMultiple = collection === "employees" || multiple;

  const options = availableOptions || dbCollectionData;

  const formatOutput = (newValue) => {
    if (!collection) {
      return newValue;
    }

    if (collection === "vehicles") {
      const { id, name, tonsCo2ePerMile, make, model, year, fuelType } =
        newValue || {};

      return (
        newValue &&
        (tonsCo2ePerMile
          ? { id, name, fuelType, tonsCo2ePerMile }
          : { id, name, make, model, year, fuelType })
      );
    }

    if (isMultiple) {
      return newValue.map((obj) => ({
        id: obj?.id,
        name: obj?.name,
        ...obj,
      }));
    }

    const { id, name } = newValue || {};
    return newValue && { id, name };
  };

  return (
    <Autocomplete
      setValue={(value) => setValue(formatOutput(value))}
      availableOptions={options}
      multiple={isMultiple}
      {...otherProps}
    />
  );
};
export default DbAutocomplete;
