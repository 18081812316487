import React, { useContext } from "react";

import { Grid, Typography } from "@mui/material";

import { faChartBar, faChartScatter } from "@fortawesome/pro-solid-svg-icons";
import { DefaultPaper, CategoriesAvatar } from "@aclymatepackages/atoms";
import { formatDecimal } from "@aclymatepackages/formatters";
import { subcategoriesWithColors } from "@aclymatepackages/subcategories";
import { sumTonsCo2e } from "@aclymatepackages/other-helpers";

import ViewBarChart from "../../../../modules/charts/ViewBarChart";
import ViewScatterPlot from "../../../../modules/charts/ViewScatterPlot";
import EmissionsCustomTooltip from "../../../../modules/charts/EmissionsCustomTooltip";
import ViewGraphCardLayout from "../../../../layouts/ViewGraphCard";

import { PlatformLayoutContext } from "../../../../../helpers/contexts/platformLayout";

const VendorsScatterPlotCustomTooltip = ({ payload }) => {
  const { displayUnitLabel } = useContext(PlatformLayoutContext);

  const { name, tonsCo2e, avgTonsCo2ePerTransaction, transactionsCount } =
    payload[0]?.payload || {};

  const displayData = [
    {
      title: "Number of transactions",
      subtitle: transactionsCount,
    },
    {
      title: `Average ${displayUnitLabel} per transaction`,
      subtitle: `${formatDecimal(
        avgTonsCo2ePerTransaction
      )} ${displayUnitLabel}`,
    },
  ];

  return (
    <DefaultPaper>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h6">{name}</Typography>
          <Typography
            variant="subtitle2"
            color="textSecondary"
          >{`${formatDecimal(
            tonsCo2e
          )} total ${displayUnitLabel} emitted (Bubble Size)`}</Typography>
        </Grid>
        {displayData.map(({ title, subtitle }, idx) => (
          <Grid item key={`tooltip-display-data-${idx}`}>
            <Typography variant="subtitle1">{title}</Typography>
            <Typography variant="body2" color="textSecondary">
              {subtitle}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </DefaultPaper>
  );
};

const VendorsBarChart = ({ dataArray, barAreasArray, setSelectedObject }) => {
  const { convertCarbonUnits } = useContext(PlatformLayoutContext);

  const formattedVendors = dataArray.map((vendor) => {
    const { transactions, name, id, tonsCo2e } = vendor;

    const uniqueTransactionSubcategories = [
      ...new Set(transactions.map(({ subcategory }) => subcategory)),
    ];

    const tonsCo2ePerSubcategory = uniqueTransactionSubcategories.map(
      (subcategory) => {
        const subcategoryTransactions = transactions.filter(
          (transaction) => transaction.subcategory === subcategory
        );
        return { subcategory, tonsCo2e: sumTonsCo2e(subcategoryTransactions) };
      }
    );

    const subcategoryVolumePropsObj = Object.fromEntries(
      tonsCo2ePerSubcategory.map(({ subcategory, tonsCo2e }) => [
        `${subcategory}TonsCo2e`,
        convertCarbonUnits(tonsCo2e),
      ])
    );

    return {
      id,
      emissionsSumTons: convertCarbonUnits(tonsCo2e),
      label: name,
      ...subcategoryVolumePropsObj,
    };
  });

  return (
    <ViewBarChart
      setSelectedObject={setSelectedObject}
      data={formattedVendors}
      barAreasArray={barAreasArray}
      tooltipComponent={
        <EmissionsCustomTooltip categoriesArray={barAreasArray} />
      }
    />
  );
};

const VendorsScatterPlot = ({ dataArray, setSelectedObject }) => {
  const { displayUnitLabel, convertCarbonUnits } = useContext(
    PlatformLayoutContext
  );

  const convertedDataArray = dataArray.map(
    ({ avgTonsCo2ePerTransaction, tonsCo2e, ...otherProps }) => ({
      ...otherProps,
      avgTonsCo2ePerTransaction: convertCarbonUnits(avgTonsCo2ePerTransaction),
      tonsCo2e: convertCarbonUnits(tonsCo2e),
    })
  );

  return (
    <ViewScatterPlot
      setSelectedObject={setSelectedObject}
      dataArray={convertedDataArray}
      yKey="transactionsCount"
      yName="Number of transactions"
      yLabel="Number of transactions"
      xKey="avgTonsCo2ePerTransaction"
      xName={`Average ${displayUnitLabel} Per Transaction`}
      xLabel={`Average ${displayUnitLabel} Per Transaction`}
      zKey="tonsCo2e"
      zName={`Total ${displayUnitLabel} CO2 Emitted (bubble size)`}
      tooltipComponent={<VendorsScatterPlotCustomTooltip type="vendors" />}
      name="Vendors"
      color="vendors"
    />
  );
};

const VendorsGraphs = ({ vendors, setSelectedVendor }) => {
  const filteredVendors = vendors.filter(
    ({ transactions = [], status }) =>
      status === "confirmed" && transactions.length
  );

  const barAreasArray = subcategoriesWithColors
    .filter(({ input }) => input)
    .map(({ subcategory, name, icon, color }) => {
      const dataKey = `${subcategory}TonsCo2e`;

      return {
        icon,
        color,
        subcategory: dataKey,
        dataKey,
        name,
        customAvatar: <CategoriesAvatar subcategory={subcategory} />,
      };
    });

  const graphs = [
    {
      title: "Total Emissions Per Vendor",
      type: "bar",
      icon: faChartBar,
      Graph: VendorsBarChart,
      graphProps: {
        barAreasArray,
        setSelectedObject: setSelectedVendor,
      },
      sortable: true,
    },
    {
      title:
        "Number of transactions vs. Average Tons CO2 emitted per transaction",
      type: "scatter",
      icon: faChartScatter,
      Graph: VendorsScatterPlot,
      graphProps: { setSelectedObject: setSelectedVendor },
    },
  ];

  return (
    <ViewGraphCardLayout
      subtitle="NOTE: We only show confirmed vendors with transactions"
      graphs={graphs}
      color="vendors"
      dataArray={filteredVendors}
      analyticsCta="to see how each of your vendors contributes to your carbon footprint"
    />
  );
};
export default VendorsGraphs;
