import React, { useContext } from "react";

import { nameToFirstName } from "@aclymatepackages/converters";

import OffsetSurvey from "../../inputs/flows/OffsetSurvey";

import {
  useCachedFirebaseCrud,
  useAccountData,
  useAuth,
  useCachedDisplayData,
} from "../../../helpers/firebase";
import { PlatformLayoutContext } from "../../../helpers/contexts/platformLayout";
import useEmissionsContext from "../../../helpers/contexts/emissions";
import { formatOffsetPreferencesForScoring } from "../../../helpers/components/offsetSurvey";
import { analyticsTrack } from "../../../helpers/analytics";
import { useCurrentCarbonBalanceTons } from "../../../helpers/hooks/companyData";

const PlatformOffsetSurvey = ({ setShowSurvey }) => {
  const [{ displayName }] = useAuth();
  const [
    {
      offsetPreferences: dbOffsetPreferences,
      name: companyName,
      isRemote,
      geography,
    },
  ] = useAccountData();
  const [companyOffices] = useCachedDisplayData("offices", [
    ["type", "==", "companyOffice"],
  ]);
  const { updateAccountData } = useCachedFirebaseCrud();
  const [currentCarbonBalanceTons, currentCarbonBalanceTonsLoading] =
    useCurrentCarbonBalanceTons();

  const { averageMonthlyRecurringEmissionsTons, recurringEmissionsLoading } =
    useEmissionsContext();
  const { activateSnackbar } = useContext(PlatformLayoutContext);

  const saveOffsetPreferences = async (offsetPreferences) => {
    activateSnackbar({
      message: "Your offset preferences were successfully saved.",
      alert: "success",
    });

    const formattedOffsetPreferences = await formatOffsetPreferencesForScoring(
      offsetPreferences
    );

    if (!dbOffsetPreferences) {
      analyticsTrack("Offset Preferences Set", {
        userName: nameToFirstName(displayName),
        companyName,
      });
    }

    return updateAccountData({ offsetPreferences: formattedOffsetPreferences });
  };

  return (
    <OffsetSurvey
      setShowSurvey={setShowSurvey}
      saveOffsetPreferences={saveOffsetPreferences}
      dbDataLoading={
        currentCarbonBalanceTonsLoading || recurringEmissionsLoading
      }
      accountData={{
        currentCarbonBalanceTons,
        averageMonthlyRecurringEmissionsTons,
        isRemote,
        geography,
      }}
      companyOffices={companyOffices}
    />
  );
};
export default PlatformOffsetSurvey;
