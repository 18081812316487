import React from "react";

import InputBlock from "./InputBlock";
import DbAutocomplete from "../autocomplete/DbAutocomplete";

const EventsInput = ({ event, setEvent, ...otherProps }) => (
  <InputBlock
    type="events"
    title="Tag an event for this transaction"
    cardData={event}
    {...otherProps}
  >
    <DbAutocomplete
      collection="events"
      setValue={setEvent}
      value={event || []}
      id="events-tag-input"
    />
  </InputBlock>
);
export default EventsInput;
