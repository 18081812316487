import React, { useState } from "react";

import {
  Box,
  Typography,
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";

const AccordionBox = ({
  isExpanded = false,
  contentTitle,
  avatarColor,
  avatarIcon,
  isChecked,
  children,
}) => {
  const { palette } = useTheme();

  const [expanded, setExpanded] = useState(undefined);

  return (
    <Accordion
      expanded={expanded === undefined ? isExpanded : expanded}
      onChange={() => setExpanded((expanded) => !expanded)}
    >
      <AccordionSummary
        style={{
          backgroundColor: palette.backgroundGray.light,
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${contentTitle}-content`}
        id={`${contentTitle}-header`}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box display="flex" gap={2}>
            <Avatar
              style={{
                width: 30,
                height: 30,
                backgroundColor: palette[avatarColor].main,
              }}
            >
              <FontAwesomeIcon icon={avatarIcon} size="sm" />
            </Avatar>
            <Typography variant="h6">{contentTitle}</Typography>
          </Box>
          {isChecked && (
            <FontAwesomeIcon
              icon={faCheckSquare}
              color={palette.secondary.main}
              size="lg"
            />
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
export default AccordionBox;
