import React, { useState } from "react";

import {
  Grid,
  Typography,
  CircularProgress,
  ButtonBase,
  Paper,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { DefaultPaper } from "@aclymatepackages/atoms";
import { useLayoutHelpers } from "@aclymatepackages/themes";
import { getYearlyTonsCo2eValues } from "@aclymatepackages/calcs";

import ReportContentCard from "./ReportCardContent";
import SubscriptionDetailsSlider from "./SubscriptionDetailsSlider";

import ProjectSummaryDisplay from "../modules/projects/ProjectSummaryDisplay";

import offsetBuckets from "../../helpers/components/display-lists/offsetBuckets";
import { useAdminProjects } from "../../helpers/components/projects";

const OffsetBucket = ({
  projects = [],
  offsetBucketKey,
  setSelectedOffsetBucket,
}) => {
  const [hover, setHover] = useState(false);

  const { title, icon, tagline, offsetCategories } =
    offsetBuckets[offsetBucketKey] || {};
  const projectsWithOffsetBucketCategories = projects.filter(
    ({ categories = [] }) =>
      offsetCategories.some((categorySlug) =>
        categories.map(({ slug }) => slug).includes(categorySlug)
      )
  );
  const offsetsToDisplay = projectsWithOffsetBucketCategories.slice(0, 5);
  const { images = [] } = offsetsToDisplay[0] || {};

  return (
    <ButtonBase
      onClick={() => setSelectedOffsetBucket(offsetBucketKey)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{ width: "100%", height: "100%", textAlign: "left" }}
    >
      <Paper
        elevation={hover ? 4 : 1}
        style={{ width: "100%", height: "100%", overflow: "hidden" }}
      >
        <ProjectSummaryDisplay
          project={{
            name: title,
            images,
            categories: [{ icon, name: tagline }],
          }}
        />
      </Paper>
    </ButtonBase>
  );
};

const OffsetBucketsBox = ({
  isDesktop,
  emissionsData,
  SubscriptionPurchaseFlow,
  numPeople,
}) => {
  const { theme } = useLayoutHelpers();
  const [adminProjects, adminProjectsLoading] = useAdminProjects();

  const [selectedOffsetBucket, setSelectedOffsetBucket] = useState(null);

  const offsetBucketKeys = Object.keys(offsetBuckets);

  const { yearlyTotalTonsCo2e } = getYearlyTonsCo2eValues(emissionsData);
  const OFFSET_SUBSCRIPTION_COST_PER_TON = 30;
  const monthlySubscriptionCost = Math.ceil(
    (yearlyTotalTonsCo2e / 12) * OFFSET_SUBSCRIPTION_COST_PER_TON
  );

  const offsetSubscriptionBenefits = [
    "Offset your entire carbon footprint",
    "Support a collection of offset projects",
  ];

  return (
    <>
      {selectedOffsetBucket && (
        <SubscriptionDetailsSlider
          selectedOffsetBucket={selectedOffsetBucket}
          setSelectedOffsetBucket={setSelectedOffsetBucket}
          SubscriptionPurchaseFlow={SubscriptionPurchaseFlow}
        />
      )}
      {adminProjectsLoading ? (
        <DefaultPaper style={{ width: "100%" }}>
          <Grid container item justifyContent="center">
            <CircularProgress />
          </Grid>
        </DefaultPaper>
      ) : (
        <ReportContentCard
          title={`Offset My ${numPeople > 1 ? "Household's " : ""}Footprint`}
          subtitle="The good news is that you can offset your footprint. Subscribe below to your favorite climate cause to support each month."
          isDesktop={isDesktop}
        >
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="stretch"
            direction={isDesktop ? "row" : "column"}
          >
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              spacing={2}
              direction={isDesktop ? "row" : "column"}
            >
              <Grid item>
                <Typography variant="h5" align="center">
                  {`$${monthlySubscriptionCost}`}
                </Typography>
                <Typography variant="subtitle1" align="center">
                  per month
                </Typography>
              </Grid>
              <Grid item>
                <Grid container spacing={2} direction="column">
                  {offsetSubscriptionBenefits.map((benefit, idx) => (
                    <Grid
                      container
                      item
                      key={idx}
                      spacing={2}
                      alignItems="center"
                      wrap="nowrap"
                    >
                      <Grid item>
                        <CheckCircleIcon
                          style={{ color: theme.palette.primary.main }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">{benefit}</Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            {offsetBucketKeys.map((offsetBucketKey, idx) => (
              <Grid item container key={idx} xs={isDesktop ? 4 : false}>
                <OffsetBucket
                  projects={adminProjects}
                  offsetBucketKey={offsetBucketKey}
                  setSelectedOffsetBucket={setSelectedOffsetBucket}
                />
              </Grid>
            ))}
          </Grid>
        </ReportContentCard>
      )}
    </>
  );
};
export default OffsetBucketsBox;
