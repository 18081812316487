import { useEffect, useState, createContext, useContext } from "react";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

import {
  buildEmissionsMonthsArray,
  calculateDefaultEmissionsClient,
  findRecurringEmissionsStateVariables,
  calcSeparateUtilitiesOfficesDefaultEmissions,
  deduplicateUtilitiesTransactionsPerOffice,
  buildAllEmissionsArray,
} from "@aclymatepackages/emissions-calcs";
import { splitScopeEmissions } from "@aclymatepackages/chart-helpers";

import { emissionStatuses } from "../components/primaryView";
import { PlatformLayoutContext } from "./platformLayout";
import { SandboxDataContext } from "./sandboxData";
import { AdminDataContext } from "./adminData";
import { getAccountCollectionAndId } from "../otherHelpers";
import { useCachedDisplayData, useAccountData } from "../firebase";
import { baseFetchHost } from "../utils/apiCalls";
import useAccountingData from "../hooks/accountingData";
import { useDisplayEvents } from "../components/events";

dayjs.extend(isSameOrAfter);

const EmissionsContext = createContext();

const buildEmissionStatus = ({ id, emissionDate, status }) => {
  if (id && status !== "confirmed") {
    return "unconfirmed";
  }

  if (dayjs(emissionDate).isAfter(dayjs())) {
    return "cleared";
  }

  return "confirmed";
};

const splitAndFormatEmissions = ({
  transactions,
  separateUtilitiesOfficesDefaultEmissions,
  monthlyCommuteEmissionsTons,
  monthlyUtilitiesEmissionsTons,
  mostRecentAccountingDate,
  events,
}) => {
  const eventsWithStatuses = events.length
    ? events.map((event) => {
        const { endDate: date } = event;

        const currentStatus = buildEmissionStatus({ emissionDate: date });
        const { severity } = emissionStatuses[currentStatus] || {};

        return {
          ...event,
          currentStatus,
          severity,
        };
      })
    : [];

  const allEmissions = buildAllEmissionsArray({
    transactions,
    separateUtilitiesOfficesDefaultEmissions,
    monthlyCommuteEmissionsTons,
    monthlyUtilitiesEmissionsTons,
    mostRecentAccountingDate,
    events: eventsWithStatuses,
  });

  const emissionsWithStatusObjs = allEmissions.map((emission) => {
    const { currentStatus } = emission;
    const { severity } = emissionStatuses[currentStatus] || {};

    return { ...emission, currentStatus, severity };
  });

  const splitEmissions = splitScopeEmissions(emissionsWithStatusObjs);

  const taggedOfficeTransactions = [
    ...transactions,
    ...separateUtilitiesOfficesDefaultEmissions,
  ].filter(({ office }) => office);

  const taggedEmployeesTransactions = transactions.filter(
    ({ taggedEmployeesIds }) => taggedEmployeesIds
  );

  return {
    allEmissions: splitEmissions,
    taggedOfficeTransactions,
    taggedEmployeesTransactions,
  };
};

export const EmissionsContextProvider = ({ children }) => {
  const { viewMode } = useContext(PlatformLayoutContext) || {};
  const { sandboxData } = useContext(SandboxDataContext) || {};
  const { adminData } = useContext(AdminDataContext) || {};
  const { companyData: sandboxCompanyData } = sandboxData || {};
  const { companyData: adminCompanyData } = adminData || {};
  const { startDate: sandboxStartDate } = sandboxCompanyData || {};
  const { startDate: adminStartDate } = adminCompanyData || {};

  const { id: companyId } = getAccountCollectionAndId();
  const [accountData, accountDataLoading] = useAccountData();
  const [{ mostRecentAccountingDate }, accountingDataLoading] =
    useAccountingData();
  const { startDate: companyStartDate, isRemote } = accountData;

  const [employees, employeesLoading] = useCachedDisplayData("employees");
  const [offices, officesLoading] = useCachedDisplayData("offices");
  const [dbTransactions, transactionsLoading] =
    useCachedDisplayData("transactions");
  const [events, eventsLoading] = useDisplayEvents();

  const [defaultEmissions, setDefaultEmissions] = useState({});

  const recurringEmissionsLoading =
    employeesLoading ||
    officesLoading ||
    accountDataLoading ||
    transactionsLoading ||
    eventsLoading ||
    accountingDataLoading;

  const emissionStartDates = {
    admin: adminStartDate,
    sandbox: sandboxStartDate,
    company: companyStartDate,
  };
  const emissionsStartDate = emissionStartDates[viewMode];

  const emissionsMonths = buildEmissionsMonthsArray(emissionsStartDate);

  const transactionsSinceStartDate = dbTransactions.filter((transaction) =>
    dayjs(transaction?.date).isSameOrAfter(emissionsStartDate)
  );

  const transactions = deduplicateUtilitiesTransactionsPerOffice(
    emissionsMonths,
    transactionsSinceStartDate
  );

  const separateUtilitiesOfficesDefaultEmissions =
    calcSeparateUtilitiesOfficesDefaultEmissions({
      companyId,
      startDate: emissionsStartDate,
      offices,
      transactions,
      baseFetchHost,
    });

  //This json is in here as a hack to prevent the useEffect to loop infinitely
  const jsonEmployees = JSON.stringify(employees);
  const jsonAccountData = JSON.stringify(accountData);

  useEffect(() => {
    if (!accountDataLoading && !employeesLoading) {
      calculateDefaultEmissionsClient(
        { ...JSON.parse(jsonAccountData), id: companyId },
        JSON.parse(jsonEmployees),
        baseFetchHost
      ).then((defaultEmissions) => setDefaultEmissions(defaultEmissions));
    }
  }, [
    jsonAccountData,
    accountDataLoading,
    jsonEmployees,
    employeesLoading,
    companyId,
  ]);

  const sandboxAdjustedDefaultEmissions =
    viewMode === "sandbox"
      ? {
          totalMonthlyTon: 0,
          monthlyEmployeesEmssionsTons: 0,
          monthlyGasCarbonTons: 0,
          monthlyElectricCarbonTons: 0,
        }
      : defaultEmissions;

  const buildOfficesCalcsArray = () => {
    if (offices.length) {
      return offices;
    }

    const { monthlyGasCarbonTons, monthlyElectricCarbonTons } =
      defaultEmissions;

    return [
      {
        type: "companyOffice",
        utilities: {
          monthlyElectricCarbonTons,
          monthlyGasCarbonTons,
          utilitiesInLease: true,
        },
      },
    ];
  };

  const {
    monthlyCommuteEmissionsTons,
    monthlyUtilitiesEmissionsTons,
    totalRecurringEmissionsTons,
    averageMonthlyRecurringEmissionsTons,
  } = findRecurringEmissionsStateVariables({
    employees,
    offices: buildOfficesCalcsArray(),
    companyStartDate: emissionsStartDate,
    defaultEmissions: sandboxAdjustedDefaultEmissions,
    emissionsMonths,
    isRemote,
    companyId,
  });

  const {
    allEmissions,
    taggedOfficeTransactions,
    taggedEmployeesTransactions,
  } = splitAndFormatEmissions({
    mostRecentAccountingDate,
    transactions,
    separateUtilitiesOfficesDefaultEmissions,
    monthlyCommuteEmissionsTons,
    monthlyUtilitiesEmissionsTons,
    events,
  });

  return (
    <EmissionsContext.Provider
      value={{
        emissionsMonths,
        monthlyCommuteEmissionsTons,
        monthlyUtilitiesEmissionsTons,
        totalRecurringEmissionsTons,
        averageMonthlyRecurringEmissionsTons,
        recurringEmissionsLoading,
        defaultEmissions,
        separateUtilitiesOfficesDefaultEmissions,
        transactions,
        allEmissions,
        taggedOfficeTransactions,
        taggedEmployeesTransactions,
      }}
    >
      {children}
    </EmissionsContext.Provider>
  );
};

const useEmissionsContext = () => useContext(EmissionsContext);
export default useEmissionsContext;
