import React, { useState, useEffect } from "react";

import { Box, LinearProgress } from "@mui/material";

import { Autocomplete } from "@aclymatepackages/atoms";

import { fetchOurApi } from "../../../helpers/utils/apiCalls";

const NaicsCodesAutocomplete = ({
  setNaicsCode,
  editVendor,
  naicsCode,
  size = "small",
  ...otherProps
}) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [naicsCodes, setNaicsCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchOurApi({
      path: "/transactions/get-naics-codes",
      method: "POST",
      callback: (res) => {
        if (!res || res.error) {
          return setErrorMsg(
            "An internal error occurred. Contact Aclymate support."
          );
        }

        setIsLoading(false);
        return setNaicsCodes(res);
      },
    });
  }, []);

  const naicsCodesOptions = naicsCodes.map(
    ({ naicsTitle, naicsCode, ...otherProps }) => ({
      name: naicsTitle,
      naicsCode: Number(naicsCode),
      ...otherProps,
    })
  );

  const onSetNaicsCode = (naicsCodeObj) => {
    if (editVendor) {
      const { naicsCode, name, tonsCo2ePerDollar } = naicsCodeObj || {};
      editVendor("tonsCo2ePerDollar")(tonsCo2ePerDollar);
      editVendor("naicsTitle")(name);
      return editVendor("naicsCode")(naicsCode);
    }

    return setNaicsCode(naicsCodeObj);
  };

  return (
    <>
      {isLoading ? (
        <Box py={1}>
          <LinearProgress />
        </Box>
      ) : (
        <Autocomplete
          size={size}
          label="Describe the industry"
          availableOptions={naicsCodesOptions}
          value={{
            name:
              naicsCodesOptions.find((option) => option.naicsCode === naicsCode)
                ?.name || "",
          }}
          helperText={errorMsg}
          setValue={onSetNaicsCode}
          error={!!errorMsg}
          style={{ minWidth: "200px" }}
          {...otherProps}
        />
      )}
    </>
  );
};
export default NaicsCodesAutocomplete;
