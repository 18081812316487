import React, { useState, useEffect, useContext, useRef } from "react";
import { FixedSizeList } from "react-window";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

import {
  Box,
  Grid,
  IconButton,
  Avatar,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  Tooltip,
  Typography,
  TableContainer,
  useTheme,
} from "@mui/material";
import SpaIcon from "@mui/icons-material/Spa";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import TableChartIcon from "@mui/icons-material/TableChart";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Checkbox,
  ToggleButtons,
  CategoriesAvatar,
} from "@aclymatepackages/atoms";
import {
  formatDate,
  formatDecimal,
  letterSBoolean,
} from "@aclymatepackages/formatters";
import {
  addDataRow,
  removeDataRow,
} from "@aclymatepackages/array-immutability-helpers";
import subcategories from "@aclymatepackages/subcategories";
import {
  buildScopesRealDataObj,
  buildSubcategoriesDataObj,
  buildEmissionGroupData,
  splitScopeEmissions,
} from "@aclymatepackages/chart-helpers";
import { hexToRgba } from "@aclymatepackages/converters";

import EditRowTransactions from "../../EditRowTransactions";

import EmissionsSourcesIcon from "../../../../atoms/icons/EmissionSourcesIcon";
import TagRowDisplay from "../../../../atoms/icons/TagRowDisplay";
import EmissionsCsvDownload from "../../../../atoms/buttons/EmissionsCsvDownload";

import { PlatformLayoutContext } from "../../../../../helpers/contexts/platformLayout";
import { emissionStatuses } from "../../../../../helpers/components/primaryView";
import { useCurrentCarbonBalanceTons } from "../../../../../helpers/hooks/companyData";
import {
  useCachedDisplayData,
  useCachedFirebaseCrud,
  useAccountData,
} from "../../../../../helpers/firebase";

dayjs.extend(isSameOrBefore);

const CHECKBOX_WIDTH = 48;
const SCROLLBAR_WIDTH = 15;
const TABLE_HEIGHT = 481;

const registerTableColumns = [
  { minWidth: 70, field: "date" },
  { minWidth: 50, field: "subcategory", label: "Category" },
  { minWidth: 150, field: "name", primary: true },
  { minWidth: 70, field: "emission" },
  { minWidth: 70, field: "offset" },
  { minWidth: 70, field: "balance" },
  {
    minWidth: 60,
    field: "scope",
    annotation:
      "Scope 1 emissions are direct greenhouse (GHG) emissions that occur from sources that are controlled or owned by an organization (e.g., emissions associated with fuel combustion in boilers, furnaces, vehicles). Scope 2 emissions are indirect GHG emissions associated with the purchase of electricity, steam, heat, or cooling. Scope 3 emissions are the result of activities from assets not owned or controlled by the reporting organization, but that the organization indirectly impacts in its value chain. Scope 3 emissions include all sources not within an organization’s scope 1 and 2 boundary.",
  },
];

const calcRowBalance = (rows, currentCarbonBalanceTons) => {
  let rowBalance = currentCarbonBalanceTons;
  return rows.map((row) => {
    const rowTonsCo2e = row.tonsCo2e;
    row.balance = rowBalance;
    rowBalance -= rowTonsCo2e;
    return row;
  });
};

const sortAndOrderRows = (rows, { field, isAsc }) => {
  const adjustedField = () => {
    if (field === "currentStatus") {
      return "severity";
    }

    if (field === "carbonVolume") {
      return "tonsCo2e";
    }

    return field;
  };

  const sortedRows = [...rows].sort((a, b) => {
    if (a[adjustedField()] > b[adjustedField()]) {
      return -1;
    }
    if (a[adjustedField()] < b[adjustedField()]) {
      return 1;
    }
    return 0;
  });

  if (isAsc) {
    return sortedRows.reverse();
  }

  return sortedRows;
};

const sortEmissionsByDate = (emissions) =>
  [...emissions].sort((a, b) => b.date - a.date);

const ActionsTransactionsBlock = ({
  tonsCo2e,
  transactionId,
  section,
  acceptDisabled,
  disabled,
}) => {
  const { palette } = useTheme();

  const { updateCollectionDoc } = useCachedFirebaseCrud();

  const generateAcceptIconTitle = () => {
    if (section === "all-emissions") {
      return "Accept default value for this transaction";
    }

    if (section === "reject-emissions" && !tonsCo2e) {
      return "Add more information to this transaction to add it to your balance";
    }

    return "Add transaction to balance";
  };

  return (
    <Grid container wrap="nowrap">
      <Grid item>
        <Tooltip
          title={generateAcceptIconTitle()}
          disableHoverListener={acceptDisabled || !tonsCo2e}
        >
          <span>
            <IconButton
              disabled={acceptDisabled || !tonsCo2e}
              onClick={(e) => {
                updateCollectionDoc("transactions", transactionId, {
                  status: "confirmed",
                });
                return e.stopPropagation();
              }}
              size="large"
            >
              <CheckCircleIcon
                fontSize="small"
                style={{
                  color:
                    acceptDisabled || !tonsCo2e
                      ? "inherit"
                      : palette.success.main,
                }}
              />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip
          title="Archive Transaction"
          disableHoverListener={disabled ? disabled : false}
        >
          <span>
            <IconButton
              disabled={disabled ? disabled : false}
              onClick={(e) => {
                updateCollectionDoc("transactions", transactionId, {
                  archived: true,
                });
                return e.stopPropagation();
              }}
              size="large"
            >
              <DeleteIcon
                fontSize="small"
                style={{
                  color: disabled ? "inherit" : palette.error.main,
                }}
              />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

const VirtualizedList = ({
  height,
  width,
  itemCount,
  itemSize,
  className,
  style,
  rowRenderFunc,
}) => {
  const otherProps = {
    height,
    width,
    itemCount,
    itemSize,
    className,
    style,
  };
  return <FixedSizeList {...otherProps}>{rowRenderFunc}</FixedSizeList>;
};

const VirtualizedListCellComponent = ({
  column,
  width,
  align = "left",
  style: { height },
  children,
  findColumnWidth,
}) => {
  const columnWidth = width ? `${width}px` : `${findColumnWidth(column)}px`;

  return (
    <TableCell
      component="div"
      style={{
        width: columnWidth,
        maxWidth: columnWidth,
        height: height,
        maxHeight: height,
        boxSizing: "border-box",
        textOverflow: "ellipsis",
      }}
      align={align}
    >
      {children}
    </TableCell>
  );
};

const TableHeaderCell = ({
  annotation,
  field,
  label,
  isColumnActive,
  onSortClick,
  isAsc,
  style,
  headerCheckboxProps,
}) => {
  const theme = useTheme();

  const createTableLabel = () =>
    !!onSortClick ? (
      <TableSortLabel
        active={isColumnActive}
        direction={isAsc ? "asc" : "desc"}
        onClick={onSortClick}
        component="div"
      >
        {(label || field)?.toUpperCase()}
      </TableSortLabel>
    ) : (
      <Typography variant="inherit">
        {(label || field)?.toUpperCase()}
      </Typography>
    );

  return (
    <TableCell component="div" style={style}>
      {!!annotation && (
        <Tooltip title={annotation}>
          <HelpOutlineIcon
            color="action"
            style={{
              verticalAlign: "middle",
              paddingBottom: 2,
              marginRight: theme.spacing(0.5),
            }}
            fontSize="small"
          />
        </Tooltip>
      )}
      {field === "checkbox" ? (
        <Checkbox {...headerCheckboxProps} />
      ) : (
        createTableLabel()
      )}
    </TableCell>
  );
};

const TableHeader = ({
  columns,
  width,
  findColumnWidth,
  selectedSort = {},
  sortRows,
  headerCheckboxProps,
}) => {
  const { field: sortField, isAsc } = selectedSort;
  const { palette } = useTheme();

  const headerCellStyles = (field) => {
    const columnWidth = findColumnWidth(field);
    return {
      flexBasis: field ? `${columnWidth}px` : CHECKBOX_WIDTH,
      display: "flex",
      flexGrow: 0,
      flexShrink: 0,
      boxSizing: "border-box",
      fontWeight: 600,
      border: "none",
      flexWrap: "nowrap",
      alignItems: "center",
    };
  };

  return (
    <TableHead
      component="div"
      style={{
        width: `${width - 25}px`,
        backgroundColor: palette.backgroundGray.light,
      }}
    >
      <TableRow
        component="div"
        style={{
          display: "flex",
          flexWrap: "nowrap",
          alignItems: "center",
          width: `${width}px`,
        }}
      >
        {columns.map((column, idx) => (
          <TableHeaderCell
            key={`table-header-cell-${idx}`}
            style={headerCellStyles(column.field)}
            isColumnActive={column.field === sortField}
            isAsc={isAsc}
            onSortClick={sortRows && (() => sortRows(column.field))}
            headerCheckboxProps={headerCheckboxProps}
            {...column}
          />
        ))}
      </TableRow>
    </TableHead>
  );
};

const VirtualizedTableRow = ({
  findColumnWidth,
  style,
  onClick,
  idx,
  tableCells,
}) => {
  const getCellComponentProps = (column) => ({
    column,
    style,
    findColumnWidth,
  });

  return (
    <TableRow
      style={{
        ...style,
        cursor: !!onClick ? "pointer" : "default",
      }}
      component="div"
      onClick={onClick ? () => onClick() : null}
      hover={!!onClick}
    >
      {tableCells.map(({ value, field, style }) => (
        <VirtualizedListCellComponent
          style={style}
          key={`table-row-${idx}-${field}`}
          {...getCellComponentProps(field)}
        >
          {value}
        </VirtualizedListCellComponent>
      ))}
    </TableRow>
  );
};

const VirtualizedTable = ({
  tableTitle,
  tableAction,
  width,
  rows,
  RowComponent,
  selectedTransaction,
  setSelectedTransaction,
  tableColumns,
  selectedSort,
  filteredRows,
  sortRows,
  isAsc,
  rowProps = {},
  headerCheckboxProps = {},
  viewMode,
}) => {
  const [columnWidths, setColumnWidths] = useState([]);

  useEffect(() => {
    const calculateColumnWidths = () => {
      const minWidthSum = tableColumns.reduce(
        (sum, { minWidth }) => Number(sum) + Number(minWidth),
        0
      );

      const newApproximateMinWidths = tableColumns.map((row) => {
        const { minWidth } = row;
        const rowPercentage = minWidth / minWidthSum;

        const newWidth = width * rowPercentage;
        return { ...row, minWidth: newWidth };
      });

      const newRoundedMinWidths = newApproximateMinWidths.map(
        ({ minWidth, ...otherProps }) => ({
          ...otherProps,
          minWidth: Math.floor(minWidth),
        })
      );

      const newMinWidthsSum = newRoundedMinWidths.reduce(
        (sum, { minWidth }) => Number(sum) + Number(minWidth),
        0
      );

      const columnWidths = newRoundedMinWidths.map(
        ({ minWidth, primary, ...column }) => {
          if (primary) {
            return { minWidth, ...column };
          }
          const newWidth =
            width - (newMinWidthsSum - minWidth) - SCROLLBAR_WIDTH;
          return { minWidth: newWidth, ...column };
        }
      );
      setColumnWidths(columnWidths);
    };

    if (width) {
      calculateColumnWidths();
    }
  }, [tableColumns, width]);

  const findColumnWidth = (field) =>
    Array.isArray(columnWidths) &&
    columnWidths.find((row) => row.field === field)?.minWidth;

  const VirtualizedTableRow = ({ index, style }) => {
    const rowData = rows[index];

    return (
      <RowComponent
        viewMode={viewMode}
        findColumnWidth={findColumnWidth}
        emission={rowData}
        style={style}
        selectedTransaction={selectedTransaction}
        setSelectedTransaction={setSelectedTransaction}
        idx={index}
        {...rowProps}
      />
    );
  };

  return (
    <>
      <Box mx={2}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Typography variant="h6">{tableTitle}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>{tableAction}</Grid>
              <Grid item>
                <EmissionsCsvDownload emissions={filteredRows} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Table style={{ width, minWidth: width }} component="div">
        <TableHeader
          columns={columnWidths}
          width={width}
          findColumnWidth={findColumnWidth}
          selectedSort={selectedSort}
          sortRows={sortRows}
          isAsc={isAsc}
          headerCheckboxProps={headerCheckboxProps}
        />
        <TableBody component="div">
          <VirtualizedList
            height={TABLE_HEIGHT}
            width={width}
            itemCount={rows.length}
            itemSize={80}
            rowRenderFunc={VirtualizedTableRow}
          />
        </TableBody>
      </Table>
    </>
  );
};

const EmissionsTableRowComponent = ({
  viewMode,
  style,
  findColumnWidth,
  emission,
  setSelectedTransaction,
  idx,
  selectedTransactionIds,
  onTransactionCheck,
  areAnyTransactionsNonRecurring,
}) => {
  const {
    date,
    name,
    subcategory,
    type,
    currentStatus,
    tonsCo2e,
    scope,
    id,
    source,
  } = emission || {};

  const { convertCarbonUnits } = useContext(PlatformLayoutContext);

  const { palette } = useTheme();

  const isTransaction = type === "transaction";

  const {
    icon: statusIcon,
    color: statusColor,
    tooltip: statusTooltip,
  } = emissionStatuses[currentStatus] || {};

  const isTransactionNonRecurring = ![
    "default-utilities",
    "recurring",
  ].includes(source);

  const buildCategoryScopeDisplayObj = () => {
    const isShowingSubcategories = viewMode === "subcategories";

    const field = isShowingSubcategories ? "subcategory" : "scope";

    const value =
      type === "transaction" && isShowingSubcategories ? (
        <TagRowDisplay {...emission} />
      ) : (
        <CategoriesAvatar
          viewMode={viewMode}
          scope={scope}
          subcategory={subcategory}
        />
      );
    return { field, value, clickable: false };
  };

  const checkBoxForUnconfirmedEmissions = areAnyTransactionsNonRecurring
    ? [
        {
          value: (
            <Tooltip
              disableHoverListener={isTransactionNonRecurring}
              title="You can only select unconfirmed transactions."
            >
              <span>
                <Checkbox
                  disabled={!isTransactionNonRecurring}
                  value={selectedTransactionIds.includes(id)}
                  editValue={() => onTransactionCheck(id)}
                />
              </span>
            </Tooltip>
          ),
          field: "checkbox",
        },
      ]
    : [];

  const actionsForUnconfirmedEmissions = areAnyTransactionsNonRecurring
    ? [
        {
          value: (
            <ActionsTransactionsBlock
              tonsCo2e={tonsCo2e}
              transactionId={id}
              section="all-emissions"
              acceptDisabled={
                currentStatus === "confirmed" || subcategory === "events"
              }
              disabled={!isTransactionNonRecurring || subcategory === "events"}
            />
          ),
          field: "actions",
        },
      ]
    : [];

  const tableCells = [
    ...checkBoxForUnconfirmedEmissions,
    {
      value: formatDate(date),
      field: "date",
    },
    {
      value: (
        <Tooltip title={statusTooltip}>
          <div>
            <FontAwesomeIcon
              icon={statusIcon}
              size="lg"
              style={{ color: palette[statusColor]?.main }}
            />
          </div>
        </Tooltip>
      ),
      field: "currentStatus",
    },
    {
      value: <EmissionsSourcesIcon emission={emission} />,
      field: "source",
    },
    buildCategoryScopeDisplayObj(),
    {
      value: name || "N/A",
      field: "name",
    },
    {
      value: (
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            {formatDecimal(Math.abs(convertCarbonUnits(tonsCo2e)))}
          </Grid>
          {currentStatus === "unconfirmed" && subcategory !== "spend-based" && (
            <Grid item>
              <Tooltip
                title="This is the default value we estimated for this transaction. You can either provide more information about this transaction or accept the default value"
                style={{ color: palette.error.main }}
              >
                <HelpOutlineIcon />
              </Tooltip>
            </Grid>
          )}
        </Grid>
      ),
      field: "carbonVolume",
    },
    ...actionsForUnconfirmedEmissions,
  ];

  const isClickable = isTransaction && currentStatus !== "locked";

  return (
    <VirtualizedTableRow
      findColumnWidth={findColumnWidth}
      style={{
        ...style,
      }}
      idx={idx}
      onClick={isClickable && (() => setSelectedTransaction(emission))}
      tableCells={tableCells}
    />
  );
};

const SummaryTable = ({ filteredRows, viewMode, tableRefPng, graphPeriod }) => {
  const { palette } = useTheme();
  const { convertCarbonUnits } = useContext(PlatformLayoutContext);

  const [{ startDate }] = useAccountData();

  const {
    groupedEmissions,
    scopesArray,
    subcategoriesArray,
    chartLabelsArray,
  } = buildEmissionGroupData(filteredRows, graphPeriod, startDate);

  const selectedSummaryTable =
    viewMode === "scopes"
      ? groupedEmissions.map((emission) =>
          buildScopesRealDataObj(splitScopeEmissions(emission))
        )
      : groupedEmissions.map((emission) => buildSubcategoriesDataObj(emission));

  const selectedSummaryTableLabel =
    viewMode === "scopes"
      ? scopesArray.filter(({ name }) => !name.includes("projected"))
      : subcategoriesArray.filter(({ name }) => !name.includes("projected"));

  return (
    <TableContainer ref={tableRefPng}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              style={{ backgroundColor: palette.backgroundGray.light }}
            >
              Dates
            </TableCell>
            {selectedSummaryTableLabel.map((label, index) => (
              <TableCell
                key={index}
                style={{ backgroundColor: palette.backgroundGray.light }}
              >
                {label.name}
              </TableCell>
            ))}
            <TableCell
              style={{ backgroundColor: palette.backgroundGray.light }}
            >
              Total
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {chartLabelsArray.map((year, index) => {
            const rowData = selectedSummaryTable[index];
            const rowValues = selectedSummaryTableLabel.map(
              (label) => rowData[label.subcategory]
            );
            const total = rowValues.reduce((sum, value) => sum + value, 0);
            return (
              <TableRow key={index} style={{ backgroundColor: "#fff" }}>
                <TableCell>{year}</TableCell>
                {selectedSummaryTableLabel.map((label, index) => (
                  <TableCell key={index}>
                    {formatDecimal(
                      convertCarbonUnits(rowData[label.subcategory])
                    )}
                  </TableCell>
                ))}
                <TableCell>
                  {formatDecimal(convertCarbonUnits(total))}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const EmissionsTable = ({ width, filteredRows, viewMode }) => {
  const { palette } = useTheme();
  const { displayUnitLabel } = useContext(PlatformLayoutContext);
  const { updateCollectionDoc } = useCachedFirebaseCrud();

  const findInitialSort = () => {
    const highSeverityRows = filteredRows.filter(
      ({ severity }) => severity > 1
    );

    if (highSeverityRows.length) {
      return { field: "currentStatus", isAsc: false };
    }

    return {
      field: "date",
      isAsc: false,
    };
  };

  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [selectedTransactionIds, setSelectedTransactionIds] = useState([]);
  const [selectedSort, setSelectedSort] = useState(findInitialSort());
  const { isAsc } = selectedSort;

  useEffect(() => {
    const compareIdsAndTransactions = () => {
      return setSelectedTransactionIds((prevSelectedTransactionIds) =>
        prevSelectedTransactionIds.filter((id) =>
          filteredRows.find((emission) => emission.id === id)
        )
      );
    };

    compareIdsAndTransactions();
  }, [filteredRows]);

  const nonRecurringTransactions = filteredRows.filter(
    ({ source }) => !["default-utilities", "recurring"].includes(source)
  );
  const isAnyNonRecurringTransactions = nonRecurringTransactions.length;

  const actionsHeader = isAnyNonRecurringTransactions
    ? [{ minWidth: 70, field: "actions" }]
    : [];

  const checboxHeader = isAnyNonRecurringTransactions
    ? [{ minWidth: 50, field: "checkbox" }]
    : [];

  const scopeSubcategoryRowObj =
    viewMode === "subcategories"
      ? { minWidth: 100, field: "subcategory", label: "category" }
      : { minWidth: 50, field: "scope" };

  const tableColumns = [
    ...checboxHeader,
    { minWidth: 70, field: "date" },
    { minWidth: 70, field: "currentStatus", label: "Status" },
    { minWidth: 65, field: "source" },
    scopeSubcategoryRowObj,
    { minWidth: 150, field: "name", primary: true },
    { minWidth: 70, field: "carbonVolume", label: `${displayUnitLabel} CO2` },
    ...actionsHeader,
  ];

  const onHeaderCheck = () => {
    if (selectedTransactionIds.length === nonRecurringTransactions.length) {
      return setSelectedTransactionIds([]);
    }

    const allRowsArray = nonRecurringTransactions.map(({ id }) => id);

    return setSelectedTransactionIds(allRowsArray);
  };

  const onTransactionCheck = (transactionId) => {
    const rowIdx = selectedTransactionIds.findIndex(
      (id) => id === transactionId
    );

    if (rowIdx === -1) {
      return addDataRow(setSelectedTransactionIds, transactionId);
    }

    return removeDataRow(rowIdx, setSelectedTransactionIds)();
  };

  const sortRows = (field) =>
    setSelectedSort(({ isAsc: currentIsAsc }) => ({
      field,
      isAsc: !currentIsAsc,
    }));

  const onUpdateBulkTransactions = (updateObj) => {
    selectedTransactionIds.forEach((id) =>
      updateCollectionDoc("transactions", id, updateObj)
    );
    return setSelectedTransactionIds([]);
  };

  const headerCheckboxProps = {
    value: selectedTransactionIds.length === nonRecurringTransactions.length,
    editValue: onHeaderCheck,
    indeterminate:
      !!selectedTransactionIds.length &&
      selectedTransactionIds.length < nonRecurringTransactions.length,
  };

  const selectedTransactionStatuses = selectedTransactionIds.map(
    (selectedId) => {
      const { status } = filteredRows.find(({ id }) => id === selectedId);
      return { id: selectedId, status };
    }
  );
  const areAnySelectedTransactionsConfirmed = selectedTransactionStatuses.find(
    ({ status }) => status === "confirmed"
  );

  return (
    <>
      {!!selectedTransaction && (
        <EditRowTransactions
          transaction={selectedTransaction || {}}
          setIsSlideOpen={setSelectedTransaction}
          saveButtonText="Save Changes"
        />
      )}
      <VirtualizedTable
        width={width}
        rows={sortAndOrderRows(filteredRows, selectedSort)}
        filteredRows={filteredRows}
        selectedSort={selectedSort}
        sortRows={sortRows}
        isAsc={isAsc}
        RowComponent={EmissionsTableRowComponent}
        rowProps={{
          selectedTransactionIds,
          onTransactionCheck,
          areAnyTransactionsNonRecurring: isAnyNonRecurringTransactions,
        }}
        setSelectedTransaction={setSelectedTransaction}
        tableColumns={tableColumns}
        tableTitle={
          selectedTransactionIds.length
            ? `Selected Transactions (${selectedTransactionIds.length})`
            : "All Emissions"
        }
        viewMode={viewMode}
        headerCheckboxProps={headerCheckboxProps}
        tableAction={
          !!selectedTransactionIds.length && (
            <>
              <Tooltip
                title={
                  areAnySelectedTransactionsConfirmed
                    ? "You can't accept default emissions for confirmed transactions"
                    : `Accept Default Emissions for ${
                        selectedTransactionIds.length
                      } transaction${letterSBoolean(selectedTransactionIds)}`
                }
              >
                <span>
                  <IconButton
                    size="large"
                    disabled={areAnySelectedTransactionsConfirmed}
                  >
                    <CheckCircleIcon
                      onClick={() =>
                        onUpdateBulkTransactions({ status: "confirmed" })
                      }
                      style={{
                        color: hexToRgba(
                          palette.success.main,
                          areAnySelectedTransactionsConfirmed ? 0.5 : 1
                        ),
                      }}
                    />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip
                title={`Archive ${
                  selectedTransactionIds.length
                } transaction${letterSBoolean(selectedTransactionIds)}`}
              >
                <span>
                  <IconButton size="large">
                    <DeleteIcon
                      onClick={() =>
                        onUpdateBulkTransactions({ archived: true })
                      }
                      style={{ color: palette.error.main }}
                    />
                  </IconButton>
                </span>
              </Tooltip>
            </>
          )
        }
      />
    </>
  );
};

const RegisterTableRowComponent = ({
  style,
  findColumnWidth,
  emission,
  idx,
}) => {
  const { date, offset, name, balance, subcategory, tonsCo2e } = emission;

  const { palette } = useTheme();
  const { convertCarbonUnits } = useContext(PlatformLayoutContext);

  const categoryObj = subcategories(subcategory);

  const tableCells = [
    { value: formatDate(date), field: "date" },
    {
      value: offset ? (
        <Tooltip title="Offset">
          <Avatar
            style={{
              backgroundColor: palette.secondary.main,
              height: 30,
              width: 30,
            }}
          >
            <SpaIcon />
          </Avatar>
        </Tooltip>
      ) : (
        <CategoriesAvatar subcategory={subcategory} />
      ),
      field: "subcategory",
    },
    {
      value: name || "N/A",
      field: "name",
    },
    {
      value: offset
        ? "--"
        : formatDecimal(Math.abs(convertCarbonUnits(tonsCo2e))),
      field: "emission",
    },
    {
      value: offset
        ? formatDecimal(Math.abs(convertCarbonUnits(tonsCo2e)))
        : "--",
      field: "offset",
    },
    { value: formatDecimal(convertCarbonUnits(balance)), field: "balance" },
    { value: categoryObj?.scope || 3, field: "scope" },
  ];

  return (
    <VirtualizedTableRow
      findColumnWidth={findColumnWidth}
      style={style}
      idx={idx}
      tableCells={tableCells}
    />
  );
};

const RegisterTable = ({ width, registerTableData }) => {
  return (
    <VirtualizedTable
      width={width}
      rows={registerTableData}
      RowComponent={RegisterTableRowComponent}
      tableColumns={registerTableColumns}
    />
  );
};

const PrimaryViewTables = ({
  allEmissions,
  emissionsLoading,
  summaryTableEmissions,
  viewMode,
  tableRefPng,
  graphPeriod,
  selectedChips,
}) => {
  const { palette } = useTheme();
  const [currentCarbonBalanceTons, currentCarbonBalanceTonsLoading] =
    useCurrentCarbonBalanceTons();
  const [offsets, offsetsLoading] = useCachedDisplayData("offsets");
  const { sideNavOpen } = useContext(PlatformLayoutContext);

  const blockLoading =
    emissionsLoading || offsetsLoading || currentCarbonBalanceTonsLoading;

  const [selectedTable, setSelectedTable] = useState("emissions");
  const [tableWidth, setTableWidth] = useState(0);

  const tableRef = useRef(null);

  useEffect(() => {
    if (tableRef.current) {
      setTableWidth(tableRef.current?.offsetWidth);
    }
  }, [tableRef, setTableWidth]);

  useEffect(() => {
    setTimeout(() => {
      setTableWidth(tableRef.current?.offsetWidth);
    }, 300);
  }, [tableRef, sideNavOpen, setTableWidth]);

  const formatRegisterTableData = () => {
    if (blockLoading) {
      return [];
    }

    const negativeSplitEmissions = allEmissions.map(
      ({ tonsCo2e, ...otherProps }) => ({
        tonsCo2e: Math.abs(tonsCo2e) * -1,
        ...otherProps,
      })
    );

    const removedZeroTonsFormattedEmissions = negativeSplitEmissions.filter(
      ({ tonsCo2e, electricRenewablesPercentage }) =>
        tonsCo2e || electricRenewablesPercentage === 100
    );

    const formattedOffsets = offsets
      .filter(({ status }) => status !== "failed")
      .map((offset) => ({ ...offset, offset: true }));

    const emissionsBeforeToday = removedZeroTonsFormattedEmissions.filter(
      ({ date }) => dayjs(date).isSameOrBefore(dayjs(), "day")
    );

    return calcRowBalance(
      sortEmissionsByDate([...formattedOffsets, ...emissionsBeforeToday]),
      currentCarbonBalanceTons
    );
  };

  const tableViews = [
    { value: "emissions", name: "Emissions" },
    { value: "register", name: "Register" },
    { value: "summary", name: "Summary" },
  ];

  const viewTables = {
    emissions: (
      <EmissionsTable
        width={tableWidth}
        filteredRows={summaryTableEmissions}
        viewMode={viewMode}
        selectedChips={selectedChips}
      />
    ),
    register: (
      <RegisterTable
        width={tableWidth}
        registerTableData={formatRegisterTableData()}
      />
    ),
    summary: (
      <SummaryTable
        filteredRows={summaryTableEmissions}
        viewMode={viewMode}
        tableRefPng={tableRefPng}
        graphPeriod={graphPeriod}
      />
    ),
  };

  return (
    <Paper ref={tableRef}>
      <Box p={2}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Avatar style={{ backgroundColor: palette.secondary.main }}>
                  <TableChartIcon />
                </Avatar>
              </Grid>
              <Grid item>
                <Typography variant="h3">Summary Tables</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <ToggleButtons
              value={selectedTable}
              onChange={setSelectedTable}
              buttons={tableViews}
            />
          </Grid>
        </Grid>
      </Box>
      {viewTables[selectedTable]}
    </Paper>
  );
};
export default PrimaryViewTables;
