import React, { useState, useEffect, useContext, useCallback } from "react";
import { Redirect } from "react-router-dom";
import { Box } from "@mui/material";

import { AnimatedLogo } from "@aclymatepackages/atoms";

import SignInForm from "./SignInForm";
import ChooseAccountForm from "./ChooseAccountForm";

import AccountAccessLayout from "../layouts/AccountAccessLayout";

import {
  handleLoggedInUser,
  useExistingIndividualData,
} from "../../helpers/components/accountAccess";
import { AccountAccessContext } from "../../helpers/contexts/accountAccess";
import { useAuth } from "../../helpers/firebase";
import { analyticsPage } from "../../helpers/analytics";

// Redeploy comment

const SignInBlock = ({
  setDialogOpen,
  chooseAccountFormOpen,
  setChooseAccountFormOpen,
}) => {
  const [loggedInUser, loggedInUserLoading] = useAuth();
  const { setAccountAccessError } = useContext(AccountAccessContext);
  const [individualData, individualDataLoading] = useExistingIndividualData();
  const { email: existingEmail } = individualData;

  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const externalDataLoading = loggedInUserLoading || individualDataLoading;

  const onLogin = useCallback(
    (user) =>
      handleLoggedInUser({
        user,
        setRedirect,
        setLoginLoading,
        setDialogOpen,
        setChooseAccountFormOpen,
        setAccountAccessError,
        existingIndividualEmail: existingEmail,
      }),
    [
      existingEmail,
      setAccountAccessError,
      setChooseAccountFormOpen,
      setDialogOpen,
    ]
  );

  useEffect(() => {
    if (
      !externalDataLoading &&
      loggedInUser?.uid &&
      !redirect &&
      !loginLoading
    ) {
      onLogin(loggedInUser);
    }
  }, [loggedInUser, redirect, loginLoading, externalDataLoading, onLogin]);

  const renderData = () => {
    if (externalDataLoading) {
      return (
        <Box display="flex" alignItems="center" justifyContent="center">
          <AnimatedLogo />
        </Box>
      );
    }

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    if (chooseAccountFormOpen) {
      return (
        <ChooseAccountForm
          chooseAccountFormOpen={chooseAccountFormOpen}
          setRedirect={setRedirect}
        />
      );
    }

    return (
      <SignInForm
        userEmail={userEmail}
        setUserEmail={setUserEmail}
        password={password}
        setPassword={setPassword}
        onLogin={onLogin}
        setAccountAccessError={setAccountAccessError}
        setDialogOpen={setDialogOpen}
        setChooseAccountFormOpen={setChooseAccountFormOpen}
        loginLoading={loginLoading}
        setLoginLoading={setLoginLoading}
        existingEmail={existingEmail}
        setRedirect={setRedirect}
      />
    );
  };

  return <>{renderData()}</>;
};

const Login = () => {
  const [chooseAccountFormOpen, setChooseAccountFormOpen] = useState(false);

  useEffect(() => {
    analyticsPage("login");
  }, []);

  return (
    <AccountAccessLayout
      title="Welcome back"
      subtitle={
        chooseAccountFormOpen
          ? "Choose which Aclymate account to access"
          : "Log back in to your account to keep saving the world."
      }
    >
      <SignInBlock
        chooseAccountFormOpen={chooseAccountFormOpen}
        setChooseAccountFormOpen={setChooseAccountFormOpen}
      />
    </AccountAccessLayout>
  );
};
export default Login;
