import React, { useState } from "react";

import {
  editRowData,
  addDataRow,
} from "@aclymatepackages/array-immutability-helpers";
import { TextField } from "@aclymatepackages/atoms";
import { incorrectNameCheck } from "@aclymatepackages/other-helpers";

import ScopeThreeCategorySelect from "./ScopeThreeCategorySelect";

import EmissionsCategorySelect from "./EmissionsCategorySelect";
import NaicsCodesAutocomplete from "../autocomplete/NaicsCodesAutocomplete";
import MultiPartFormLayout from "../flows/MultipartForm";
import AddNewFormQuestion from "../forms/AddNewFormQuestion";
import useTransactionsUploader from "../../hooks/transactionsCsvUploader";

import { useCachedFirebaseCrud } from "../../../helpers/firebase";
import { analyticsTrack } from "../../../helpers/analytics";

const VendorInputForm = ({ setOpen, isSliderLeftContent }) => {
  const [transactionsCsvUploaderSteps] = useTransactionsUploader({
    open: true,
    setOpen,
  });
  const { newCollectionDoc } = useCachedFirebaseCrud();

  const newVendorObj = { emissionCategory: "spend-based" };
  const [newVendors, setNewVendors] = useState([newVendorObj]);
  const [selectedOption, setSelectedOption] = useState("");

  const addNewVendor = () => addDataRow(setNewVendors, newVendorObj);

  const buildNewVendorInputRows = ({ vendor, editVendor }) => {
    const { name, naicsCode, scopeThreeCategory, emissionCategory } = vendor;

    const editVendorField = (field) => (value) => editVendor(field, value);

    const vendorNameInputRow = {
      label: "What do you call this vendor?",
      value: name,
      input: (
        <TextField
          label="Vendor Name"
          value={name}
          setValue={editVendorField("name")}
          error={name && incorrectNameCheck(name)}
          helperText={
            name &&
            incorrectNameCheck(name) &&
            "You need to have at least one non-numeric character in the vendor name and the name cannot have more than 50 characters"
          }
        />
      ),
    };

    const spendBasedInputs =
      emissionCategory === "spend-based"
        ? [
            {
              input: (
                <NaicsCodesAutocomplete
                  editVendor={editVendorField}
                  naicsCode={naicsCode}
                />
              ),
              value: naicsCode,
              label: "Choose a NAICS code for this vendor",
            },
            {
              input: (
                <ScopeThreeCategorySelect
                  scopeThreeCategory={scopeThreeCategory}
                  setScopeThreeCategory={editVendorField("scopeThreeCategory")}
                />
              ),
              value: scopeThreeCategory,
              label: "Choose a scope 3 category for this vendor",
            },
          ]
        : [];

    return [
      vendorNameInputRow,
      {
        label: "How are emissions from this vendor categorized?",
        value: emissionCategory,
        input: (
          <EmissionsCategorySelect
            value={emissionCategory}
            setValue={editVendorField("emissionCategory")}
          />
        ),
      },
      ...spendBasedInputs,
    ];
  };

  const onFormSubmit = async () => {
    await Promise.all(
      newVendors.map(async (vendor) => newCollectionDoc("vendors", vendor))
    );
    if (setOpen) {
      setOpen(false);
    }
    return analyticsTrack("Added Vendors Manually");
  };

  const FormBottomInput = ({ showNextForm }) => (
    <AddNewFormQuestion
      showNextForm={showNextForm}
      onAddNewForm={addNewVendor}
      onFormSubmit={onFormSubmit}
      label="vendor"
    />
  );

  const newVendorsForm = newVendors.map((vendor, idx) => ({
    label: vendor.name,
    title: vendor.name,
    rows: buildNewVendorInputRows({
      vendor,
      editVendor: editRowData(idx, setNewVendors),
    }),
    FormBottomInput,
  }));

  const forms = {
    add: newVendorsForm,
    transactionsCsv: transactionsCsvUploaderSteps,
  };

  return (
    <MultiPartFormLayout
      type="vendors"
      setOption={setSelectedOption}
      selectedOption={selectedOption}
      forms={forms[selectedOption]}
      onClose={!isSliderLeftContent && (() => setOpen(false))}
      submitLoadingText="Please wait while we load your new vendors..."
      style={isSliderLeftContent && { right: "40%" }}
    />
  );
};
export default VendorInputForm;
