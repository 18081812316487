import { useState, useCallback, useEffect } from "react";

import {
  Grid,
  Typography,
  Button,
  Tooltip,
  IconButton,
  Collapse,
  StyledEngineProvider,
  FormControl,
  ThemeProvider,
} from "@mui/material";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { ucFirstLetters } from "@aclymatepackages/formatters";
import { TextField, Select } from "@aclymatepackages/atoms";
import {
  addDataRow,
  editRowData,
} from "@aclymatepackages/array-immutability-helpers";
import { mainTheme } from "@aclymatepackages/themes";
import { incorrectNameCheck } from "@aclymatepackages/other-helpers";

import WarningPopup from "../atoms/notifications/WarningPopup";

import FuelTypesSelect from "../inputs/vehicles/FuelTypesSelect";
import VehicleYearSelect from "../inputs/vehicles/VehicleYearSelect";
import VehicleMakeModelSelect from "../inputs/vehicles/VehicleMakeModelSelect";
import VehicleForm from "../inputs/vehicles/VehicleForm";

import { useEmployeesApiDbData } from "../../helpers/components/inputs";
import {
  sendEmployeeSurvey,
  modifyEmployeeVehiclesInCommuteSchedules,
  buildNewEmployeeStatusLinks,
} from "../../helpers/components/employees";
import {
  useFetchVehicleFuelTypes,
  ALT_VEHICLE_FUEL_TYPES,
  ALT_VEHICLE_TYPES,
} from "../../helpers/components/vehicles";
import { generateSecureId } from "../../helpers/otherHelpers";
import { fetchOurApi } from "../../helpers/utils/apiCalls";
import {
  useCachedFirebaseCrud,
  useCachedDisplayData,
  useAccountData,
} from "../../helpers/firebase";

const useNewVehicleEditor = ({
  vehicle,
  setVehicle,
  ownerName,
  zipCode,
  survey,
  geography,
}) => {
  const fetchAvailableFuelTypes = useFetchVehicleFuelTypes();

  const { make, model, year, vehicleType } = vehicle || {};

  const [availableFuelTypes, setAvailableFuelTypes] = useState([]);

  const useThisZipCode = zipCode || geography?.address?.zipCode;

  const editVehicle = useCallback(
    (field) => (value) => {
      setVehicle(field, value);

      const onEditMake = () => {
        if (!value) {
          return setVehicle("model", null);
        }
        if (ownerName && !survey) {
          setVehicle("name", `${ownerName}'s ${value}`);
        }
        if (model && year && !vehicleType) {
          return fetchAvailableFuelTypes(
            value,
            model,
            year,
            setVehicle,
            setAvailableFuelTypes
          );
        }
        return null;
      };

      if (field === "make") {
        return onEditMake();
      }

      if (field === "model" && make && year && !vehicleType) {
        return fetchAvailableFuelTypes(
          make,
          value,
          year,
          setVehicle,
          setAvailableFuelTypes
        );
      }

      if (field === "year" && make && model && !vehicleType) {
        return fetchAvailableFuelTypes(
          make,
          model,
          value,
          setVehicle,
          setAvailableFuelTypes
        );
      }
    },
    [
      setVehicle,
      ownerName,
      make,
      model,
      year,
      fetchAvailableFuelTypes,
      vehicleType,
      survey,
    ]
  );

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const fetchTailpipeEmissions = (vehicle) => {
      const { make, model, year, fuelType, vehicleType } = vehicle;

      const areVehicleFieldsComplete = make && model && year && fuelType;

      if (fuelType === "phev") {
        return editVehicle("tonsCo2ePerMile")(null);
      }

      if (
        areVehicleFieldsComplete ||
        (vehicleType && areVehicleFieldsComplete)
      ) {
        return fetchOurApi({
          path: "/calcs/cars/tailpipe-emissions",
          method: "POST",
          data: { vehicle, zipCode: useThisZipCode },
          callback: ({ vehicleCarbonTonsPerMile }) =>
            editVehicle("tonsCo2ePerMile")(vehicleCarbonTonsPerMile),
          signal,
        });
      }
      return null;
    };

    fetchTailpipeEmissions(vehicle);

    return () => {
      controller.abort();
    };
  }, [vehicle, editVehicle, useThisZipCode]);

  return { availableFuelTypes, editVehicle };
};

const SingleVehicleDeleteIcons = ({
  isAboutToDelete,
  isVehicleUsedForCommuting,
  vehicle,
  archiveCompanyVehicle,
  archivePersonalVehicle,
  employee,
  setIsAboutToDelete,
}) =>
  isAboutToDelete && !isVehicleUsedForCommuting(vehicle) ? (
    <Grid container direction="row">
      <Grid item xs={9}>
        <Typography style={{ fontSize: "14px" }} align="center">
          Are you sure you would like to archive this vehicle?
        </Typography>
      </Grid>
      <Grid item container direction="row" xs={3}>
        <Grid item>
          <Tooltip title="Yes, I want to archive this vehicle">
            <span>
              <IconButton
                onClick={() =>
                  employee ? archivePersonalVehicle() : archiveCompanyVehicle()
                }
                size="large"
              >
                <CheckIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="No, I do not want to archive this vehicle">
            <span>
              <IconButton
                onClick={() => setIsAboutToDelete(false)}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Grid item>
      <Tooltip title="Archive this vehicle">
        <span>
          <IconButton
            onClick={() =>
              setIsAboutToDelete((isAboutToDelete) => !isAboutToDelete)
            }
            size="large"
          >
            <DeleteForeverIcon color="error" />
          </IconButton>
        </span>
      </Tooltip>
    </Grid>
  );

const SingleVehicleContent = ({
  newVehicle,
  availableFuelTypes,
  editVehicleHandler,
}) => (
  <FormControl variant="standard" fullWidth>
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <VehicleForm
          {...newVehicle}
          availableFuelTypes={availableFuelTypes}
          editVehicleHandler={editVehicleHandler}
        />
      </Grid>
    </Grid>
  </FormControl>
);

const SingleVehicleFooter = ({
  archiveVehicleWarning,
  isAboutToDelete,
  isVehicleUsedForCommuting,
  vehicle,
  disableSaveChanges,
  employee,
  savePersonalVehiclesChanges,
  saveCompanyVehicleChanges,
  archiveCompanyVehicle,
  archivePersonalVehicle,
  setIsAboutToDelete,
}) => (
  <Grid container alignItems="center" justifyContent="center" spacing={2}>
    <Collapse
      in={isAboutToDelete && isVehicleUsedForCommuting(vehicle)}
      timeout="auto"
      unmountOnExit
    >
      <Grid item>
        <WarningPopup
          warningTitle={archiveVehicleWarning.title}
          warningInput={archiveVehicleWarning.input}
        />
      </Grid>
    </Collapse>
    <Grid item container alignItems="center">
      <Grid item>
        <Button
          color="primary"
          variant="contained"
          disabled={disableSaveChanges}
          onClick={
            employee ? savePersonalVehiclesChanges : saveCompanyVehicleChanges
          }
        >
          Save Changes
        </Button>
      </Grid>
      <Grid item>
        <SingleVehicleDeleteIcons
          isAboutToDelete={isAboutToDelete}
          isVehicleUsedForCommuting={isVehicleUsedForCommuting}
          vehicle={vehicle}
          archiveCompanyVehicle={archiveCompanyVehicle}
          archivePersonalVehicle={archivePersonalVehicle}
          employee={employee}
          setIsAboutToDelete={setIsAboutToDelete}
        />
      </Grid>
    </Grid>
  </Grid>
);

const NoVehicleContent = () => (
  <Grid item>
    <Typography variant="h6" align="center">
      There is no vehicle information to display.
    </Typography>
  </Grid>
);

const buildNewVehicleInputFormRows = ({
  vehicle,
  editVehicle,
  index,
  availableFuelTypes,
  setNewVehicles,
}) => {
  const {
    make,
    model,
    year,
    name,
    fuelType,
    vehicleType = null,
  } = vehicle || {};

  const handleSetShowHeavyVehicleForm = (newVehicleType) =>
    setNewVehicles((vehicles) =>
      vehicles.map((vehicleRow, mapIdx) => {
        if (mapIdx === index) {
          return { vehicleType: newVehicleType };
        }
        return vehicleRow;
      })
    );

  const onEditVehicle = (field) => (value) => editVehicle(field, value);

  const buildFuelTypesInputRow =
    availableFuelTypes.length > 1
      ? [
          {
            label: "Fuel Types",
            value: fuelType,
            input: (
              <FuelTypesSelect
                availableFuelTypes={availableFuelTypes}
                fuelType={fuelType}
                editVehicle={onEditVehicle("fuelType")}
                id={`vehicleFuelSelect-${index}`}
              />
            ),
          },
        ]
      : [];

  const vehicleMakeInputRow = {
    label: "Vehicle Make",
    value: make,
    input: (
      <VehicleMakeModelSelect
        type="makes"
        vehicleValue={make}
        editValue={(value) => {
          onEditVehicle("make")(value);
          onEditVehicle("model")("");
        }}
        idx={index}
        handleSetShowHeavyVehicleForm={handleSetShowHeavyVehicleForm}
      />
    ),
  };

  const vehicleModelInputRow = {
    label: "Vehicle Model",
    value: model,
    input: (
      <VehicleMakeModelSelect
        type="models"
        vehicleValue={model}
        editValue={onEditVehicle("model")}
        make={make}
        idx={index}
        handleSetShowHeavyVehicleForm={handleSetShowHeavyVehicleForm}
      />
    ),
  };

  const vehicleYearInputRow = {
    label: "Vehicle Year",
    value: year,
    input: (
      <VehicleYearSelect
        year={year}
        editVehicle={onEditVehicle("year")}
        noLabel={false}
        id={`vehicleYear-select-${index}`}
        smallFont
      />
    ),
  };

  const vehicleNameInputRow = {
    label: "Name",
    value: name && !incorrectNameCheck(name, 75),
    input: (
      <TextField
        label="Vehicle Name"
        value={name || ""}
        setValue={onEditVehicle("name")}
        id={`vehicleNameInput-${index}`}
        error={name && incorrectNameCheck(name, 75)}
        helperText={
          name &&
          incorrectNameCheck(name, 75) &&
          "You need to have at least one non-numeric character in the name and the name cannot have more than 75 characters"
        }
      />
    ),
  };

  const regularVehicleForm = [
    vehicleMakeInputRow,
    vehicleModelInputRow,
    vehicleYearInputRow,
    ...buildFuelTypesInputRow,
    vehicleNameInputRow,
  ];

  const heavyVehicleForm = [
    {
      label: "Vehicle Type",
      value: vehicleType,
      input: (
        <Select
          size="small"
          label={"Vehicle Type"}
          value={vehicleType || ""}
          editValue={onEditVehicle("vehicleType")}
          options={[
            ...ALT_VEHICLE_TYPES,
            {
              label: (
                <Button onClick={() => handleSetShowHeavyVehicleForm(null)}>
                  Back to regular vehicles
                </Button>
              ),
            },
          ]}
          smallFont
        />
      ),
    },
    {
      label: "Vehicle Make",
      value: make,
      input: (
        <TextField
          label="Vehicle Make"
          value={make || ""}
          setValue={onEditVehicle("make")}
        />
      ),
    },
    {
      label: "Vehicle Model",
      value: model,
      input: (
        <TextField
          label="Vehicle Model"
          value={model || ""}
          setValue={onEditVehicle("model")}
        />
      ),
    },
    {
      label: "Fuel Type",
      value: fuelType,
      input: (
        <Select
          size="small"
          label={"Fuel Type"}
          value={fuelType || ""}
          editValue={onEditVehicle("fuelType")}
          options={ALT_VEHICLE_FUEL_TYPES}
          smallFont
        />
      ),
    },
    vehicleYearInputRow,
    vehicleNameInputRow,
  ];

  return vehicleType === null ? regularVehicleForm : heavyVehicleForm;
};

const AddNewVehicleQuestion = ({
  addNewVehicleQuestionText,
  showNextForm,
  addNewVehicle,
  disableShowNextForm = false,
  onNewVehicleSubmit,
  noEmployeeVehicles,
}) => {
  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={mainTheme}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
              <Typography variant="h6">{addNewVehicleQuestionText}</Typography>
            </Grid>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <Button
                  color="primary"
                  onClick={() => {
                    addNewVehicle();
                    if (!disableShowNextForm) {
                      showNextForm();
                    }
                  }}
                  variant="contained"
                  endIcon={<ChevronRightIcon fontSize="large" />}
                >
                  Yes
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="secondary"
                  onClick={() => {
                    showNextForm();
                    if (noEmployeeVehicles) {
                      noEmployeeVehicles(true);
                    }
                    if (onNewVehicleSubmit) {
                      onNewVehicleSubmit();
                    }
                  }}
                  variant="contained"
                  endIcon={<ChevronRightIcon fontSize="large" />}
                >
                  No
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

export const useNewVehiclesForm = ({
  newEmployeeName,
  newVehicles,
  setNewVehicles,
  onNewVehicleSubmit,
  companyName,
  survey,
}) => {
  const fetchAvailableFuelTypes = useFetchVehicleFuelTypes();
  const [companyData] = useEmployeesApiDbData("data");
  const { geography } = companyData || {};
  const { address } = geography || {};
  const { zipCode } = address || {};

  const [activeVehicleIdx, setActiveVehicleIdx] = useState(
    newVehicles.length === 0 ? -1 : 0
  );
  const [availableFuelTypes, setAvailableFuelTypes] = useState([]);
  const [noEmployeeVehicles, setNoEmployeeVehicles] = useState(false);

  const { year, make, model } = newVehicles[activeVehicleIdx] || {};
  const currentVehicle = newVehicles[activeVehicleIdx];
  const editCurrentVehicle = editRowData(activeVehicleIdx, setNewVehicles);
  const currentName = newEmployeeName || companyName;
  const newVehicleInitialData = companyName
    ? {}
    : {
        id: generateSecureId(),
      };

  const addNewVehicleRow = () => {
    addDataRow(setNewVehicles, newVehicleInitialData);
    setActiveVehicleIdx(activeVehicleIdx + 1);
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const { year, make, model, fuelType, tonsCo2ePerMile } =
      currentVehicle || {};

    if (make && model && year && fuelType && !tonsCo2ePerMile) {
      fetchOurApi({
        path: "/calcs/cars/tailpipe-emissions",
        method: "POST",
        data: {
          vehicle: currentVehicle,
          zipCode,
        },
        callback: ({ vehicleCarbonTonsPerMile }) =>
          editCurrentVehicle("tonsCo2ePerMile", vehicleCarbonTonsPerMile),
        signal,
      });
    }

    return () => {
      controller.abort();
    };
  }, [currentVehicle, editCurrentVehicle, zipCode]);

  useEffect(() => {
    if (activeVehicleIdx > -1 && make) {
      editCurrentVehicle("name", `${currentName}'s ${year} ${make} ${model}`);
      fetchAvailableFuelTypes(
        make,
        model,
        year,
        editCurrentVehicle,
        setAvailableFuelTypes
      );
    }
    if (!make && model) {
      editCurrentVehicle("model", "");
    }
    // TODO: Figure out how to resolve infinite loop in this useEffect
    // when using editCurrentVehicle in dependency array
    // eslint-disable-next-line
  }, [make, model, year]);

  const buildAddNewVehicleQuestionText = () => {
    if (survey) {
      return "Have you used another vehicle to commute?";
    }

    if (!newEmployeeName) {
      return "Is there another company vehicle you would like to add?";
    }

    return "Has this employee used another vehicle to commute?";
  };

  const AnyVehiclesQuestion = ({ showNextForm }) => (
    <AddNewVehicleQuestion
      addNewVehicle={() => addNewVehicleRow()}
      showNextForm={showNextForm}
      disableShowNextForm={true}
      onNewVehicleSubmit={onNewVehicleSubmit}
      noEmployeeVehicles={setNoEmployeeVehicles}
    />
  );

  const AddNewVehicleQuestionInput = ({ showNextForm }) => (
    <AddNewVehicleQuestion
      addNewVehicleQuestionText={buildAddNewVehicleQuestionText()}
      addNewVehicle={() => addNewVehicleRow()}
      showNextForm={showNextForm}
      onNewVehicleSubmit={onNewVehicleSubmit}
    />
  );

  if (newEmployeeName && newVehicles.length < 1) {
    return !noEmployeeVehicles
      ? [
          {
            label: survey ? "Commute Vehicles" : "Employee Commute Vehicles",
            title: survey
              ? "Since you've started working at this company, have you had any commute vehicles?"
              : "Has this employee had any commute vehicles since they started working here?",
            titleAlign: "center",
            rows: [],
            FormBottomInput: AnyVehiclesQuestion,
          },
        ]
      : [
          {
            label: survey ? "Commute Vehicles" : "Employee Commute Vehicles",
            title: survey
              ? "You don't have any personal commute vehicles"
              : "This employee doesn't have any personal commute vehicles",
            rows: [
              {
                input: (
                  <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={mainTheme}>
                      <Grid container justifyContent="center">
                        <Grid item>
                          <Typography variant="h6">
                            {survey
                              ? "You don't have any personal commute vehicles"
                              : "This employee doesn't have any personal commute vehicles"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ThemeProvider>
                  </StyledEngineProvider>
                ),
              },
            ],
          },
        ];
  }

  const buildFormTitle = ({ vehicle, idx }) => {
    const { name, year, make, model } = vehicle;

    if (!name && !year && !make && !model && idx === 0 && companyName) {
      return "Let's start by inputting some car info below";
    }
    if (!name && !year && !make && !model && idx === 0) {
      return "Great! Let's start by inputting some car info below";
    }
    if (!name && !year && !make && !model && idx > 0) {
      return "Now tell us about this vehicle";
    }
    if (newEmployeeName) {
      return `${ucFirstLetters(newEmployeeName)}'s ${
        ucFirstLetters(year) || ""
      } ${ucFirstLetters(make) || ""} ${ucFirstLetters(model) || ""}`;
    }

    return `${ucFirstLetters(year) || ""} ${ucFirstLetters(make) || ""} ${
      ucFirstLetters(model) || ""
    }`;
  };

  return newVehicles.map((vehicle, idx) => ({
    label: buildFormTitle({ vehicle, idx }),
    title: buildFormTitle({ vehicle, idx }),
    rows: buildNewVehicleInputFormRows({
      vehicle,
      editVehicle: editRowData(idx, setNewVehicles),
      setNewVehicles,
      index: idx,
      availableFuelTypes,
    }),
    FormBottomInput: AddNewVehicleQuestionInput,
  }));
};

export const useEditableVehicle = ({
  newVehicle,
  employee,
  setVehicle,
  ownerName,
  zipCode,
  displayVehicles,
  setDisplayVehicles,
  survey,
  companyData,
}) => {
  const { updateCollectionDoc } = useCachedFirebaseCrud();
  const [employees] = useEmployeesApiDbData("employee-commute-schedules");
  const { editVehicle, availableFuelTypes } = useNewVehicleEditor({
    vehicle: newVehicle,
    setVehicle,
    ownerName,
    zipCode,
    survey,
    ...companyData,
  });

  const { id: employeeId } = employee || {};

  const [isVehicleEdited, setIsVehicleEdited] = useState(false);

  const editVehicleHandler = (field) => (value) => {
    setIsVehicleEdited(true);
    return editVehicle(field)(value);
  };

  const removeUnnecessaryFieldsFromVehicle = (vehicle) => {
    const {
      employee,
      emissions,
      lbsCo2PerMile,
      monthlyDistanceMi,
      status,
      statusTooltip,
      statusEmissionsDetailsTooltip,
      totalMonthlyEmissions,
      isNewAndUnsaved,
      severity,
      volumeCo2PerMile,
      color,
      icon,
      ...otherVehicleFields
    } = vehicle;

    return otherVehicleFields;
  };

  const searchThroughCurrentCommuteScheduleForVehicle = (
    commuteSchedules,
    { id: vehicleId }
  ) => {
    const [{ commuteEndpoints = [] }] = commuteSchedules;
    const nonHomeEndpoints = commuteEndpoints.filter(
      ({ type }) => type !== "homeOffice"
    );
    const allCommuteVehicles = nonHomeEndpoints.flatMap(
      ({ vehicles }) => vehicles
    );

    return allCommuteVehicles.find(
      ({ id: commuteVehicleId }) => commuteVehicleId === vehicleId
    );
  };

  const isVehicleUsedForCommuting = (vehicle) =>
    employees.find(({ commuteSchedules = [{}] }) =>
      searchThroughCurrentCommuteScheduleForVehicle(commuteSchedules, vehicle)
    );

  const doesEmployeeUseVehicleForCommuting = (vehicle, { commuteSchedules }) =>
    searchThroughCurrentCommuteScheduleForVehicle(commuteSchedules, vehicle);

  const createArchivedVehicleCommuteSchedules = (
    vehicleToArchive,
    employee
  ) => {
    const doesCurrentCommuteScheduleContainVehicleToDelete = () => {
      const { commuteSchedules } = employee;
      const [currentCommuteSchedule] = commuteSchedules;
      const { commuteEndpoints: currentCommuteScheduleEndpoints } =
        currentCommuteSchedule;

      return currentCommuteScheduleEndpoints.find(({ vehicles = [] }) =>
        vehicles.find((vehicle) => vehicle.id === vehicleToArchive.id)
      );
    };

    const archiveCurrentCommuteSchedule = () =>
      employee.commuteSchedules.map((commuteSchedule, idx) =>
        !idx
          ? {
              ...commuteSchedule,
              endDate: new Date(),
            }
          : commuteSchedule
      );

    if (doesCurrentCommuteScheduleContainVehicleToDelete()) {
      return archiveCurrentCommuteSchedule();
    }

    return employee.commuteSchedules;
  };

  const archivePersonalVehicle = () => {
    const archivedCurrentCommuteSchedule =
      createArchivedVehicleCommuteSchedules(newVehicle, employee);
    const { vehicles } = employee;

    const archivedVehicleArray = vehicles.map((vehicle) => {
      const otherVehicleFields = removeUnnecessaryFieldsFromVehicle(vehicle);

      if (vehicle.id === newVehicle.id) {
        return {
          ...otherVehicleFields,
          archived: true,
        };
      }

      return vehicle;
    });

    const employeeUpdateObj = {
      commuteSchedules: archivedCurrentCommuteSchedule,
      vehicles: archivedVehicleArray,
      links: buildNewEmployeeStatusLinks(
        employee,
        isVehicleUsedForCommuting(newVehicle) ? "incomplete" : "confirmed"
      ),
    };

    const updateEmployeeInDb = async () => {
      if (survey) {
        return await fetchOurApi({
          path: "/individuals/update",
          method: "POST",
          data: {
            individualId: employeeId,
            updateObj: employeeUpdateObj,
          },
        });
      }

      return updateCollectionDoc("employees", employeeId, employeeUpdateObj);
    };

    updateEmployeeInDb();

    const setPersonalDisplayVehiclesAfterArchival = () => {
      if (Array.isArray(displayVehicles)) {
        return setDisplayVehicles(
          archivedVehicleArray.filter(({ archived }) => !archived)
        );
      }

      return setDisplayVehicles({});
    };

    return setPersonalDisplayVehiclesAfterArchival();
  };

  const archiveCompanyVehicle = () => {
    employees.map((employee) => {
      if (!employee.commuteSchedules) {
        return null;
      }

      const archivedCurrentCommuteSchedule =
        createArchivedVehicleCommuteSchedules(newVehicle, employee);

      const newLinks = doesEmployeeUseVehicleForCommuting(newVehicle, employee)
        ? {
            links: buildNewEmployeeStatusLinks(employee, "incomplete"),
          }
        : {};

      const employeeUpdateObj = {
        commuteSchedules: archivedCurrentCommuteSchedule,
        ...newLinks,
      };

      return updateCollectionDoc("employees", employee.id, employeeUpdateObj);
    });

    const vehicleUpdateObj = {
      archived: true,
    };

    updateCollectionDoc("vehicles", newVehicle.id, vehicleUpdateObj);

    return setDisplayVehicles({});
  };

  const savePersonalVehiclesChanges = () => {
    const modifiedVehiclesCommuteSchedules =
      modifyEmployeeVehiclesInCommuteSchedules({ ...employee, newVehicle });
    const { vehicles } = employee;
    const { id: newVehicleId } = newVehicle;

    const vehiclesWithoutUnncessaryFields = vehicles.map((vehicle) => {
      if (vehicle.id === newVehicle.id) {
        return removeUnnecessaryFieldsFromVehicle(newVehicle);
      }

      return removeUnnecessaryFieldsFromVehicle(vehicle);
    });

    const newEmployeeVehicles = !vehiclesWithoutUnncessaryFields.find(
      ({ id: vehicleId }) => vehicleId === newVehicleId
    )
      ? [
          ...vehiclesWithoutUnncessaryFields,
          removeUnnecessaryFieldsFromVehicle(newVehicle),
        ]
      : vehiclesWithoutUnncessaryFields;

    const employeeUpdateObj = {
      commuteSchedules: modifiedVehiclesCommuteSchedules,
      vehicles: newEmployeeVehicles,
    };

    return updateCollectionDoc("employees", employeeId, employeeUpdateObj);
  };

  const saveCompanyVehicleChanges = () => {
    employees.map(({ id: employeeId, commuteSchedules }) => {
      if (!Array.isArray(commuteSchedules)) {
        return null;
      }

      const modifiedEmployeeCommuteSchedule =
        modifyEmployeeVehiclesInCommuteSchedules({
          commuteSchedules,
          newVehicle,
        });

      const employeeUpdateObj = {
        commuteSchedules: modifiedEmployeeCommuteSchedule,
      };

      return updateCollectionDoc("employees", employeeId, employeeUpdateObj);
    });

    const vehicleUpdateObject = removeUnnecessaryFieldsFromVehicle(newVehicle);

    return updateCollectionDoc("vehicles", newVehicle.id, vehicleUpdateObject);
  };

  return {
    saveCompanyVehicleChanges,
    savePersonalVehiclesChanges,
    archiveCompanyVehicle,
    archivePersonalVehicle,
    isVehicleEdited,
    isVehicleUsedForCommuting,
    doesEmployeeUseVehicleForCommuting,
    newVehicle,
    editVehicleHandler,
    availableFuelTypes,
  };
};

export const useSingleVehicleForm = ({
  vehicle = {},
  employee,
  setVehicle,
  setDisplayVehicle,
}) => {
  const { name: employeeName, address: employeeAddress } = employee || {};
  const [companyData] = useAccountData();
  const [employees] = useCachedDisplayData("employees");

  const { name: companyName, geography } = companyData || {};

  const { address: companyAddress } = geography || {};
  const ownerName = employeeName || companyName;
  const zipCode = employeeAddress?.zipCode || companyAddress?.zipCode;
  const { make, model, year, fuelType } = vehicle;

  const {
    savePersonalVehiclesChanges,
    saveCompanyVehicleChanges,
    archivePersonalVehicle,
    archiveCompanyVehicle,
    isVehicleEdited,
    doesEmployeeUseVehicleForCommuting,
    isVehicleUsedForCommuting,
    newVehicle,
    editVehicleHandler,
    availableFuelTypes,
  } = useEditableVehicle({
    newVehicle: vehicle,
    employee,
    setVehicle,
    ownerName,
    zipCode,
    displayVehicles: vehicle,
    setDisplayVehicles: setDisplayVehicle,
    companyData,
  });

  const [isAboutToDelete, setIsAboutToDelete] = useState(false);

  const sendEmployeeSurveysToCompanyVehicleCommuters = () =>
    employees.map(
      (employee) =>
        doesEmployeeUseVehicleForCommuting(vehicle, employee) &&
        sendEmployeeSurvey({ employee })
    );

  const sendSurveys = () => {
    if (employee) {
      sendEmployeeSurvey({ employee });
      return archivePersonalVehicle();
    }

    sendEmployeeSurveysToCompanyVehicleCommuters();
    return archiveCompanyVehicle();
  };

  const archiveVehicleWarningTitle = employee
    ? `Archiving this vehicle will cause the current commute schedule for ${employeeName} to become incomplete. How would you like to fix this?`
    : "Archiving this vehicle will cause the current commute schedule for any employee that uses this company vehicle to become incomplete. How would you like to fix this?";

  const adminFixCommuteSchedulesButtonText = employee
    ? `I'll fix the current commute schedule for ${employeeName} myself`
    : "I'll fix each employee's commute schedule myself";

  const sendSurveyButtonText = employee
    ? `Send a survey to ${employeeName} to update their current commute schedule`
    : "Send a survey to each employee to update their current commute schedules";

  const disableSaveChanges =
    !isVehicleEdited || !make || !model || !year || !fuelType;

  const archiveVehicleWarning = {
    title: archiveVehicleWarningTitle,
    input: (
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid item>
          <Button
            variant="contained"
            onClick={() =>
              employee ? archivePersonalVehicle() : archiveCompanyVehicle()
            }
          >
            {adminFixCommuteSchedulesButtonText}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={() => sendSurveys()}>
            {sendSurveyButtonText}
          </Button>
        </Grid>
      </Grid>
    ),
  };

  const singleVehicleFormObj =
    Object.keys(vehicle).length === 0
      ? {
          content: <NoVehicleContent />,
        }
      : {
          content: (
            <SingleVehicleContent
              newVehicle={newVehicle}
              availableFuelTypes={availableFuelTypes}
              editVehicleHandler={editVehicleHandler}
            />
          ),
          footer: (
            <SingleVehicleFooter
              archiveVehicleWarning={archiveVehicleWarning}
              isAboutToDelete={isAboutToDelete}
              isVehicleUsedForCommuting={isVehicleUsedForCommuting}
              vehicle={vehicle}
              disableSaveChanges={disableSaveChanges}
              employee={employee}
              savePersonalVehiclesChanges={savePersonalVehiclesChanges}
              saveCompanyVehicleChanges={saveCompanyVehicleChanges}
              archiveCompanyVehicle={archiveCompanyVehicle}
              archivePersonalVehicle={archivePersonalVehicle}
              setIsAboutToDelete={setIsAboutToDelete}
            />
          ),
        };

  return singleVehicleFormObj;
};
