import { useEffect, useState } from "react";

import {
  Button,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import { kmToMiles } from "@aclymatepackages/converters";
import { Select, TextField } from "@aclymatepackages/atoms";
import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { PlacesAutocomplete } from "@aclymatepackages/modules";
import { numbersRegExpTest } from "@aclymatepackages/reg-exp";
import { isStateInNortheastCorridor } from "@aclymatepackages/other-helpers";

import { buildInitialTransactionInputValueFromSchema } from "../../../helpers/components/inputs";
import {
  fetchOurApi,
  fetchDirectionsMileage,
} from "../../../helpers/utils/apiCalls";
import { findGoogleAddressStringDetails } from "../../../helpers/utils/geography";

const fetchPublicTransitCarbon = async ({
  mileage,
  transitType,
  isNortheastCorridor,
}) =>
  await fetchOurApi({
    path: "/calcs/public-transit/public-transit-carbon-mileage",
    method: "POST",
    data: { mileage, publicTransitType: transitType, isNortheastCorridor },
    callback: ({ tonsCo2e }) => tonsCo2e,
  });

const Mileage = ({
  rideMileageEstimate,
  editRideMileageEstimate,
  setTrainInputName,
  distanceUnit,
  setDistanceUnit,
}) => {
  return (
    <Grid container direction={"column"} rowGap={2}>
      <Grid item>
        <Typography variant="h6">Enter Mileage And Unit</Typography>
      </Grid>
      <Grid item>
        <Grid container columnGap={2} wrap="nowrap">
          <Grid item xs={8}>
            <TextField
              label="Enter Mileage"
              value={rideMileageEstimate}
              setValue={(miles) => editRideMileageEstimate(miles)}
              size="small"
              helperText={
                rideMileageEstimate &&
                !numbersRegExpTest(rideMileageEstimate) &&
                "This must be a number"
              }
              id="rides-estimated-mileage"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <Select
              label="Distance Unit"
              options={[
                { label: "Miles", value: "mi" },
                { label: "Kilometers", value: "km" },
              ]}
              value={distanceUnit}
              editValue={setDistanceUnit}
              size="small"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Button
          size="small"
          onClick={() => setTrainInputName("start-end-points")}
          variant="contained"
          color="primary"
        >
          Enter Start and End Points Instead
        </Button>
      </Grid>
    </Grid>
  );
};

const StartEndPoints = ({
  setTrainInputName,
  rideFrom,
  rideTo,
  setFrom,
  setTo,
}) => {
  return (
    <Grid item container direction="column" rowGap={2}>
      <Grid item>
        <Typography variant="h6">Enter Ride Start and End Points</Typography>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item sm={6}>
          <PlacesAutocomplete
            place={rideFrom}
            editPlace={(place) => {
              setFrom(place);
            }}
            label="Train Ride Starting Point"
            size="small"
            id="train-rides-starting-point-input"
          />
        </Grid>
        <Grid item sm={6}>
          <PlacesAutocomplete
            place={rideTo}
            editPlace={(place) => {
              setTo(place);
            }}
            label="Train Ride Destination"
            size="small"
            id="train-rides-endpoint-input"
          />
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item>
          <Button
            size="small"
            onClick={() => setTrainInputName("mileage")}
            variant="contained"
            color="primary"
          >
            Use Distance Instead
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const TrainInput = ({
  rideFrom,
  setFrom,
  rideTo,
  setTo,
  rideMileageEstimate,
  editRideMileageEstimate,
  distanceUnit,
  setDistanceUnit,
  trainType,
  setTrainType,
}) => {
  const [trainInputName, setTrainInputName] = useState("start-end-points");

  const content = {
    "start-end-points": (
      <StartEndPoints
        setTrainInputName={setTrainInputName}
        rideFrom={rideFrom}
        setFrom={setFrom}
        rideTo={rideTo}
        setTo={setTo}
      />
    ),
    mileage: (
      <Mileage
        rideMileageEstimate={rideMileageEstimate}
        editRideMileageEstimate={editRideMileageEstimate}
        setTrainInputName={setTrainInputName}
        distanceUnit={distanceUnit}
        setDistanceUnit={setDistanceUnit}
      />
    ),
  };

  return (
    <Grid container direction="column" rowGap={2}>
      <Grid item>{content[trainInputName]}</Grid>
      <Grid item>
        <FormLabel>What type of train are you riding?</FormLabel>
        <RadioGroup row>
          {[
            { title: "Commuter Rail", value: "commuterRail" },
            { title: "Metro Rail", value: "metroRail" },
            { title: "Amtrak", value: "amtrak" },
          ].map((option, idx) => (
            <FormControlLabel
              value={option.value}
              checked={trainType === option.value}
              control={<Radio onChange={(e) => setTrainType(option.value)} />}
              label={option.title}
              key={idx}
            />
          ))}
        </RadioGroup>
      </Grid>
    </Grid>
  );
};

const useTrainInput = ({ transaction, onSave, setCalcLoading }) => {
  const inputSchema = [
    { field: "rideFrom" },
    { field: "rideTo" },
    { field: "rideMileageEstimate" },
    { field: "distanceUnit" },
    { field: "trainType" },
  ];

  const [emissionData, setEmissionData] = useState(
    buildInitialTransactionInputValueFromSchema(transaction, inputSchema)
  );

  const {
    tonsCo2e,
    rideFrom,
    rideTo,
    rideMileageEstimate,
    distanceUnit,
    trainType,
  } = emissionData;

  const editEmissionData = (field) => (value) =>
    editObjectData(setEmissionData, field, value);

  useEffect(() => {
    const fetchTrainCarbon = async () => {
      setCalcLoading(true);

      const getMileage = async () => {
        if (rideFrom && rideTo) {
          return await fetchDirectionsMileage(rideFrom, rideTo);
        }
        if (rideMileageEstimate && distanceUnit === "km") {
          return kmToMiles(rideMileageEstimate);
        }
        return rideMileageEstimate;
      };

      const isRideToAndRideFromInNortheastCorridor = async () => {
        if (rideTo && rideFrom) {
          const { place_id: rideToPlaceId } = rideTo;
          const { place_id: rideFromPlaceId } = rideFrom;

          const rideToDetails = await findGoogleAddressStringDetails(
            rideToPlaceId
          );
          const rideFromDetails = await findGoogleAddressStringDetails(
            rideFromPlaceId
          );
          const { state: rideToState } = rideToDetails || {};
          const { state: rideFromState } = rideFromDetails || {};

          return (
            isStateInNortheastCorridor(rideToState) &&
            isStateInNortheastCorridor(rideFromState)
          );
        }

        return false;
      };

      const mileage = await getMileage();

      const isNortheastCorridor =
        await isRideToAndRideFromInNortheastCorridor();

      const tonsCo2e = mileage
        ? await fetchPublicTransitCarbon({
            mileage,
            transitType: trainType,
            isNortheastCorridor,
          })
        : 0;

      editEmissionData("tonsCo2e")(tonsCo2e);
      return setCalcLoading(false);
    };

    if (
      (rideFrom && rideTo && trainType) ||
      (rideMileageEstimate && distanceUnit && trainType)
    ) {
      fetchTrainCarbon();
    }
  }, [
    rideFrom,
    rideTo,
    trainType,
    rideMileageEstimate,
    distanceUnit,
    setCalcLoading,
  ]);

  const descriptionObj =
    rideTo && rideFrom
      ? { description: `${rideFrom.description} -> ${rideTo.description}` }
      : { mileageEstimate: rideMileageEstimate };

  const onTransactionSave = () =>
    onSave({
      ...descriptionObj,
      ...emissionData,
      tonsCo2e,
    });

  const saveEnabled =
    (rideTo && rideFrom && trainType) ||
    (!!rideMileageEstimate &&
      numbersRegExpTest(rideMileageEstimate) &&
      trainType &&
      distanceUnit) ||
    tonsCo2e;

  return {
    inputBlock: (
      <TrainInput
        rideFrom={rideFrom}
        setFrom={editEmissionData("rideFrom")}
        rideTo={rideTo}
        setTo={editEmissionData("rideTo")}
        rideMileageEstimate={rideMileageEstimate}
        editRideMileageEstimate={editEmissionData("rideMileageEstimate")}
        distanceUnit={distanceUnit}
        setDistanceUnit={editEmissionData("distanceUnit")}
        trainType={trainType}
        setTrainType={editEmissionData("trainType")}
      />
    ),
    saveEnabled,
    onTransactionSave,
    tonsCo2e,
  };
};

export default useTrainInput;
