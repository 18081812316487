import { useState, useContext } from "react";
import { ref, uploadBytesResumable } from "firebase/storage";

import useCsvUploader from "./csvUploader";

import { PlatformLayoutContext } from "../../helpers/contexts/platformLayout";
import { useSharedFormLoading } from "../../helpers/components/inputs";
import { storage } from "../../helpers/firebase";
import { getAccountCollectionAndId } from "../../helpers/otherHelpers";

const fieldOptions = [
  { label: "City", value: "city" },
  { label: "State", value: "state" },
  { label: "Zip Code", value: "zipCode" },
  { label: "Country", value: "country" },
];

const requiredFields = ["city", "state", "country", "zipCode"];

const knownFields = [
  { header: "city", field: "city" },
  { header: "state", field: "state" },
  { header: "country", field: "country" },
  { header: "zip code", field: "zipCode" },
];

const fieldValidator = (headers) => {
  const fieldsBoolean = requiredFields.reduce(
    (booleanAcc, requiredField) =>
      booleanAcc && headers.find(({ field }) => requiredField === field),
    true
  );

  return {
    success: fieldsBoolean,
    message:
      !fieldsBoolean &&
      `The fields City, State, Zip Code and Country are required for this CSV.`,
  };
};

const useEventAttendeesUploader = ({ setFormOpen, createNewEvent }) => {
  const { setFormLoading } = useSharedFormLoading();
  const { activateSnackbar } = useContext(PlatformLayoutContext);

  const [fileInfo, setFileInfo] = useState({});
  const [matchedHeaders, setMatchedHeaders] = useState([]);

  const uploadEventAttendeesCsvFile = async ({
    originalFileObj,
    matchedHeaders,
  }) => {
    setFormLoading(true);

    const { id } = getAccountCollectionAndId();

    const matchedHeadersMetadataObj = matchedHeaders.reduce(
      (currentObj, { field, name }) => ({
        ...currentObj,
        [field]: name,
      }),
      {}
    );

    const eventId = await createNewEvent();

    const { name } = fileInfo;
    const storageRef = ref(storage, `${id}/${name}`);
    const uploadTask = uploadBytesResumable(storageRef, originalFileObj, {
      customMetadata: {
        companyId: id,
        eventId,
        ...matchedHeadersMetadataObj,
        type: "event-attendees",
      },
    });

    return uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        return console.log("progress", progress);
      },
      (error) => {
        setFormLoading(false);
        setFormOpen(false);

        return activateSnackbar({
          message: `An error has occurred uploading your CSV (${error.serverResponse})`,
          alert: "error",
        });
      },
      () => {
        setFormLoading(false);
        setFormOpen(false);

        return activateSnackbar({
          message: "Your CSV has been successfully uploaded.",
          alert: "success",
        });
      }
    );
  };

  const csvUploaderSteps = useCsvUploader({
    knownFields,
    fieldOptions,
    open: true,
    setOpen: setFormOpen,
    fileInfo,
    setFileInfo,
    matchedHeaders,
    setMatchedHeaders,
    processData: uploadEventAttendeesCsvFile,
    fieldValidator,
    docType: "eventAttendees",
    firstStepText:
      "Upload a CSV of your event attendees and we'll calculate the total emissions of travel by the attendees.",
    requiredFields,
  });

  return csvUploaderSteps;
};
export default useEventAttendeesUploader;
