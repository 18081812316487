import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useBetween } from "use-between";

import { numbersRegExpTest } from "@aclymatepackages/reg-exp";
import { WORK_DAY_HEIGHT_PX } from "@aclymatepackages/constants";

import { fetchOurApi, useFetchUtilitiesCarbon } from "../utils/apiCalls";
import { useLayoutHelpers, getAccountCollectionAndId } from "../otherHelpers";
import {
  convertElectricCarbonIntensityToTonsPerMwh,
  electricRenewablesAndIntensitySaveEnabled,
} from "./offices";

export const setTransactionInitialValue = (transaction, field) => {
  const { status } = transaction;
  const existingValue = transaction[field];
  if (status !== "confirmed" || !existingValue) {
    return "";
  }

  return existingValue;
};

export const buildInitialTransactionInputValueFromSchema = (
  transaction,
  schema
) => {
  const { status, tonsCo2e } = transaction;
  if (status !== "confirmed") {
    const dataObj = Object.fromEntries(
      schema
        .filter(({ defaultValue }) => defaultValue)
        .map(({ field, defaultValue }) => [field, defaultValue])
    );

    return { ...dataObj, tonsCo2e };
  }

  const dataObj = Object.fromEntries(
    schema.map(({ field, defaultValue }) => [
      field,
      transaction[field] || defaultValue,
    ])
  );

  return { ...dataObj, tonsCo2e };
};

export const isEmissionsInputSchemaEnabled = (inputData, schema) =>
  schema.reduce((acc, { isRequired = true, validator, field }) => {
    if (!isRequired) {
      return acc && true;
    }

    const fieldValue = inputData[field];
    if (validator) {
      return acc && validator(fieldValue);
    }

    return acc && !!fieldValue;
  }, true);

export const useElectricityInputProps = (transaction) => {
  const fetchUtilitiesCarbon = useFetchUtilitiesCarbon();
  const {
    electricCarbonIntensityTonsPerMwh,
    electricRenewablesReductionAmount,
    electricRenewablesAndIntensityTransactionInfo,
    electricRenewablesAndIntensityInputFormProps,
  } = useElectricRenewablesAndIntensityInputs(transaction);

  const {
    isElectricRenewablesPercentageGiven,
    isElectricCarbonIntensityGiven,
  } = electricRenewablesAndIntensityInputFormProps;

  const [electricKwh, setElectricKwh] = useState(
    setTransactionInitialValue(transaction, "electricKwh")
  );
  const [electricCarbonTons, setElectricCarbonTons] = useState(
    setTransactionInitialValue(transaction, "tonsCo2e")
  );
  const [
    electricRenewablesReplacementTons,
    setElectricRenewablesReplacementTons,
  ] = useState(
    setTransactionInitialValue(transaction, "electricRenewablesReplacementTons")
  );

  useEffect(() => {
    const fetchElectricData = () => {
      fetchUtilitiesCarbon(
        electricKwh,
        "kwh",
        "electricity",
        electricCarbonIntensityTonsPerMwh
      ).then((electricCarbon) => {
        const electricRenewablesReduction =
          electricRenewablesReductionAmount(electricCarbon);
        setElectricRenewablesReplacementTons(electricRenewablesReduction);
        setElectricCarbonTons(electricCarbon - electricRenewablesReduction);
      });
    };

    fetchElectricData();
  }, [
    electricKwh,
    fetchUtilitiesCarbon,
    electricCarbonIntensityTonsPerMwh,
    electricRenewablesReductionAmount,
  ]);

  const saveEnabled =
    !!electricKwh &&
    numbersRegExpTest(electricKwh) &&
    electricKwh < 3000000 &&
    isElectricRenewablesPercentageGiven !== null &&
    isElectricCarbonIntensityGiven !== null &&
    electricRenewablesAndIntensitySaveEnabled(
      electricRenewablesAndIntensityInputFormProps
    );

  const saveObject = {
    electricKwh,
    electricRenewablesReplacementTons,
    ...electricRenewablesAndIntensityTransactionInfo,
  };

  return {
    electricRenewablesAndIntensityTransactionInfo,
    isElectricRenewablesPercentageGiven,
    isElectricCarbonIntensityGiven,
    electricKwh,
    setElectricKwh,
    electricCarbonTons,
    electricRenewablesReplacementTons,
    electricRenewablesAndIntensityInputFormProps,
    saveEnabled,
    saveObject,
  };
};

export const useGasInputProps = (transaction) => {
  const { fuelType: existingFuelType } = transaction;

  const fetchUtilitiesCarbon = useFetchUtilitiesCarbon();

  const [gasCarbonTons, setGasCarbonTons] = useState(
    setTransactionInitialValue(transaction, "tonsCo2e")
  );
  const [gasUnitValue, setGasUnitValue] = useState(
    setTransactionInitialValue(transaction, "gasUnitValue")
  );

  const [fuelType, setFuelType] = useState(existingFuelType || "naturalGas");
  const [gasUnit, setGasUnit] = useState("therms");

  useEffect(() => {
    const fetchGasData = async () => {
      if (gasUnitValue && gasUnit && fuelType) {
        const utilitiesCarbonTons = await fetchUtilitiesCarbon(
          gasUnitValue,
          gasUnit,
          fuelType
        );
        return setGasCarbonTons(utilitiesCarbonTons);
      }
    };

    fetchGasData();
  }, [gasUnitValue, fuelType, fetchUtilitiesCarbon, gasUnit]);

  const saveEnabled =
    !!gasUnitValue &&
    numbersRegExpTest(gasUnitValue) &&
    gasUnitValue < 3000000 &&
    fuelType;

  const gasUnitSaveObj = fuelType === "naturalGas" ? { gasUnit } : {};
  const saveObject = {
    fuelType,
    gasUnitValue,
    ...gasUnitSaveObj,
  };

  return {
    gasCarbonTons,
    gasUnit,
    setGasUnit,
    gasUnitValue,
    setGasUnitValue,
    fuelType,
    setFuelType,
    saveEnabled,
    saveObject,
  };
};

export const useElectricRenewablesAndIntensityInputs = (transaction) => {
  const {
    electricRenewablesPercentage: storedElectricRenewablePercentage,
    electricCarbonIntensity: storedElectricCarbonIntensity,
  } = transaction;

  const [electricRenewablesPercentage, setElectricRenewablesPercentage] =
    useState(
      setTransactionInitialValue(transaction, "electricRenewablesPercentage")
    );
  const [electricCarbonIntensity, setElectricCarbonIntensity] = useState(
    setTransactionInitialValue(transaction, "electricCarbonIntensity")
  );
  const [electricCarbonIntensityUnits, setElectricCarbonIntensityUnits] =
    useState(
      setTransactionInitialValue(transaction, "electricCarbonIntensityUnits") ||
        "tons kwh"
    );
  const [
    isElectricRenewablesPercentageGiven,
    setIsElectricRenewablesPercentageGiven,
  ] = useState(!!storedElectricRenewablePercentage);
  const [isElectricCarbonIntensityGiven, setIsElectricCarbonIntensityGiven] =
    useState(!!storedElectricCarbonIntensity);
  const [
    electricCarbonIntensityTonsPerMwh,
    setElectricCarbonIntensityTonsPerMwh,
  ] = useState(0);

  const onIsElectricRenewablesPercentageGiven = (isGiven) => {
    if (!isGiven) {
      setElectricRenewablesPercentage(undefined);
    }

    return setIsElectricRenewablesPercentageGiven(isGiven);
  };

  const onIsElectricCarbonIntensityGiven = (isGiven) => {
    if (!isGiven) {
      setElectricCarbonIntensity(undefined);
    }

    return setIsElectricCarbonIntensityGiven(isGiven);
  };

  const electricRenewablesReductionAmount = (electricCarbon) =>
    electricCarbon * ((electricRenewablesPercentage || 0) / 100);

  const electricRenewablesAndIntensityTransactionInfo = {
    electricRenewablesPercentage,
    electricCarbonIntensity,
    electricCarbonIntensityUnits,
  };

  const electricRenewablesAndIntensityInputFormProps = {
    isElectricRenewablesPercentageGiven,
    onIsElectricRenewablesPercentageGiven,
    electricRenewablesPercentage,
    setElectricRenewablesPercentage,
    isElectricCarbonIntensityGiven,
    onIsElectricCarbonIntensityGiven,
    electricCarbonIntensity,
    setElectricCarbonIntensity,
    electricCarbonIntensityUnits,
    setElectricCarbonIntensityUnits,
  };

  useEffect(
    () =>
      setElectricCarbonIntensityTonsPerMwh(
        convertElectricCarbonIntensityToTonsPerMwh(
          electricCarbonIntensity,
          electricCarbonIntensityUnits
        )
      ),
    [electricCarbonIntensity, electricCarbonIntensityUnits]
  );

  return {
    electricCarbonIntensityTonsPerMwh,
    electricRenewablesReductionAmount,
    electricRenewablesAndIntensityTransactionInfo,
    electricRenewablesAndIntensityInputFormProps,
  };
};

export const doesFieldExist = (headers, field) =>
  headers.findIndex(({ field: headerField }) => headerField === field) !== -1;

export const createErrorObj = (message) => ({ success: false, message });

const useMultiPartFormLoading = () => {
  const [formLoading, setFormLoading] = useState(false);

  return { formLoading, setFormLoading };
};
export const useSharedFormLoading = () => useBetween(useMultiPartFormLoading);

export const useEmployeesApiDbData = (path) => {
  const companyId = useParams()?.companyId;
  const structuredCompanyId = `v2-companies-${companyId}`;
  const accountId = window.sessionStorage.getItem("accountId");
  const { id } = getAccountCollectionAndId();

  const [dataLoading, setDataLoading] = useState(true);
  const [apiData, setApiData] = useState([]);

  useEffect(() => {
    fetchOurApi({
      accountId: accountId || structuredCompanyId,
      path: `/surveys/company/${companyId || id}/${path}`,
      method: "GET",
      callback: (res) => setApiData(res),
    }).then(() => setDataLoading(false));
  }, [accountId, structuredCompanyId, companyId, id, path]);

  return [apiData, dataLoading];
};

export const useWorkDayHeightPx = () => {
  const { isMobile } = useLayoutHelpers();

  if (!isMobile) {
    return WORK_DAY_HEIGHT_PX;
  }

  return WORK_DAY_HEIGHT_PX - 10;
};

export const useSlidableDaysPerWkBlock = (
  blocksArray = [],
  editBlockDaysPerWk,
  identifier = "tempId",
  totalWorkweekDays
) => {
  const { isMobile } = useLayoutHelpers();
  const workDayHeightPx = useWorkDayHeightPx();

  const [selectedBlockIdx, setSelectedBlockIdx] = useState(null);
  const [startingClientY, setStartingClientY] = useState(null);
  const [startingBlockDaysPerWk, setStartingBlockDaysPerWk] = useState(null);

  useEffect(() => {
    const scrollContainer = document.getElementById("scroll-blocker");
    if (selectedBlockIdx !== null && scrollContainer?.style?.touchAction) {
      scrollContainer.style.touchAction = "none";
    } else if (scrollContainer?.style?.touchAction) {
      scrollContainer.style.touchAction = "auto";
    }
  }, [selectedBlockIdx]);

  const findClientY = (e) => {
    const { clientY: mouseClientY, touches = [{}] } = e;
    const [{ clientY: touchClientY }] = touches;

    if (isMobile) {
      return touchClientY;
    }

    return mouseClientY;
  };

  const onBlockSelect = (idx) => (e) => {
    e.stopPropagation();

    const clientY = findClientY(e);

    const { daysPerWk } = blocksArray[idx];
    const { daysPerWk: adjacentDaysPerWk } =
      idx - 1 < 0 ? { daysPerWk: 7 - totalWorkweekDays } : blocksArray[idx - 1];

    setStartingBlockDaysPerWk([daysPerWk, adjacentDaysPerWk]);
    setSelectedBlockIdx(idx);
    return setStartingClientY(clientY);
  };

  const onBlockDeselect = () => {
    setSelectedBlockIdx(null);
    setStartingClientY(null);
    return setStartingBlockDaysPerWk([]);
  };

  const onBlockResize = (e) => {
    e.stopPropagation();
    const clientY = findClientY(e);

    if (selectedBlockIdx === null) {
      return;
    }

    const [selectedDaysPerWk, adjacentDaysPerWk] = startingBlockDaysPerWk;
    const { [identifier]: selectedTempId } = blocksArray[selectedBlockIdx];
    const { [identifier]: adjacentTempId = "Weekend Block" } =
      selectedBlockIdx - 1 < 0 ? {} : blocksArray[selectedBlockIdx - 1];

    const clientYChange = startingClientY - clientY;

    const QUARTER_DAY_HEIGHT = workDayHeightPx / 4;
    const adjustedClientYChange = Math.round(
      clientYChange / QUARTER_DAY_HEIGHT
    );

    const newDaysPerWeek = adjustedClientYChange * 0.25;

    const newSelectedDaysPerWk = selectedDaysPerWk + newDaysPerWeek;
    const newAdjacentDaysPerWk = adjacentDaysPerWk - newDaysPerWeek;

    return editBlockDaysPerWk([
      { tempId: selectedTempId, daysPerWk: newSelectedDaysPerWk },
      { tempId: adjacentTempId, daysPerWk: newAdjacentDaysPerWk },
    ]);
  };

  return { onBlockSelect, onBlockDeselect, onBlockResize };
};

export const generateAutocompleteAnyValueSelectedList = (
  newSelectedValues,
  anyTypeKeywordObj
) => {
  const { name: anyTypeKeyword } = anyTypeKeywordObj;
  const newSelectedValuesNames = newSelectedValues.map(({ name }) => name);

  if (
    newSelectedValuesNames.includes(anyTypeKeyword) &&
    newSelectedValuesNames.indexOf(anyTypeKeyword)
  ) {
    return [anyTypeKeywordObj];
  }

  if (
    newSelectedValuesNames.includes(anyTypeKeyword) &&
    !newSelectedValuesNames.indexOf(anyTypeKeyword) &&
    newSelectedValuesNames?.length > 1
  ) {
    return [newSelectedValues[1]];
  }

  return newSelectedValues;
};
