import { nameToFirstName } from "@aclymatepackages/converters";

import { auth } from "./firebase";
import { fetchOurApi } from "./utils/apiCalls";
import { getAccountCollectionAndId } from "./otherHelpers";

const { analytics } = window;
const isProduction = process.env.REACT_APP_ENVIRONMENT === "production";

export const analyticsPage = (name) => {
  if (isProduction) {
    return analytics.page(name);
  }
};

export const analyticsIdentify = async (user, accountId) => {
  const { uid, email, displayName: name } = user;

  window.dataLayer = window.dataLayer || [];

  if (isProduction) {
    const { id } = getAccountCollectionAndId(accountId);
    const companyId =
      id ||
      (await user.getIdTokenResult().then(({ claims }) => claims.company));

    const firstName = nameToFirstName(name);

    const nameArray = name.split(" ");
    const lastName = nameArray[nameArray.length - 1];

    analytics.identify(uid, {
      name,
      first_name: firstName,
      last_name: lastName,
      email,
      companyId,
      fs_contact: true,
    });
    return fetchOurApi({
      user,
      path: "/mixpanel/identify",
      method: "POST",
      data: {
        userId: uid,
        traits: {
          name,
          first_name: firstName,
          last_name: lastName,
          email,
          companyId,
          fs_contact: true,
        },
      },
    });
  }
};

export const triggerGoogleEvent = (event, properties, otherData = {}) => {
  if (properties) {
    return window.dataLayer.push({
      event,
      ...otherData,
      listItems: [properties],
    });
  }

  return window.dataLayer.push({ event });
};

export const analyticsTrack = (event, properties = {}) => {
  if (isProduction) {
    const { currentUser: user } = auth || {};
    const { uid } = user || {};

    if (window.Appcues) {
      window.Appcues.track(event);
    }

    return fetchOurApi({
      user,
      path: "/mixpanel/track",
      method: "POST",
      data: { event, properties, userId: uid },
    });
  }
};

export const pageEvent = (page) => {
  if (isProduction) {
    return analyticsTrack(`Page: ${page}`);
  }
};
