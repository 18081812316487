import React, { useState, useMemo } from "react";
import PDLJS from "peopledatalabs";

import {
  Grid,
  Chip,
  InputAdornment,
  CircularProgress,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import SearchIcon from "@mui/icons-material/Search";

import { TextField } from "@aclymatepackages/atoms";
import { ucFirstLetters } from "@aclymatepackages/formatters";

const CompanySelectionChip = ({
  id,
  name,
  isSelected,
  setSelectedCompanyId,
}) => (
  <Grid item>
    <Chip
      label={name}
      style={{ color: isSelected ? "white" : "inherit" }}
      onClick={() =>
        setSelectedCompanyId((currentId) => (currentId === id ? null : id))
      }
      icon={isSelected && <CheckIcon style={{ color: "white" }} />}
      color={isSelected ? "primary" : "default"}
    />
  </Grid>
);

const PdlCompanySelect = ({ onSelectCompany }) => {
  const [inputValue, setInputValue] = useState("");
  const [matchingCompanies, setMatchingCompanies] = useState([]);
  const [inputComplete, setInputComplete] = useState(false);
  const [pdlFetchLoading, setPdlFetchLoading] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);

  const pdlClient = useMemo(
    () =>
      new PDLJS({
        apiKey: process.env.REACT_APP_PEOPLEDATALABS_API_KEY,
      }),
    []
  );

  const fetchMatchingCompanies = async () => {
    setPdlFetchLoading(true);

    const { data: matchingPdlCompanies } = await pdlClient.autocomplete({
      field: "company",
      text: inputValue,
      size: 10,
      pretty: true,
    });

    const capitalizedCompanies = matchingPdlCompanies.map(
      ({ name, ...otherProps }) => ({
        name: ucFirstLetters(name),
        ...otherProps,
      })
    );

    setPdlFetchLoading(false);
    setMatchingCompanies(capitalizedCompanies);
    return setInputComplete(true);
  };

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <TextField
          disabled={pdlFetchLoading}
          label="Enter your company's name"
          value={inputValue}
          setValue={setInputValue}
          onKeyPress={({ key }) => {
            if (key === "Enter") {
              return fetchMatchingCompanies();
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton
                  disabled={pdlFetchLoading}
                  onClick={fetchMatchingCompanies}
                >
                  {pdlFetchLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <SearchIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item container justifyContent="center"></Grid>
      {inputComplete && (
        <>
          <Grid item>
            <Typography variant="h6" align="center">
              Please select your company from these options
            </Typography>
          </Grid>
          <Grid item container spacing={2} justifyContent="center">
            {matchingCompanies.map(({ name, meta }, idx) => (
              <CompanySelectionChip
                key={`company-list-item-${idx}`}
                name={name}
                isSelected={selectedCompanyId === meta.id}
                setSelectedCompanyId={setSelectedCompanyId}
                {...meta}
              />
            ))}
          </Grid>
          <Grid item container spacing={2} justifyContent="center">
            <Grid item>
              {selectedCompanyId ? (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => onSelectCompany(selectedCompanyId)}
                >
                  Choose this company
                </Button>
              ) : (
                <Button
                  color="primary"
                  onClick={() => onSelectCompany("none", inputValue)}
                >
                  I don't see my company
                </Button>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default PdlCompanySelect;
