import { useContext, useState } from "react";
import { ref, uploadBytesResumable } from "firebase/storage";

import { Grid, Typography } from "@mui/material";

import { ToggleButtons } from "@aclymatepackages/atoms";

import useCsvUploader from "./csvUploader";

import {
  createErrorObj,
  doesFieldExist,
  useSharedFormLoading,
} from "../../helpers/components/inputs";
import { storage } from "../../helpers/firebase";
import { getAccountCollectionAndId } from "../../helpers/otherHelpers";
import { PlatformLayoutContext } from "../../helpers/contexts/platformLayout";

const fieldOptions = [
  { label: "Date", value: "date" },
  { label: "Weight", value: "weight" },
  { label: "Starting Point", value: "startPoint" },
  { label: "Ending Point", value: "endPoint" },
  { label: "Distance", value: "distance" },
];

const requiredFields = [
  "Date",
  "Weight",
  "Starting & Ending Point or Distance",
];

const knownFields = [
  { header: "distance", field: "distance" },
  { header: "weight", field: "weight" },
];

const fieldValidator = (headers) => {
  if (!doesFieldExist(headers, "date")) {
    return createErrorObj('Your CSV must include a "Date" field');
  }

  if (!doesFieldExist(headers, "weight")) {
    return createErrorObj('Your CSV must include a "Weight" field');
  }

  if (
    !(
      doesFieldExist(headers, "startPoint") &&
      doesFieldExist(headers, "endPoint")
    ) &&
    !doesFieldExist(headers, "distance")
  ) {
    return createErrorObj(
      'Your CSV must include "Starting Point" and "Ending Point" fields or a "Distance" field'
    );
  }

  return { success: true };
};

const useShippingCsvReview = ({ setOpen, matchedHeaders, originalFileObj }) => {
  const { setFormLoading } = useSharedFormLoading();
  const { activateSnackbar } = useContext(PlatformLayoutContext);

  const [shippingWeightUnits, setShippingWeightUnits] = useState("lbs");
  const [distanceUnits, setDistanceUnits] = useState("miles");

  const doesDistanceColumnExist = doesFieldExist(matchedHeaders, "distance");

  const uploadShippingCsvFile = async () => {
    setFormLoading(true);

    const { id } = getAccountCollectionAndId();

    const matchedHeadersMetadataObj = matchedHeaders.reduce(
      (currentObj, { field, name }) => ({
        ...currentObj,
        [field]: name,
      }),
      {}
    );
    const distanceUnitsObj = doesDistanceColumnExist
      ? {
          distanceUnits,
        }
      : {};

    const { name } = originalFileObj;
    const storageRef = ref(storage, `${id}/${name}`);

    const uploadTask = uploadBytesResumable(storageRef, originalFileObj, {
      customMetadata: {
        companyId: id,
        ...matchedHeadersMetadataObj,
        shippingWeightUnits,
        ...distanceUnitsObj,
        type: "shipping",
      },
    });

    return uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        return console.log("progress", progress);
      },
      (error) => {
        setFormLoading(false);
        setOpen(false);

        return activateSnackbar({
          message: `An error has occurred uploading your CSV (${error.serverResponse})`,
          alert: "error",
        });
      },
      () => {
        setFormLoading(false);
        setOpen(false);

        return activateSnackbar({
          message: "Your CSV has been successfully uploaded.",
          alert: "success",
        });
      }
    );
  };

  return [
    {
      label: "Shipping CSV Review",
      input: (
        <Grid container spacing={2} alignItems="center" direction="column">
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            wrap="nowrap"
            spacing={2}
          >
            <Grid item>
              <Typography variant="body2">
                What are the units for shipping weight?
              </Typography>
            </Grid>
            <Grid item>
              <ToggleButtons
                size="small"
                value={shippingWeightUnits}
                onChange={setShippingWeightUnits}
                buttons={[
                  {
                    value: "lbs",
                    name: "Pounds",
                  },
                  { value: "kgs", name: "Kilograms" },
                  {
                    value: "metricTons",
                    name: "Metric Tons",
                  },
                ]}
              />
            </Grid>
          </Grid>
          {doesDistanceColumnExist && (
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              wrap="nowrap"
              spacing={2}
            >
              <Grid item>
                <Typography variant="body2">
                  What are the units for distance?
                </Typography>
              </Grid>
              <Grid item>
                <ToggleButtons
                  size="small"
                  value={distanceUnits}
                  onChange={setDistanceUnits}
                  buttons={[
                    {
                      value: "miles",
                      name: "Miles",
                    },
                    { value: "km", name: "Kilometers" },
                  ]}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      ),
      buttonText: "Test Submit",
      onAdvanceForm: uploadShippingCsvFile,
    },
  ];
};

const useShippingCsvUploader = ({ open, setOpen }) => {
  const [fileInfo, setFileInfo] = useState({});
  const [matchedHeaders, setMatchedHeaders] = useState([]);
  const [originalFileObj, setOriginalFileObj] = useState({});

  const shippingReviewStep = useShippingCsvReview({
    setOpen,
    matchedHeaders,
    originalFileObj,
  });

  const mileageCsvUploaderSteps = useCsvUploader({
    knownFields,
    fieldOptions,
    open,
    setOpen,
    fileInfo,
    setFileInfo,
    matchedHeaders,
    setMatchedHeaders,
    processData: (res) => setOriginalFileObj(res.originalFileObj),
    fieldValidator,
    docType: "shipping",
    firstStepText:
      "We'll automatically calculate the carbon emissions from your shipping transactions.",
    requiredFields,
  });

  const completeShippingForm = [
    ...mileageCsvUploaderSteps,
    ...shippingReviewStep,
  ];

  return [completeShippingForm, 100];
};

export default useShippingCsvUploader;
