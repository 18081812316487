import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";

import { LoadingButton, TextField } from "@aclymatepackages/atoms";
import { emailRegExpTest } from "@aclymatepackages/reg-exp";

import AccountAccessLayout from "../layouts/AccountAccessLayout";

import { fetchOurApi } from "../../helpers/utils/apiCalls";

const ForgotPassword = () => {
  const [userEmail, setUserEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [passwordResetEmailError, setPasswordResetEmailError] = useState("");
  const [isEmailSentLoading, setIsEmailSentLoading] = useState(false);

  const onButtonClick = async () => {
    setIsEmailSentLoading(true);

    await fetchOurApi({
      path: "/onboarding/password-reset-email",
      method: "POST",
      data: { email: userEmail },
      callback: () => setEmailSent(true),
      setError: (e) => setPasswordResetEmailError(e),
    });

    return setIsEmailSentLoading(false);
  };

  return (
    <AccountAccessLayout
      title="Forgot your password"
      subtitle="No problem! We'd rather you forget your password than forget to fight climate change."
    >
      {emailSent ? (
        <Grid item>
          <Typography variant="h6">Password reset email sent</Typography>
          <Typography variant="subtitle1">
            If we found your email in our database, you'll have received an
            email with instructions on how to reset your password.
          </Typography>
        </Grid>
      ) : (
        <>
          <Grid item>
            <TextField
              label="Enter your email address"
              value={userEmail}
              setValue={setUserEmail}
              helperText="If we find an email associated with your account then we'll send you an email to a password reset link."
            />
          </Grid>
          <Grid item container justifyContent="center">
            <Grid item>
              <LoadingButton
                variant="contained"
                color="primary"
                disabled={!emailRegExpTest(userEmail)}
                onClick={onButtonClick}
                isLoading={isEmailSentLoading}
                label="Send Email"
              />
            </Grid>
          </Grid>
          {passwordResetEmailError && (
            <Grid item>
              <Typography variant="body1" color="error" align="center">
                {passwordResetEmailError}
              </Typography>
            </Grid>
          )}
        </>
      )}
    </AccountAccessLayout>
  );
};
export default ForgotPassword;
