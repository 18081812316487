import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useDrag } from "react-dnd";

import {
  Box,
  Container,
  Grid,
  Button,
  Typography,
  IconButton,
  Tooltip,
  Avatar,
  Chip,
  LinearProgress,
  StyledEngineProvider,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import DoneIcon from "@mui/icons-material/Done";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortCircle } from "@fortawesome/pro-solid-svg-icons";

import { DefaultPaper } from "@aclymatepackages/atoms";
import { mergeDarkTheme, mainTheme } from "@aclymatepackages/themes";
import { formatDate } from "@aclymatepackages/formatters";
import { editRowData } from "@aclymatepackages/array-immutability-helpers";
import { numbersRegExpTest } from "@aclymatepackages/reg-exp";
import { YesNoQuestion } from "@aclymatepackages/modules";

import WorkspaceGraphicCard from "./WorkspaceGraphicCard";
import CommuteSchedulesInterface from "./CommuteSchedulesInterface";

import FormDivider from "../forms/FormDivider";
import MultiPartFormReveal from "../forms/MultiPartFormReveal";
import PlacesAutocomplete from "../autocomplete/PlacesAutocomplete";
import DbAutocomplete from "../autocomplete/DbAutocomplete";
import ElectricRenewablesYesNoQuestion from "../offices/ElectricRenewablesYesNoQuestion";
import ElectricRenewablesPercentageInput from "../offices/ElectricRenewablesPercentageInput";

import { useSharedNewEmployee } from "../../hooks/employees";
import DatePicker from "../../atoms/mui/DatePicker";
import RemovableRow from "../../modules/RemovableRow";

import {
  useEmployeesApiDbData,
  useSlidableDaysPerWkBlock,
  useWorkDayHeightPx,
} from "../../../helpers/components/inputs";
import {
  useVehicleTypeProperties,
  alternateVehicles,
} from "../../../helpers/components/vehicles";
import {
  useOfficeTypes,
  setOfficeAddress,
  electricRenewablesPercentageError,
} from "../../../helpers/components/offices";
import { useAccountData } from "../../../helpers/firebase";
import {
  isObjectEmpty,
  generateTempId,
  useLayoutHelpers,
} from "../../../helpers/otherHelpers";

const itemTypes = { VEHICLE: "vehicle" };

const OfficeTypeQuestionRow = ({
  endDate,
  worksInThisOffice,
  setWorksInThisOffice,
  question,
  onYesSelect,
  onNoSelect,
  alternateQuestion,
  survey,
}) => {
  const onButtonSelect = (value) => {
    if (!value && onNoSelect) {
      onNoSelect();
    }
    if (value && onYesSelect) {
      onYesSelect();
    }
    return setWorksInThisOffice(value);
  };

  const buildQuestionText = () => {
    if (survey) {
      return `During an average week, ${
        endDate ? `did` : `do`
      } you work from ${question}?`;
    }

    return `During an average week, ${
      endDate ? "did" : "does"
    } this employee work from ${question}?`;
  };

  return (
    <YesNoQuestion
      question={alternateQuestion || buildQuestionText()}
      value={worksInThisOffice}
      setValue={onButtonSelect}
    />
  );
};

const CompanyOfficesSelect = ({
  companyOfficeEndpoints,
  addWorkspace,
  removeWorkspace,
  removeAllOfficesOfType,
  survey,
}) => {
  const [companyOffices, officesLoading] = useEmployeesApiDbData("offices");

  const [availableOffices, setAvailableOffices] = useState([]);

  useEffect(() => {
    if (!officesLoading) {
      const notClosedOffices = companyOffices.filter(
        ({ isClosed }) => !isClosed
      );

      const availableOffices = notClosedOffices.filter(
        ({ id }) =>
          !companyOfficeEndpoints.find((endpoint) => endpoint.id === id)
      );
      setAvailableOffices(availableOffices);
    }
  }, [companyOfficeEndpoints, companyOffices, officesLoading]);

  const onOfficeSelect = (newValueArray) => {
    const [newOffice] = newValueArray.filter(
      ({ name }) =>
        !companyOfficeEndpoints.find((endpoint) => endpoint.name === name)
    );

    if (!newOffice) {
      return removeAllOfficesOfType("companyOffice");
    }

    return addWorkspace(newOffice);
  };

  const disableSelect = companyOffices.length === companyOfficeEndpoints.length;

  //TODO: these db autocompletes should be just regular autocompletes from @aclymatepackages/atoms since they aren't pulling from the db
  return (
    <DbAutocomplete
      multiple
      label="Select company offices"
      helperText={`Please select all of the offices ${
        survey ? `you work` : `this employee works`
      } in during an average week`}
      disabled={disableSelect}
      variant="standard"
      availableOptions={availableOffices}
      value={companyOfficeEndpoints}
      setValue={onOfficeSelect}
      onDelete={({ tempId }) => removeWorkspace(tempId)}
    />
  );
};

const OtherOfficesInput = ({
  officeTypeEndpoints,
  addWorkspace,
  editWorkspace,
  removeWorkspace,
  label,
  survey,
}) => {
  const moreButtonEnabled = officeTypeEndpoints.reduce(
    (acc, { address }) => acc && !!address,
    true
  );

  return (
    <Grid container direction="column" spacing={2}>
      {officeTypeEndpoints.map(({ address, tempId }) => (
        <RemovableRow
          key={`other-office-${tempId}`}
          rowCount={officeTypeEndpoints.length}
          removeRow={() => removeWorkspace(tempId)}
        >
          <Grid item sm={10} xs={12}>
            <PlacesAutocomplete
              place={address}
              editPlace={setOfficeAddress(editWorkspace(tempId), true)}
              label={`What is the ${label} Name or Address`}
              size="small"
              variant="standard"
            />
          </Grid>
        </RemovableRow>
      ))}
      <Grid item container justifyContent="center">
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => addWorkspace()}
            disabled={!moreButtonEnabled}
          >{`Add another ${label} where ${
            survey ? `you work` : `this employee works`
          }`}</Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useDefineWorkspacesSteps = ({
  home = {},
  commuteEndpoints = [],
  editCommuteEndpoints,
  endDate,
  updatePeriodEndpoints,
  previousHome = {},
  editTimePeriod,
  editTimePeriodHomeProps,
  survey,
}) => {
  const {
    address: homeAddress,
    homeArea,
    homeOfficeArea,
    isElectricRenewablesPercentageGiven,
    electricRenewablesPercentage,
  } = home;

  const {
    address: previousHomeAddress,
    homeArea: previousHomeArea,
    homeOfficeArea: previousHomeOfficeArea,
  } = previousHome;

  const [worksInWorkspaceTypes, setWorksInWorkspaceTypes] = useState([]);
  const [
    isNotValidWorkspaceTypeQuestions,
    setIsNotValidWorkspaceTypeQuestions,
  ] = useState(false);

  useEffect(() => {
    const allAnswersAreNo = worksInWorkspaceTypes.every(
      (worksInWorkspaceType) => !worksInWorkspaceType.value
    );

    const setIsNotValidWorkspaceTypeQuestionsHandler = () => {
      if (worksInWorkspaceTypes.length === 4 && allAnswersAreNo) {
        return setIsNotValidWorkspaceTypeQuestions(true);
      }

      return setIsNotValidWorkspaceTypeQuestions(false);
    };

    setIsNotValidWorkspaceTypeQuestionsHandler();
  }, [worksInWorkspaceTypes]);

  const findSetWorkspaceType = (type, array = worksInWorkspaceTypes) =>
    array.find((workspace) => workspace.type === type);

  const editWorksInWorkspaceType = (type) => (value) =>
    setWorksInWorkspaceTypes((currentTypesArray) => {
      const typeAlreadyExists = !!findSetWorkspaceType(type, currentTypesArray);

      if (!typeAlreadyExists) {
        return [...currentTypesArray, { type, value }];
      }

      const filteredTypesArray = currentTypesArray.filter(
        (workspace) => workspace.type !== type
      );
      return [...filteredTypesArray, { type, value }];
    });

  const addWorkspace = (type) => (newOfficeObj) =>
    editCommuteEndpoints([
      ...commuteEndpoints,
      {
        tempId: generateTempId(),
        type,
        ...newOfficeObj,
      },
    ]);

  const editWorkspace = (tempId) => (field) => (value) => {
    const endpointsUpdater = (commuteEndpoints) =>
      commuteEndpoints.map((endpoint) => {
        if (endpoint.tempId !== tempId) {
          return endpoint;
        }
        return { ...endpoint, [field]: value };
      });

    return updatePeriodEndpoints(endpointsUpdater);
  };

  const removeWorkspace = (tempId) =>
    tempId &&
    editCommuteEndpoints(
      commuteEndpoints.filter((workspace) => workspace.tempId !== tempId)
    );

  const filterEndpointsByCategory = (filterCategory) =>
    commuteEndpoints.filter(({ type }) => type === filterCategory);

  const removeAllOfficesOfType = (type) =>
    editCommuteEndpoints(
      commuteEndpoints.filter((endpoint) => endpoint.type !== type)
    );

  const buildHomeOfficeInputRows = () => {
    const [homeOffice] = filterEndpointsByCategory("homeOffice");
    const { tempId } = homeOffice || {};

    const homeAreaCompBool =
      !!homeOfficeArea &&
      !!homeArea &&
      Number(homeOfficeArea) >= Number(homeArea);

    const addNewHomeAddress = () => (address) =>
      editTimePeriodHomeProps("address")(address);

    const electricRenewablesPercentageInput =
      isElectricRenewablesPercentageGiven
        ? [
            {
              input: (
                <ElectricRenewablesPercentageInput
                  electricRenewablesPercentage={electricRenewablesPercentage}
                  editElectricRenewablesPercentage={(value) =>
                    editTimePeriodHomeProps("electricRenewablesPercentage")(
                      value
                    )
                  }
                  variant="standard"
                  label="What % of electricity is from renewables?"
                />
              ),
              value:
                electricRenewablesPercentage &&
                !electricRenewablesPercentageError(
                  electricRenewablesPercentage
                ),
            },
          ]
        : [];

    const areaInputRows = [
      {
        label: `How big is ${survey ? `your` : `this`} home?`,
        helperText: "Please enter a square footage",
        value: homeArea,
        editData: editTimePeriodHomeProps("homeArea"),
        error: homeAreaCompBool || (homeArea && !numbersRegExpTest(homeArea)),
      },
      {
        label: `How big is ${survey ? `your` : `this`} home office?`,
        helperText: "Please enter a square footage",
        value: homeOfficeArea,
        editData: editTimePeriodHomeProps("homeOfficeArea"),
        error:
          homeAreaCompBool ||
          (homeOfficeArea && !numbersRegExpTest(homeOfficeArea)),
      },
      {
        input: (
          <ElectricRenewablesYesNoQuestion
            questionText={`Is ${
              survey ? "your" : "this"
            } home powered by renewable energy sources?`}
            isElectricRenewablesPercentageGiven={
              isElectricRenewablesPercentageGiven
            }
            editIsElectricRenewablesPercentageGiven={(value) =>
              editTimePeriodHomeProps("isElectricRenewablesPercentageGiven")(
                value
              )
            }
          />
        ),
        value: isElectricRenewablesPercentageGiven !== undefined,
      },
      ...electricRenewablesPercentageInput,
    ];

    if (endDate) {
      const didHomeAddressChange = !worksInWorkspaceTypes.find(
        ({ type }) => type === "newHomeOffice"
      )?.value;

      const newHomeOfficeInputRows =
        didHomeAddressChange || !previousHomeArea || !previousHomeOfficeArea
          ? { inputRows: areaInputRows }
          : {};

      const otherHomeAddressInputRows =
        homeAddress !== previousHomeAddress
          ? {
              inputRows: [
                {
                  input: (
                    <PlacesAutocomplete
                      place={homeAddress}
                      editPlace={setOfficeAddress(addNewHomeAddress)}
                      label={`What is the other home's address?`}
                      size="small"
                      variant="standard"
                    />
                  ),
                  value: homeAddress,
                },
              ],
            }
          : {};

      return [
        {
          alternateQuestion: `During this time, did ${
            survey ? `you` : `this employee`
          } still live at ${previousHomeAddress.description}?`,
          type: "newHomeOffice",
          onYesSelect: () =>
            editTimePeriodHomeProps("address")(previousHomeAddress),
          onNoSelect: () => editTimePeriodHomeProps("address")(null),
          ...otherHomeAddressInputRows,
        },
        {
          type: "homeOffice",
          question: `${survey ? `your` : `their`} home office`,
          onYesSelect: () => {
            if (!didHomeAddressChange) {
              editTimePeriod("home")(previousHome);
            }
            return addWorkspace("homeOffice")();
          },
          onNoSelect: () => removeWorkspace(tempId),
          ...newHomeOfficeInputRows,
        },
      ];
    }

    return [
      {
        type: "homeOffice",
        question: `${survey ? `your` : `their`} home office`,
        onYesSelect: () => addWorkspace("homeOffice")(),
        onNoSelect: () => removeWorkspace(tempId),
        inputRows: areaInputRows,
      },
    ];
  };

  const buildOtherOfficeRows = () =>
    [
      {
        type: "coffeeShop",
        question: "any coffee shops",
        label: "Coffee Shop",
      },
      {
        type: "coworking",
        question: "any coworking spaces",
        label: "Coworking Space",
      },
    ].map(({ label, type, question }) => ({
      type,
      question,
      onYesSelect: () => addWorkspace(type)(),
      onNoSelect: () => removeAllOfficesOfType(type),
      inputRows: [
        {
          input: (
            <OtherOfficesInput
              label={label}
              officeTypeEndpoints={filterEndpointsByCategory(type)}
              editWorkspace={editWorkspace}
              removeWorkspace={removeWorkspace}
              addWorkspace={addWorkspace(type)}
              survey={survey}
            />
          ),
          value: filterEndpointsByCategory(type).reduce(
            (acc, { address }) => acc && !!address,
            true
          ),
        },
      ],
    }));

  const workspaceTypeQuestions = [
    {
      type: "companyOffice",
      question: `any ${survey ? `` : `of your`} company offices`,
      onNoSelect: () => removeAllOfficesOfType("companyOffice"),
      inputRows: [
        {
          input: (
            <CompanyOfficesSelect
              companyOfficeEndpoints={filterEndpointsByCategory(
                "companyOffice"
              )}
              addWorkspace={addWorkspace("companyOffice")}
              removeWorkspace={removeWorkspace}
              removeAllOfficesOfType={removeAllOfficesOfType}
              survey={survey}
            />
          ),
          value: !!filterEndpointsByCategory("companyOffice").length,
        },
      ],
    },
    ...buildHomeOfficeInputRows(),
    ...buildOtherOfficeRows(),
  ];

  const buildInputRowsArray = (question) => {
    const { type, inputRows = [] } = question;
    const isWorkspaceTypeSet = findSetWorkspaceType(type);
    const { value: worksInThisOffice } = isWorkspaceTypeSet || {};

    if ((type === "newHomeOffice" && !worksInThisOffice) || worksInThisOffice) {
      return inputRows;
    }

    return [];
  };

  const workspaceQuestions = workspaceTypeQuestions.flatMap((question) => {
    const { type } = question;
    const isWorkspaceTypeSet = findSetWorkspaceType(type);

    const { value: worksInThisOffice } = isWorkspaceTypeSet || {};

    const questionRowProps = {
      ...question,
      worksInThisOffice,
      setWorksInThisOffice: editWorksInWorkspaceType(type),
      survey,
    };

    const inputRowsArray = buildInputRowsArray(question);
    return [
      {
        input: (
          <OfficeTypeQuestionRow endDate={endDate} {...questionRowProps} />
        ),
        value: isWorkspaceTypeSet,
      },
      ...inputRowsArray,
    ];
  });

  return { workspaceQuestions, isNotValidWorkspaceTypeQuestions };
};

const WorkspaceScheduleBlock = ({
  type,
  daysPerWk,
  color,
  children,
  ...otherProps
}) => {
  const { color: officeColor } = useOfficeTypes({ type });
  const workDayHeightPx = useWorkDayHeightPx();

  return (
    <Box
      p={1}
      style={{
        backgroundColor: color || officeColor,
        color: "white",
        borderRadius: "5px",
        border: `thin solid white`,
        boxSizing: "border-box",
        width: "100%",
        height: `${daysPerWk * workDayHeightPx}px`,
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="relative"
      {...otherProps}
    >
      {children}
    </Box>
  );
};

const editWorkWeekDaysPerWk = (setWorkSchedule) => (editingOfficesArray) => {
  const editThisWorkWeekBlock = (idx, daysPerWk) =>
    editRowData(idx, setWorkSchedule)("daysPerWk", daysPerWk);
  const [
    { daysPerWk: newSelectedDaysPerWk },
    { daysPerWk: newAdjacentDaysPerWk },
  ] = editingOfficesArray;

  if (newSelectedDaysPerWk <= 0 || newAdjacentDaysPerWk <= 0) {
    return;
  }

  editThisWorkWeekBlock(0, newAdjacentDaysPerWk);
  return editThisWorkWeekBlock(1, newSelectedDaysPerWk);
};

const WorkweekScheduleBlock = ({ workSchedule, setWorkSchedule }) => {
  const { isMobile } = useLayoutHelpers();

  const { onBlockSelect, onBlockDeselect, onBlockResize } =
    useSlidableDaysPerWkBlock(
      workSchedule,
      editWorkWeekDaysPerWk(setWorkSchedule)
    );

  const boxDraggingProps = isMobile
    ? { onTouchMove: onBlockResize, onTouchEnd: onBlockDeselect }
    : {
        onMouseMove: onBlockResize,
        onMouseUp: onBlockDeselect,
        onMouseLeave: onBlockDeselect,
      };

  const arrowDraggingProps = isMobile
    ? { onTouchStart: onBlockSelect(1), onTouchEnd: onBlockDeselect }
    : { onMouseDown: onBlockSelect(1), onMouseUp: onBlockDeselect };

  return (
    <Box display="flex" flexDirection="column" {...boxDraggingProps}>
      {workSchedule.map(({ name, daysPerWk, color }, idx) => (
        <WorkspaceScheduleBlock
          key={`work-week-schedule-block-${idx}`}
          color={color}
          daysPerWk={daysPerWk}
        >
          <Typography variant="h6">{`${name}- ${daysPerWk} days/week`}</Typography>
          {!!idx && (
            <div
              style={{
                position: "absolute",
                top: "-36px",
                left: "calc(50% - 36px)",
                zIndex: 1500,
              }}
            >
              <IconButton {...arrowDraggingProps} size="large">
                <FontAwesomeIcon
                  icon={faSortCircle}
                  style={{ color: "white" }}
                  size="2x"
                />
              </IconButton>
            </div>
          )}
        </WorkspaceScheduleBlock>
      ))}
    </Box>
  );
};

const WorkspaceDaysPerWkBlock = ({
  type,
  daysPerWk,
  name,
  isDraggable,
  onOfficeSelect,
  onOfficeDeselect,
}) => {
  const { isMobile } = useLayoutHelpers();

  const arrowDraggingProps = isMobile
    ? { onTouchStart: onOfficeSelect, onTouchEnd: onOfficeDeselect }
    : { onMouseDown: onOfficeSelect, onMouseUp: onOfficeDeselect };

  return (
    <WorkspaceScheduleBlock type={type} daysPerWk={daysPerWk}>
      {isDraggable && (
        <div
          style={{
            position: "absolute",
            top: "-24px",
            left: "calc(50% - 24px)",
            zIndex: 1500,
          }}
        >
          <IconButton {...arrowDraggingProps} size="large">
            <FontAwesomeIcon
              icon={faSortCircle}
              style={{ color: "white" }}
              size="1x"
            />
          </IconButton>
        </div>
      )}
      <Typography variant="subtitle2" color="inherit" align="center">{`${
        type === "homeOffice" ? "Home Office" : name
      }- ${daysPerWk} days/wk.`}</Typography>
    </WorkspaceScheduleBlock>
  );
};

const WorkspaceScheduleColumn = ({
  weekendDays,
  children,
  editMode,
  ...otherProps
}) => {
  const { palette } = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
      style={{ height: "100%" }}
      {...otherProps}
    >
      <WorkspaceScheduleBlock
        color={palette["business-travel"].main}
        daysPerWk={weekendDays}
      >
        <Typography variant="h6" align="center">
          Weekend
        </Typography>
      </WorkspaceScheduleBlock>
      {children}
    </Box>
  );
};

const editOfficeDaysPerWeek =
  (commuteEndpoints, editCommuteEndpoints) => (editingOfficesArray) => {
    const [
      { daysPerWk: newSelectedDaysPerWk },
      { daysPerWk: newAdjacentDaysPerWk },
    ] = editingOfficesArray;

    if (newSelectedDaysPerWk <= 0 || newAdjacentDaysPerWk <= 0) {
      return;
    }

    return editCommuteEndpoints(
      commuteEndpoints.map((endpoint) => {
        const editingOffice = editingOfficesArray.find(
          ({ tempId }) => tempId === endpoint.tempId
        );

        if (!editingOffice) {
          return endpoint;
        }

        const { daysPerWk: newDaysPerWk } = editingOffice;

        const newTotalWorkingDaysInWeek = commuteEndpoints.reduce(
          (sum, { tempId: endpointId, daysPerWk: oldDaysPerWk }) =>
            endpointId === editingOffice.tempId
              ? sum + newDaysPerWk
              : sum + oldDaysPerWk,
          0
        );

        if (
          newTotalWorkingDaysInWeek > 6.75 ||
          newTotalWorkingDaysInWeek <= 0.25
        ) {
          return endpoint;
        }

        const { daysPerWk: oldDaysPerWk, vehicles: oldVehicles } = endpoint;

        if (oldVehicles && oldVehicles.length) {
          const adjustedDaysPerWk = newDaysPerWk - oldDaysPerWk || 0;
          const largestDaysPerWkVehicle = oldVehicles.reduce((max, vehicle) =>
            vehicle.daysPerWk > max.daysPerWk ? vehicle : max
          );
          const newVehiclesCorrectDaysPerWk = oldVehicles.map((vehicle) =>
            vehicle.id === largestDaysPerWkVehicle.id
              ? { ...vehicle, daysPerWk: vehicle.daysPerWk + adjustedDaysPerWk }
              : vehicle
          );
          const filterVehiclesWithLessThanQuarterDay =
            newVehiclesCorrectDaysPerWk.filter(
              ({ daysPerWk }) => daysPerWk >= 0.25
            );

          return {
            ...endpoint,
            ...editingOffice,
            vehicles: filterVehiclesWithLessThanQuarterDay,
          };
        }

        return { ...endpoint, ...editingOffice };
      })
    );
  };

export const DraggableOfficesGraphic = ({
  commuteEndpoints,
  editCommuteEndpoints,
}) => {
  const { isMobile } = useLayoutHelpers();

  const { onBlockSelect, onBlockDeselect, onBlockResize } =
    useSlidableDaysPerWkBlock(
      commuteEndpoints,
      editOfficeDaysPerWeek(commuteEndpoints, editCommuteEndpoints)
    );

  const draggingProps = isMobile
    ? { onTouchMove: onBlockResize, onTouchEnd: onBlockDeselect }
    : {
        onMouseMove: onBlockResize,
        onMouseUp: onBlockDeselect,
        onMouseLeave: onBlockDeselect,
      };

  return (
    <WorkspaceScheduleColumn
      {...draggingProps}
      weekendDays={
        7 -
        commuteEndpoints.reduce(
          (sum, { daysPerWk }) => Number(sum) + Number(daysPerWk),
          0
        )
      }
    >
      {commuteEndpoints.map((office, idx) => (
        <WorkspaceDaysPerWkBlock
          key={`draggable-office-block-${office.tempId}`}
          isDraggable={!!idx}
          {...office}
          onOfficeSelect={onBlockSelect(idx)}
          onOfficeDeselect={onBlockDeselect}
        />
      ))}
    </WorkspaceScheduleColumn>
  );
};

const AvailableCommuteVehicleChip = ({
  type,
  name,
  description,
  tonsCo2ePerMile,
  id,
}) => {
  const isTouchBackend = "ontouchstart" in window;
  const { isMobile } = useLayoutHelpers();

  const { color, icon, tooltip } = useVehicleTypeProperties(type) || {};

  const [{ isDragging, offset }, drag] = useDrag(() => ({
    type: itemTypes.VEHICLE,
    item: { id, type, name: name || description, tonsCo2ePerMile },
    collect: (monitor) => {
      const isDragging = !!monitor.isDragging();

      if (isDragging && isTouchBackend) {
        const offset = monitor.getDifferenceFromInitialOffset() || {
          x: 0,
          y: 0,
        };
        return { isDragging, offset };
      }

      return { isDragging };
    },
  }));
  const { x: offsetX = 0, y: offsetY = 0 } = offset || {};

  const chipProps = {
    size: isMobile ? "small" : "medium",
    avatar: (
      <Tooltip title={tooltip}>
        <Avatar
          style={{
            backgroundColor: color,
            color: "white",
          }}
        >
          <FontAwesomeIcon icon={icon} size="sm" />
        </Avatar>
      </Tooltip>
    ),
    label: name || description,
  };

  return (
    <Grid item>
      {isTouchBackend && isDragging && (
        <div style={{ position: "relative" }}>
          <Chip
            {...chipProps}
            style={{
              position: "absolute",
              top: `${offsetY}px`,
              left: `${offsetX}px`,
            }}
          />
        </div>
      )}
      <Chip
        ref={drag}
        {...chipProps}
        style={{
          cursor: isDragging ? "grabbing" : "grab",
        }}
      />
    </Grid>
  );
};

const DragVehiclesStep = ({ survey }) => {
  const { isMobile } = useLayoutHelpers();
  const { employeeVehicles } = useSharedNewEmployee();
  const [companyVehicles, companyVehiclesLoading] =
    useEmployeesApiDbData("vehicles");

  const vehiclesRows = [
    {
      label: `${survey ? `Your` : `Employee`} Vehicles`,
      annotation: `These are vehicles owned by ${
        survey ? `you` : `this employee`
      } that you defined earlier in this form. You can go back to that step if you need to edit these vehicles.`,
      vehicles: employeeVehicles
        .filter((vehicle) => !isObjectEmpty(vehicle) && !vehicle.archived)
        .map((vehicle) => ({
          ...vehicle,
          type: "personal",
          name: vehicle.description || vehicle.name,
        })),
    },
    {
      label: "Company Vehicles",
      annotation: `These are vehicles that are owned and managed by your company ${
        survey
          ? `that can be used by employees like you.`
          : `that can be edited on your company vehicles page.`
      }`,
      vehicles: companyVehicles,
      isLoading: companyVehiclesLoading,
    },
    {
      label: "Alternate Transportation",
      annotation:
        "These are alternate forms of transportation that don't involve driving a vehicle to work.",
      vehicles: alternateVehicles,
    },
  ];

  const filterVehiclesRows = () => {
    if (!isMobile) {
      return vehiclesRows;
    }

    return vehiclesRows.filter(({ vehicles }) => vehicles.length);
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="body1">
          {`Drag a vehicle from the options below into each office and then drag
          it like you dragged the offices to adjust how many days per week ${
            survey ? `you` : `they`
          }
          commute to that office with that vehicle.`}
        </Typography>
      </Grid>
      <Grid item container spacing={isMobile ? 2 : 3}>
        {filterVehiclesRows().map(
          ({ label, annotation, vehicles, isLoading }, rowIdx) => (
            <Grid
              item
              container
              direction="column"
              spacing={isMobile ? 0 : 1}
              key={`vehicles-chips-row-${rowIdx}`}
            >
              <Grid item container justifyContent="space-between">
                <Grid item>
                  <Typography variant="subtitle1">{label}</Typography>
                </Grid>
                <Grid item>
                  <Tooltip title={annotation}>
                    <HelpOutlineIcon />
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid item container spacing={1}>
                {isLoading ? (
                  <LinearProgress />
                ) : !vehicles.length ? (
                  <Typography align="center" variant="body1">{`There are no ${
                    survey ? "personal vehicles" : label.toLowerCase()
                  } available`}</Typography>
                ) : (
                  vehicles.map((vehicle, idx) => (
                    <AvailableCommuteVehicleChip
                      key={`available-commute-vehicle-chip-${idx}`}
                      {...vehicle}
                    />
                  ))
                )}
              </Grid>
            </Grid>
          )
        )}
      </Grid>
    </Grid>
  );
};

const useTimePeriodTransitionStep = ({
  onSubmit,
  editTimePeriod,
  startDate,
  addNewTimePeriod,
  endDate,
  survey,
  newCommuteScheduleSurvey,
  employeeCommuteSurvey,
}) => {
  const { newEmployee } = useSharedNewEmployee();
  const { startDate: employeeStartDate, commuteSchedules = [{}] } = newEmployee;
  const [{ startDate: currentScheduleStartDate }] = commuteSchedules;
  const [{ startDate: companyStartDate }] = useAccountData();

  const [showNextStep, setShowTimePeriodDate] = useState(
    newCommuteScheduleSurvey ? true : false
  );

  const buildTitle = () =>
    !newCommuteScheduleSurvey
      ? `${
          survey ? `Have you` : `Has this employee`
        } worked this schedule since at least ${formatDate(
          employeeStartDate || companyStartDate
        )}.`
      : "Schedule Start Date";

  return employeeCommuteSurvey
    ? {
        label: "Schedule Start Date",
        input: (
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={mainTheme}>
              <DatePicker
                label="When did you start working with this schedule?"
                date={startDate}
                minDate={
                  currentScheduleStartDate ||
                  employeeStartDate ||
                  companyStartDate
                }
                editDate={editTimePeriod("startDate")}
                darkTheme
              />
              <Grid container justifyContent="center">
                <Grid item style={{ marginTop: "1rem" }}>
                  <Button
                    color="success"
                    endIcon={<CheckIcon />}
                    onClick={() => onSubmit()}
                    variant="contained"
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </ThemeProvider>
          </StyledEngineProvider>
        ),
        noButton: true,
      }
    : {
        label: "Schedule Start Date",
        title: buildTitle(),
        input: (
          <>
            {showNextStep ? (
              <DatePicker
                label={`When did ${
                  survey ? `you` : `this employee`
                } start this schedule?`}
                date={startDate}
                maxDate={endDate || new Date()}
                minDate={
                  currentScheduleStartDate ||
                  employeeStartDate ||
                  companyStartDate
                }
                editDate={editTimePeriod("startDate")}
                inputVariant="standard"
                darkTheme
              />
            ) : (
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={mainTheme}>
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item>
                      <Button
                        color="primary"
                        endIcon={<CheckIcon />}
                        onClick={() => onSubmit()}
                        variant="contained"
                      >
                        Yes
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        color="secondary"
                        endIcon={<CancelIcon />}
                        variant="contained"
                        onClick={() => setShowTimePeriodDate(true)}
                      >
                        No
                      </Button>
                    </Grid>
                  </Grid>
                </ThemeProvider>
              </StyledEngineProvider>
            )}
          </>
        ),
        noButton: !showNextStep,
        onAdvanceForm: newCommuteScheduleSurvey ? onSubmit : addNewTimePeriod,
      };
};

const NewEmployeeWorkspacesGraphic = ({
  daysPerWk,
  graphicStep,
  commuteEndpoints,
  editCommuteEndpoints,
  updatePeriodEndpoints,
  endDate,
  workSchedule,
  setWorkSchedule,
  home,
  editTimePeriodHome,
}) => {
  const { newEmployee, employeeVehicles } = useSharedNewEmployee();

  const graphicSteps = [
    <WorkweekScheduleBlock
      workSchedule={workSchedule}
      setWorkSchedule={setWorkSchedule}
    />,
    <DraggableOfficesGraphic
      commuteEndpoints={commuteEndpoints}
      editCommuteEndpoints={editCommuteEndpoints}
    />,
    <CommuteSchedulesInterface
      commuteEndpoints={commuteEndpoints}
      editCommuteEndpoints={editCommuteEndpoints}
      updatePeriodEndpoints={updatePeriodEndpoints}
      editCommuteScheduleHome={editTimePeriodHome}
      editMode
      commuteHome={home}
      employeePersonalVehicles={employeeVehicles}
    />,
  ];

  return (
    <WorkspaceGraphicCard
      employeeName={newEmployee.name}
      endDate={endDate}
      daysPerWk={daysPerWk}
      graphic={graphicSteps[graphicStep - 1]}
    />
  );
};

const TimePeriodGraphicForm = ({
  daysPerWk,
  startDate,
  endDate,
  commuteEndpoints = [],
  home,
  onSubmit,
  editTimePeriod,
  addNewTimePeriod,
  isActivePeriod,
  updatePeriodEndpoints,
  editTimePeriodHomeProps,
  editTimePeriodHome,
  previousPeriod,
  survey,
  newCommuteScheduleSurvey,
  employeeCommuteSurvey,
}) => {
  const nonHomeEndpoints = commuteEndpoints.filter(
    ({ type }) => type !== "homeOffice"
  );
  const theme = useTheme();

  const [formOpen, setFormOpen] = useState(false);
  const [graphicStep, setGraphicStep] = useState(0);
  const [workSchedule, setWorkSchedule] = useState([
    {
      name: "Weekend",
      tempId: generateTempId(),
      daysPerWk: daysPerWk ? 7 - daysPerWk : 2,
      color: theme.palette["business-travel"].main,
      label: "weekend",
    },
    {
      name: "Work week",
      tempId: generateTempId(),
      daysPerWk: daysPerWk || 5,
      color: theme.palette.employees.main,
      label: "workWeek",
    },
  ]);

  const onAdvanceGraphicStep = () =>
    setGraphicStep((currentStep) => {
      const newGraphicStep = currentStep + 1;

      return newGraphicStep > 3 ? currentStep : newGraphicStep;
    });

  const editCommuteEndpoints = (newEndpoints) =>
    editTimePeriod("commuteEndpoints")(newEndpoints);

  const buildFormTitle = () => {
    if (startDate && endDate) {
      return `Commute Schedule from ${formatDate(startDate)} to ${formatDate(
        endDate
      )}`;
    }

    if (startDate) {
      return `Commute Schedule from ${formatDate(startDate)} to now`;
    }

    if (endDate) {
      return `Commute Schedule prior to ${formatDate(endDate)}`;
    }

    return "Commute Schedule";
  };

  const onDaysPerWeekNextStep = () => {
    const conditionallyAdvanceGraphicStep = () => {
      if (commuteEndpoints.length > 1) {
        return onAdvanceGraphicStep();
      }
      return setGraphicStep(3);
    };
    conditionallyAdvanceGraphicStep();

    const { daysPerWk: adjustedDaysPerWk } = workSchedule.find(
      ({ label }) => label === "workWeek"
    );
    editTimePeriod("daysPerWk")(adjustedDaysPerWk);

    const endpointsCount = commuteEndpoints.length;
    if (endpointsCount === 1) {
      return editCommuteEndpoints(
        commuteEndpoints.map((endpoint) => ({
          ...endpoint,
          daysPerWk: adjustedDaysPerWk,
        }))
      );
    }

    const dividedDaysPerWk = adjustedDaysPerWk / endpointsCount;

    const otherEndpointsDaysPerWk = [...new Array(endpointsCount - 1)].map(
      () => Math.ceil(dividedDaysPerWk * 4) / 4
    );

    const otherEndpointsDaysPerWkSum = otherEndpointsDaysPerWk.reduce(
      (sum, curr) => Number(sum) + Number(curr),
      0
    );

    const firstEndpointDaysPerWk =
      adjustedDaysPerWk - otherEndpointsDaysPerWkSum;
    const daysPerWkArray = [firstEndpointDaysPerWk, ...otherEndpointsDaysPerWk];

    return editCommuteEndpoints(
      commuteEndpoints.map(({ timePeriods, ...endpoint }, idx) => ({
        ...endpoint,
        daysPerWk: daysPerWkArray[idx],
      }))
    );
  };

  const { workspaceQuestions, isNotValidWorkspaceTypeQuestions } =
    useDefineWorkspacesSteps({
      commuteEndpoints,
      editCommuteEndpoints,
      endDate,
      updatePeriodEndpoints,
      previousHome: previousPeriod?.home,
      editTimePeriod,
      editTimePeriodHomeProps,
      home,
      survey,
    });

  const timePeriodTransitionStep = useTimePeriodTransitionStep({
    onSubmit,
    editTimePeriod,
    startDate,
    addNewTimePeriod,
    endDate,
    survey,
    newCommuteScheduleSurvey,
    employeeCommuteSurvey,
  });

  const timePeriodTransitionStepArray = isActivePeriod
    ? [timePeriodTransitionStep]
    : [];

  const commuteScheduleStep =
    commuteEndpoints.length > 1
      ? [
          {
            label: "Commute Schedule",
            title: `Great, now we need to know how often ${
              survey ? `you work` : `this employee works`
            } 
              in each of these workspaces and how ${
                survey ? `you` : `they`
              } get there.`,
            input: (
              <Typography variant="body1">
                {`We've placed all of ${
                  survey ? `your` : `this employee's`
                } workspaces on this graphic
                to represent how many days per week ${
                  survey ? `you work` : `this employee works`
                } in each
                one. The actual day of the week doesn't matter (e.g. Wednesday
                vs. Thursday), only how many days per week. Drag the arrows to
                adjust how often ${
                  survey ? `you work` : `this employee works`
                } in each workspace and then
                move on to the next step.`}
              </Typography>
            ),
            onAdvanceForm: onAdvanceGraphicStep,
          },
        ]
      : [];

  const commuteVehiclesStep = !!nonHomeEndpoints.length
    ? [
        {
          label: "Commute Vehicles",
          title: `Now we need to know which vehicles ${
            survey ? `you use` : `this employee uses`
          } to commute to each location.`,
          input: <DragVehiclesStep survey={survey} />,
          buttonDisabled: !commuteEndpoints.reduce(
            (acc, { type, vehicles = [] }) => {
              if (type === "homeOffice") {
                return acc && true;
              }
              return acc && vehicles.length;
            },
            true
          ),
        },
      ]
    : [];

  const forms = [
    {
      label: "Workspaces",
      title: endDate
        ? `Now we need to know where ${
            survey ? `you` : `this employee`
          } worked prior to ${formatDate(endDate)}`
        : `Now we need to know where ${
            survey ? `you work` : `this employee works`
          } and some information about each of those workspaces`,
      rows: workspaceQuestions,
      formAdvanceErrorMessage: isNotValidWorkspaceTypeQuestions
        ? "You need to have at least one workplace selected before moving on."
        : "",
      onAdvanceForm: onAdvanceGraphicStep,
    },
    {
      label: "Work Schedule",
      title: `We're going to use the graphic to the right to build ${
        survey ? `your` : `this employee's`
      } commute schedule. A commute schedule includes the workspaces ${
        survey ? `you're` : `they're`
      } commuting to, the vehicles ${
        survey ? `you're` : `they're`
      } using to get there, and how often ${
        survey ? `you` : `they`
      } travel to and work from each workspace.`,
      input: (
        <Typography variant="body1">
          {`Let's start with how many days per week ${
            survey ? `you work` : `this employee works`
          }. Drag the
          slider on the chart to the right to adjust ${
            survey ? `your` : `this employee's`
          } work
          schedule then move onto the next step.`}
        </Typography>
      ),
      onAdvanceForm: onDaysPerWeekNextStep,
    },
    ...commuteScheduleStep,
    ...commuteVehiclesStep,
    ...timePeriodTransitionStepArray,
  ];

  return (
    <>
      {!isActivePeriod && (
        <Grid item>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={mergeDarkTheme}>
              <Container maxWidth={formOpen ? "xl" : "md"}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <Avatar
                          style={{
                            backgroundColor: theme.palette.employees.main,
                          }}
                        >
                          <DoneIcon color="primary" />
                        </Avatar>
                      </Grid>
                      <Grid item>
                        <Typography variant="h5">{buildFormTitle()}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={() =>
                        setFormOpen((currentStatus) => !currentStatus)
                      }
                      size="large"
                    >
                      {formOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  </Grid>
                </Grid>
              </Container>
            </ThemeProvider>
          </StyledEngineProvider>
        </Grid>
      )}
      {(isActivePeriod || formOpen) && (
        <Grid
          item
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item sm={7} container direction="column" spacing={2}>
            <MultiPartFormReveal forms={forms} showAllSteps={!isActivePeriod} />
          </Grid>
          {!!graphicStep && (
            <Grid item sm={5} xs={12}>
              <DefaultPaper>
                <NewEmployeeWorkspacesGraphic
                  commuteEndpoints={commuteEndpoints}
                  editCommuteEndpoints={editCommuteEndpoints}
                  updatePeriodEndpoints={updatePeriodEndpoints}
                  workSchedule={workSchedule}
                  setWorkSchedule={setWorkSchedule}
                  daysPerWk={daysPerWk}
                  graphicStep={graphicStep}
                  endDate={endDate}
                  home={home}
                  editTimePeriodHome={editTimePeriodHome}
                />
              </DefaultPaper>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

const BuildWorkspacesForm = ({
  onSubmit,
  surveyThankYouStep,
  newCommuteScheduleSurvey,
  employeeData,
  modifiedEmployeeVehicles,
  employeeCommuteSurvey,
}) => {
  const { newEmployee, setNewEmployee, employeeVehicles, setEmployeeVehicles } =
    useSharedNewEmployee();

  const {
    address: employeeAddress,
    homeSqFootage,
    realtyMolePropertyId,
  } = employeeData || newEmployee;

  const [timePeriods, setTimePeriods] = useState([
    {
      tempId: generateTempId(),
      home: {
        address: employeeAddress,
        homeArea: homeSqFootage,
        realtyMolePropertyId,
      },
    },
  ]);

  const [timePeriodStep, setTimePeriodStep] = useState(0);
  const [showSurveyThankYouStep, setShowSurveyThankYouStep] = useState(false);

  const editTimePeriod = (idx) => (field) => (value) =>
    editRowData(idx, setTimePeriods)(field, value);

  const editTimePeriodHomeProps = (periodTempId) => (field) => (value) =>
    setTimePeriods((currentPeriods) =>
      currentPeriods.map((period) => {
        if (period.tempId !== periodTempId) {
          return period;
        }
        const { home } = period;
        return { ...period, home: { ...home, [field]: value } };
      })
    );

  const editTimePeriodHome = (periodTempId) => (newHomeProps) =>
    setTimePeriods((currentPeriods) =>
      currentPeriods.map((period) => {
        if (period.tempId !== periodTempId) {
          return period;
        }
        const { home } = period;
        return { ...period, home: { ...home, ...newHomeProps } };
      })
    );

  const updatePeriodEndpoints = (periodTempId) => (updateFunction) =>
    setTimePeriods((currentPeriods) =>
      currentPeriods.map((period) => {
        if (periodTempId !== period.tempId) {
          return period;
        }
        const { commuteEndpoints } = period;
        return {
          ...period,
          commuteEndpoints: updateFunction(commuteEndpoints),
        };
      })
    );

  const addNewTimePeriod = () => {
    setTimePeriods((currentPeriods) => {
      const { startDate, home } = currentPeriods[timePeriodStep];
      const endDate = dayjs(startDate).subtract(1, "day");

      return [
        ...currentPeriods,
        {
          tempId: generateTempId(),
          endDate,
          home: { address: home.address },
        },
      ];
    });
    return setTimePeriodStep((currentStep) => currentStep + 1);
  };

  const submitFormattedTimePeriods = () => {
    const formattedTimePeriods = timePeriods.map((period) => {
      const { tempId, commuteEndpoints, home, ...otherPeriodProps } = period;
      const formattedCommuteEndpoints = commuteEndpoints.map(
        ({
          type,
          address,
          name,
          homeArea,
          homeOfficeArea,
          daysPerWk,
          vehicles,
          id,
        }) => {
          if (type === "homeOffice") {
            return { type, name, homeArea, homeOfficeArea, daysPerWk };
          }

          const idObj = id ? { id } : {};

          return {
            type,
            address,
            name,
            daysPerWk,
            vehicles,
            ...idObj,
          };
        }
      );
      const { homeArea, homeOfficeArea } = home;
      const homeAreaNumbersObj =
        homeArea && homeOfficeArea
          ? {
              homeArea: Number(homeArea),
              homeOfficeArea: Number(homeOfficeArea),
            }
          : {};

      return {
        commuteEndpoints: formattedCommuteEndpoints,
        home: { ...home, ...homeAreaNumbersObj },
        ...otherPeriodProps,
      };
    });

    if (surveyThankYouStep) {
      setShowSurveyThankYouStep(true);
    }

    return onSubmit(formattedTimePeriods);
  };

  const timePeriodIsActive = (idx) => {
    if (showSurveyThankYouStep) {
      return false;
    }

    return timePeriodStep === idx;
  };

  useEffect(() => {
    if (
      !Object.keys(newEmployee).length &&
      (newCommuteScheduleSurvey || employeeCommuteSurvey)
    ) {
      setNewEmployee(employeeData);
    }

    if (!employeeVehicles.length && modifiedEmployeeVehicles?.length) {
      setEmployeeVehicles(modifiedEmployeeVehicles);
    }
  }, [
    employeeData,
    newCommuteScheduleSurvey,
    newEmployee,
    setNewEmployee,
    modifiedEmployeeVehicles,
    setEmployeeVehicles,
    employeeCommuteSurvey,
    employeeVehicles,
  ]);

  return (
    <Grid container direction="column" spacing={4}>
      {timePeriods.map((timePeriod, idx) => (
        <>
          <TimePeriodGraphicForm
            key={`time-period-graphic-form-${idx}`}
            onSubmit={submitFormattedTimePeriods}
            editTimePeriod={editTimePeriod(idx)}
            addNewTimePeriod={addNewTimePeriod}
            updatePeriodEndpoints={updatePeriodEndpoints(timePeriod.tempId)}
            editTimePeriodHomeProps={editTimePeriodHomeProps(timePeriod.tempId)}
            editTimePeriodHome={editTimePeriodHome(timePeriod.tempId)}
            isActivePeriod={timePeriodIsActive(idx)}
            previousPeriod={timePeriods[idx - 1]}
            survey={surveyThankYouStep}
            newCommuteScheduleSurvey={newCommuteScheduleSurvey}
            employeeCommuteSurvey={employeeCommuteSurvey}
            {...timePeriod}
          />
          {idx < timePeriodStep && <FormDivider />}
        </>
      ))}
      {showSurveyThankYouStep && (
        <>
          <FormDivider />
          <Grid item container direction="column" spacing={4}>
            <MultiPartFormReveal forms={surveyThankYouStep} />
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default BuildWorkspacesForm;
