import { emissionsCategoriesTags } from "@aclymatepackages/subcategories";

export const findThisTagIconColor = (name) => {
  return emissionsCategoriesTags.find((tag) => tag.name === name) || {};
};

const formatOfficeVehicleTagData = (tag, data) => {
  if (!data) {
    return {};
  }

  const { name, id, tonsCo2ePerMile } = data;
  const tonsCo2ePerMileObj = tonsCo2ePerMile
    ? {
        tonsCo2ePerMile,
      }
    : {};

  return {
    [tag]: { name, id, ...tonsCo2ePerMileObj },
  };
};

export const buildFormattedTagsObjs = ({
  employees,
  vehicle,
  office,
  knownVendor,
}) => {
  const buildEmployeeTagProps = () => {
    if (!employees) {
      return {};
    }

    const formattedEmployees = employees.map(({ name, id }) => ({ name, id }));
    const employeeIds = formattedEmployees.map(({ id }) => id);
    return {
      employees: formattedEmployees,
      taggedEmployeesIds: employeeIds,
    };
  };

  const formattedEmployees = buildEmployeeTagProps();
  const formattedVehicle = formatOfficeVehicleTagData("vehicle", vehicle);
  const formattedOffice = formatOfficeVehicleTagData("office", office);
  const formattedVendor = formatOfficeVehicleTagData(
    "knownVendor",
    knownVendor
  );

  return {
    ...formattedEmployees,
    ...formattedVehicle,
    ...formattedOffice,
    ...formattedVendor,
  };
};
