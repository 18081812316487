import React, { useState } from "react";

import {
  Typography,
  Divider,
  Grid,
  InputAdornment,
  IconButton,
  Tabs,
  Tab,
  useTheme,
} from "@mui/material";

import SaveIcon from "@mui/icons-material/Save";

import { TextField, CategoriesAvatar } from "@aclymatepackages/atoms";
import { hexToRgba } from "@aclymatepackages/converters";
import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { subcategories } from "@aclymatepackages/subcategories";

import TagAvatar from "../../atoms/icons/TagAvatar";
import ManualTransactionsInput from "../../inputs/emissions/ManualTransactionsInput";
import SlideLayout from "../../layouts/SlideLayout";

const EmissionsDetailsFooter = ({
  name,
  emissionsType,
  onManualTransactionSelect,
  inputSubcategories,
}) => {
  const filteredSubcategories = subcategories.filter(
    ({ input, tags = [] }) => input && tags.includes(emissionsType)
  );

  const displaySubcategories = inputSubcategories || filteredSubcategories;

  return (
    <>
      {!!displaySubcategories.length && (
        <Grid container justifyContent="center" spacing={1} direction="column">
          <Grid item>
            <Typography variant="h6" align="center">
              {`Add more emissions for ${name}`}
            </Typography>
          </Grid>
          <Grid container item justifyContent="center" spacing={1}>
            {displaySubcategories.map(({ subcategory }, idx) => (
              <Grid item key={`subcategory-selection-card-${idx}`}>
                <IconButton
                  size="small"
                  onClick={() => onManualTransactionSelect(subcategory)}
                >
                  <CategoriesAvatar subcategory={subcategory} tooltip />
                </IconButton>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </>
  );
};

const SettingsObjectDetailsSlider = ({
  type,
  objectName: parentObjectName,
  settingsObject,
  editSettingsObject,
  onNameSave,
  setSelectedObject,
  views = [],
  noFooter,
  footerInputSubcategories,
  leftContent,
}) => {
  const { name: originalName } = settingsObject;
  const [{ value: firstViewValue }] = views;

  const { palette } = useTheme();

  const [objectName, setObjectName] = useState(originalName);
  const [selectedView, setSelectedView] = useState(firstViewValue);

  const [
    manualTransactionsInputSubcategory,
    setManualTransactionsInputSubcategory,
  ] = useState(false);

  const onSaveClick = () => {
    editObjectData(setSelectedObject, "name", objectName);
    return onNameSave(objectName);
  };

  const adornmentProps =
    objectName !== originalName && onNameSave
      ? {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton size="small" onClick={onSaveClick}>
                <SaveIcon />
              </IconButton>
            </InputAdornment>
          ),
        }
      : {};

  const { value, content, footer, contentPosition } =
    views.find(({ value }) => value === selectedView) || {};

  const buildTagProps = () => {
    if (type === "employees") {
      return { employees: [settingsObject] };
    }
    if (type === "vendors") {
      return { knownVendor: settingsObject };
    }

    const singleType = type.slice(0, -1);
    return { [singleType]: settingsObject };
  };

  const availableSubcategories = () => {
    if (!footerInputSubcategories) {
      return subcategories.filter(
        ({ input, tags = [] }) => input && tags.includes(type)
      );
    }

    const categories = footerInputSubcategories.map(
      ({ subcategory }) => subcategory
    );

    return subcategories.filter(
      ({ input, subcategory, tags = [] }) =>
        input && tags.includes(type) && categories.includes(subcategory)
    );
  };

  return (
    <>
      {manualTransactionsInputSubcategory ? (
        <ManualTransactionsInput
          setDialogOpen={setSelectedObject}
          startingTransactionProps={{
            subcategory: manualTransactionsInputSubcategory,
            ...buildTagProps(),
          }}
          availableSubcategories={availableSubcategories()}
          emissionsAddName={originalName}
        />
      ) : (
        <SlideLayout
          isSlideOpen
          header={
            <Grid container spacing={2} wrap="nowrap" alignItems="center">
              <Grid item>
                <TagAvatar tag={type} />
              </Grid>
              <Grid item sm={10}>
                <TextField
                  variant="standard"
                  value={parentObjectName ?? objectName}
                  setValue={
                    editSettingsObject
                      ? editSettingsObject("name")
                      : setObjectName
                  }
                  InputProps={{
                    style: { fontSize: "1rem", fontWeight: 400 },
                    ...adornmentProps,
                  }}
                />
              </Grid>
            </Grid>
          }
          tagInputs={
            views.length > 1 && (
              <>
                <Tabs
                  value={value}
                  onChange={(_, value) => setSelectedView(value)}
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  {views.map(({ label, value }, idx) => (
                    <Tab
                      label={label}
                      value={value}
                      key={`object-details-view-tab-${idx}`}
                    />
                  ))}
                </Tabs>
                <Divider />
              </>
            )
          }
          content={content}
          contentPosition={contentPosition}
          footer={
            !noFooter &&
            (footer ||
              (selectedView === "emissions" && (
                <EmissionsDetailsFooter
                  inputSubcategories={footerInputSubcategories}
                  name={originalName}
                  emissionsType={type}
                  onManualTransactionSelect={(subcategory) =>
                    setManualTransactionsInputSubcategory(subcategory)
                  }
                />
              )))
          }
          footerColor={
            !noFooter &&
            selectedView === "emissions" &&
            (!footerInputSubcategories || !!footerInputSubcategories.length) &&
            hexToRgba(palette[type].main, 0.1)
          }
          setIsSlideOpen={setSelectedObject}
          leftContent={leftContent}
        />
      )}
    </>
  );
};
export default SettingsObjectDetailsSlider;
