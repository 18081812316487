import React, { useState } from "react";

import {
  Box,
  Grid,
  Typography,
  Avatar,
  ButtonBase,
  useTheme,
} from "@mui/material";

import PagePromote from "./PagePromote";
import CertificationsBasecamp from "./CertificationsBasecamp";
import PageSetup from "./PageSetup";

const BadgeStepTab = ({ number, name, color, isSelected, onClick }) => {
  const avatarTextColor = isSelected ? color : "white";
  const numberBackgroundColor = isSelected ? "white" : color;

  return (
    <Grid item xs={4}>
      <ButtonBase style={{ width: "100%" }} onClick={onClick}>
        <Box
          p={2}
          style={{ backgroundColor: numberBackgroundColor, width: "100%" }}
        >
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <Avatar
                style={{
                  backgroundColor: avatarTextColor,
                  color: numberBackgroundColor,
                }}
              >
                {number}
              </Avatar>
            </Grid>
            <Grid item>
              <Typography variant="h3" style={{ color: avatarTextColor }}>
                {name}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </ButtonBase>
    </Grid>
  );
};

const Badges = () => {
  const { palette } = useTheme();

  const [selectedTabIdx, setSelectedTabIdx] = useState(0);

  const pageTabs = [
    { name: "GET CERTIFIED", color: "commuting-auto" },
    { name: "PAGE SETUP", color: "vendors" },
    { name: "PROMOTE", color: "employees" },
  ];

  const tabsContent = [
    <CertificationsBasecamp setSelectedTabIdx={setSelectedTabIdx} />,
    <PageSetup />,
    <PagePromote />,
  ];

  return (
    <Box
      style={{ height: "calc(100vh - 75px)", overflow: "hidden" }}
      display="flex"
      flexDirection="column"
    >
      <Grid container>
        {pageTabs.map(({ name, color }, idx) => (
          <BadgeStepTab
            key={`badge-step-tab-${idx}`}
            number={idx + 1}
            name={name}
            color={palette[color]?.main}
            isSelected={selectedTabIdx === idx}
            onClick={() => setSelectedTabIdx(idx)}
          />
        ))}
      </Grid>
      <Box
        backgroundColor={palette.backgroundGray.light}
        p={4}
        flexGrow={1}
        style={{ overflowY: "auto" }}
      >
        <Grid
          container
          direction="column"
          spacing={2}
          style={{ maxWidth: "100%" }}
        >
          {tabsContent[selectedTabIdx]}
        </Grid>
      </Box>
    </Box>
  );
};
export default Badges;
