import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";

import {
  Grid,
  Slider,
  Tooltip,
  Typography,
  Tabs,
  Tab,
  Button,
  StyledEngineProvider,
  Box,
  FormControlLabel,
  Checkbox,
  ThemeProvider,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoePrints } from "@fortawesome/free-solid-svg-icons";

import { YesNoQuestion } from "@aclymatepackages/modules";
import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import {
  Select,
  TextField,
  PlusMinusNumericInput,
} from "@aclymatepackages/atoms";
import { emailRegExpTest, numbersRegExpTest } from "@aclymatepackages/reg-exp";
import { rentalCarCategories } from "@aclymatepackages/lists";
import mainTheme, {
  mergeMainTheme,
  useLayoutHelpers,
} from "@aclymatepackages/themes";
import { MultiPartFormLayout } from "@aclymatepackages/multi-part-form";
import { formatDecimal } from "@aclymatepackages/formatters";
import {
  otherTransportationCarbon,
  petsCarbon,
  monthlyDietTypeCarbon,
  getYearlyTonsCo2eValues,
} from "@aclymatepackages/calcs";
import {
  US_DOMESTIC_FLIGHT_DISTANCE_MI,
  INTERNATIONAL_FLIGHT_DISTANCE_MI,
} from "@aclymatepackages/constants";

import FootprintDisplay from "./FootprintDisplay";

import ButtonCards from "../atoms/buttons/ButtonCards";
import PlacesAutocomplete from "../inputs/autocomplete/PlacesAutocomplete";
import VehicleMakeModelSelect from "../inputs/vehicles/VehicleMakeModelSelect";
import VehicleYearSelect from "../inputs/vehicles/VehicleYearSelect";
import FuelTypesSelect from "../inputs/vehicles/FuelTypesSelect";

import { fetchOurApi } from "../../helpers/utils/apiCalls";
import { analyticsTrack } from "../../helpers/analytics";
import { setAddress } from "../../helpers/utils/geography";
import { calcTonsCo2eForVehicleType } from "../../helpers/components/events";

const ALT_VEHICLE_TYPES = [
  { label: "Long Haul", value: "longHaul" },
  { label: "Short Haul", value: "shortHaul" },
  { label: "Medium Duty Urban", value: "mediumDutyUrban" },
  { label: "Transit Bus", value: "transitBus" },
  { label: "Other Bus", value: "otherBus" },
  { label: "Heavy Duty Pickup", value: "heavyDutyPickup" },
  { label: "Medium Duty Vocational", value: "mediumDutyVocational" },
  { label: "Heavy Duty Vocational", value: "heavyDutyVocational" },
];

const ALT_VEHICLE_FUEL_TYPES = [
  { label: "Gas", value: "gas" },
  { label: "Diesel", value: "diesel" },
];

const GreenCheckButtonCards = ({
  options,
  setOption,
  selectedOption,
  gridItemProps,
  exclusive,
}) => (
  <ButtonCards
    options={options}
    setOption={setOption}
    selectedOption={selectedOption}
    color="#39b54a"
    gridItemProps={gridItemProps}
    exclusive={exclusive}
    themeColor="dark"
  />
);

const PercentageValueLabel = ({ value, open, children, placement = "top" }) => (
  <Tooltip open={open} title={value} placement={placement}>
    {children}
  </Tooltip>
);

const UtilityUsageInput = ({
  utilityUsage = {},
  availableHeatingTypes,
  utilityTypeKey,
  editIndividual,
}) => {
  const { value, unit, selectedCard } = utilityUsage[utilityTypeKey] || {};

  const {
    title: label,
    cardValues,
    defaultUnit,
  } = availableHeatingTypes.find(({ value }) => value === utilityTypeKey);
  const { belowAverage, average, aboveAverage } = cardValues;
  const timeScale = ["wood", "propane"].includes(utilityTypeKey)
    ? "year"
    : "month";

  const cardsOptions = [
    {
      title: "Below Average",
      subtitle: `${belowAverage} ${defaultUnit}/${timeScale}`,
      value: "belowAverage",
      usageValue: belowAverage,
    },
    {
      title: "Average",
      subtitle: `${average} ${defaultUnit}/${timeScale}`,
      value: "average",
      usageValue: average,
    },
    {
      title: "Above Average",
      subtitle: `${aboveAverage} ${defaultUnit}/${timeScale}`,
      value: "aboveAverage",
      usageValue: aboveAverage,
    },
    {
      title: "Custom",
      value: "custom",
      usageValue: 0,
    },
  ];

  const unitsList = {
    electricity: [{ label: "kWh / month", value: "kWh" }],
    naturalGas: [
      { label: "Therms / month", value: "therms" },
      { label: "ft\u00b3 / month", value: "cubicFeet" },
    ],
    propane: [{ label: "Gallons / year", value: "gallons" }],
    wood: [{ label: "Cords / year", value: "cords" }],
    heatingOil: [{ label: "Gallons / month", value: "gallons" }],
  };

  const saveNewUtilityUsage = (newUtilityTypeObj) =>
    editIndividual("utilityUsage")({
      ...utilityUsage,
      [utilityTypeKey]: newUtilityTypeObj,
    });

  const editUsageValue = (usageValue) =>
    saveNewUtilityUsage({
      value: Number(usageValue),
      unit,
      selectedCard: "custom",
    });

  const editUnitValue = (unitValue) =>
    saveNewUtilityUsage({ value, unit: unitValue, selectedCard: "custom" });

  return (
    <Grid item container spacing={1} justifyContent="center" direction="column">
      <Grid item>
        <Typography
          variant="body1"
          align="center"
          color="textPrimary"
        >{`${label} usage per ${timeScale}`}</Typography>
      </Grid>
      <Grid item>
        <GreenCheckButtonCards
          options={cardsOptions}
          setOption={(newValue) => {
            const { usageValue = 0 } =
              cardsOptions.find(({ value }) => value === newValue) || {};

            return saveNewUtilityUsage({
              value: usageValue,
              unit: defaultUnit,
              selectedCard: newValue,
            });
          }}
          selectedOption={selectedCard}
        />
      </Grid>
      {selectedCard === "custom" && (
        <Grid item container spacing={2}>
          <Grid item xs={8}>
            <TextField
              label={`${label} Usage`}
              value={value === 0 ? "0" : value}
              setValue={editUsageValue}
              helperText={
                (value < 0 || (!!value && !numbersRegExpTest(value))) &&
                "Please enter a non-negative number."
              }
              error={value < 0 || (!!value && !numbersRegExpTest(value))}
              variant="filled"
              id="utility-usage-input"
            />
          </Grid>
          <Grid item xs={4}>
            <Select
              options={unitsList[utilityTypeKey]}
              label="Unit"
              value={unit}
              editValue={editUnitValue}
              size="small"
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const UtilityUsageInterface = ({ individual = {}, editIndividual }) => {
  const {
    utilityUsage,
    electricityRenewablesPercentage,
    selectedHeatingTypes,
  } = individual;

  const availableHeatingTypes = [
    {
      value: "electricity",
      title: "Electricity",
      defaultUnit: "kWh",
      cardValues: {
        belowAverage: 459,
        average: 917,
        aboveAverage: 1834,
      },
    },
    {
      value: "naturalGas",
      title: "Natural Gas",
      defaultUnit: "therms",
      cardValues: {
        belowAverage: 33,
        average: 67,
        aboveAverage: 134,
      },
    },
    {
      value: "propane",
      title: "Propane",
      defaultUnit: "gallons",
      cardValues: {
        belowAverage: 221,
        average: 441,
        aboveAverage: 882,
      },
    },
    {
      value: "wood",
      title: "Wood",
      defaultUnit: "cords",
      cardValues: {
        belowAverage: 0.75,
        average: 1.5,
        aboveAverage: 3,
      },
    },
    {
      value: "heatingOil",
      title: "Heating Oil",
      defaultUnit: "gallons",
      cardValues: {
        belowAverage: 21,
        average: 41,
        aboveAverage: 82,
      },
    },
  ];

  return (
    <Grid container spacing={2} direction="column" alignItems="center">
      <UtilityUsageInput
        utilityUsage={utilityUsage}
        availableHeatingTypes={availableHeatingTypes}
        utilityTypeKey="electricity"
        editIndividual={editIndividual}
      />
      <Grid item container direction="column" alignItems="center">
        <Grid item>
          <Typography variant="body1" align="center" color="textPrimary">
            What percentage of your electricity comes from renewable energy
            sources?
          </Typography>
        </Grid>
        <Grid item container style={{ width: "90%" }}>
          <Slider
            step={10}
            value={electricityRenewablesPercentage}
            onChange={(e, newValue) =>
              editIndividual("electricityRenewablesPercentage")(newValue)
            }
            marks={Array.from({ length: 11 }).map((val, idx) => {
              const markValue = idx * 10;

              if (!idx || idx === 10) {
                return {
                  value: markValue,
                  label: `${markValue}%`,
                };
              }

              return { value: markValue };
            })}
            valueLabelDisplay="on"
            valueLabelFormat={(selectedValue) => `${selectedValue}%`}
            components={{
              ValueLabel: (otherProps) => (
                <PercentageValueLabel placement="bottom" {...otherProps} />
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="body1" align="center" color="textPrimary">
          How do you heat your home?
        </Typography>
      </Grid>
      <Grid item>
        <GreenCheckButtonCards
          options={availableHeatingTypes}
          setOption={editIndividual("selectedHeatingTypes")}
          selectedOption={selectedHeatingTypes}
          exclusive={false}
        />
      </Grid>
      {selectedHeatingTypes
        .filter((type) => type !== "electricity")
        .map((heatingType) => (
          <UtilityUsageInput
            utilityUsage={utilityUsage}
            availableHeatingTypes={availableHeatingTypes}
            utilityTypeKey={heatingType}
            editIndividual={editIndividual}
            key={heatingType}
          />
        ))}
    </Grid>
  );
};

const HouseholdMembersInterface = ({
  individual,
  editIndividual,
  setMonthlyPetsTonsCo2e,
}) => {
  const { isMobile } = useLayoutHelpers();

  const {
    selectedCalcType = "",
    numPeople,
    numRoommates,
    numDogs,
    numCats,
    numLargeDogs,
  } = individual;

  const cardsOptions = [
    {
      title: "Just me",
      value: "justMe",
    },
    {
      title: "Household",
      value: "household",
    },
  ];

  const handleEditPets = (petType) => (numPets) => {
    editIndividual(petType)(numPets);

    return setMonthlyPetsTonsCo2e(
      petsCarbon({
        numDogs,
        numCats,
        numLargeDogs,
        [petType]: numPets,
      })
    );
  };

  return (
    <Grid container alignItems="center" spacing={2} direction="column">
      <Grid item>
        <Typography variant="body1" color="textPrimary" align="center">
          Would you like to calculate a footprint for just yourself or everyone
          in your household?
        </Typography>
        <Typography variant="body1" color="textSecondary" align="center">
          Selecting household will account for emissions from the diet,
          household utilities, and financial expenses of the other people that
          live with you, including children.
        </Typography>
      </Grid>
      <Grid item>
        <GreenCheckButtonCards
          options={cardsOptions}
          setOption={(newValue) => {
            if (newValue === "justMe") {
              editIndividual("numPeople")(1);
            }

            editIndividual("numRoommates")(0);

            return editIndividual("selectedCalcType")(newValue);
          }}
          selectedOption={selectedCalcType}
        />
      </Grid>
      {selectedCalcType && (
        <>
          <Grid item>
            <Typography variant="body1" color="textPrimary">
              Who lives with you?
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            <Grid
              item
              container
              justifyContent="space-between"
              wrap="nowrap"
              spacing={2}
              direction={isMobile ? "column" : "row"}
            >
              <Grid item>
                <Grid
                  item
                  container
                  direction="column"
                  alignItems={isMobile ? "center" : "flex-end"}
                  spacing={2}
                >
                  <Grid item>
                    {selectedCalcType === "justMe" ? (
                      <PlusMinusNumericInput
                        value={numRoommates}
                        editValue={editIndividual("numRoommates")}
                        label="Roommates"
                      />
                    ) : (
                      <PlusMinusNumericInput
                        value={numPeople}
                        editValue={(newNumPeople) =>
                          editIndividual("numPeople")(
                            newNumPeople >= 1 ? newNumPeople : 1
                          )
                        }
                        label="People"
                      />
                    )}
                  </Grid>
                  <Grid item>
                    <PlusMinusNumericInput
                      value={numCats}
                      editValue={handleEditPets("numCats")}
                      label="Cats"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  item
                  container
                  direction="column"
                  alignItems={isMobile ? "center" : "flex-end"}
                  spacing={2}
                >
                  <Grid item>
                    <PlusMinusNumericInput
                      value={numDogs}
                      editValue={handleEditPets("numDogs")}
                      label="Dogs"
                    />
                  </Grid>
                  <Grid item>
                    <PlusMinusNumericInput
                      value={numLargeDogs}
                      editValue={handleEditPets("numLargeDogs")}
                      label="Large Dogs"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container justifyContent="center">
              <Typography variant="subtitle2" color="textSecondary">
                Large dogs are over 50 lbs
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

const useResidenceStepsRows = ({ individual, editIndividual }) => {
  const [monthlyHomeTonsCo2e, setMonthlyHomeTonsCo2e] = useState({
    monthlyElectricCarbonTons: 0,
    monthlyGasCarbonTons: 0,
    monthlyHeatingOilCarbonTons: 0,
    monthlyPropaneCarbonTons: 0,
    monthlyWoodCarbonTons: 0,
  });
  const [monthlyPetsTonsCo2e, setMonthlyPetsTonsCo2e] = useState({
    monthlyDogsTonsCo2e: 0,
    monthlyCatsTonsCo2e: 0,
    monthlyLargeDogsTonsCo2e: 0,
  });

  const {
    address,
    numPeople,
    numRoommates,
    numDogs,
    numCats,
    numLargeDogs,
    homeSqFootage,
    isUtilityUsageKnown,
    utilityUsage,
    electricityRenewablesPercentage,
    selectedHeatingTypes,
  } = individual;
  const { state, zipCode } = address || {};
  const {
    electricity: monthlyElectricityUsage,
    heatingOil: monthlyHeatingOilUsage,
    naturalGas: monthlyNaturalGasUsage,
    propane: yearlyPropaneUsage,
    wood: yearlyWoodUsage,
  } = utilityUsage;

  const isValidPets = [numDogs, numCats, numLargeDogs].reduce(
    (isValid, value) => isValid && numbersRegExpTest(value) && value >= 0,
    true
  );

  const generateTonsCo2eObjectFromSelectedHeatingTypes = () => {
    const monthlyHomeTonsCo2eObjWithRoommates = Object.keys(
      monthlyHomeTonsCo2e
    ).reduce((currentObj, homeTonsKey) => {
      const carbonTonsValue = monthlyHomeTonsCo2e[homeTonsKey];

      if (numRoommates >= 1) {
        return {
          ...currentObj,
          [homeTonsKey]: carbonTonsValue / (numRoommates + 1),
        };
      }

      return {
        ...currentObj,
        [homeTonsKey]: carbonTonsValue,
      };
    }, {});
    const {
      monthlyElectricCarbonTons = 0,
      monthlyGasCarbonTons = 0,
      monthlyHeatingOilCarbonTons = 0,
      monthlyPropaneCarbonTons = 0,
      monthlyWoodCarbonTons = 0,
    } = monthlyHomeTonsCo2eObjWithRoommates;

    if (!isUtilityUsageKnown) {
      return {
        monthlyElectricCarbonTons,
        monthlyGasCarbonTons,
      };
    }

    const selectedHeatingTypesTonsCo2eValues = {
      electricity: monthlyElectricCarbonTons,
      naturalGas: monthlyGasCarbonTons,
      heatingOil: monthlyHeatingOilCarbonTons,
      wood: monthlyWoodCarbonTons,
      propane: monthlyPropaneCarbonTons,
    };
    const selectedHeatingTypesTonsCo2eKeys = {
      electricity: "monthlyElectricCarbonTons",
      naturalGas: "monthlyGasCarbonTons",
      heatingOil: "monthlyHeatingOilCarbonTons",
      wood: "monthlyWoodCarbonTons",
      propane: "monthlyPropaneCarbonTons",
    };
    const tonsCo2eValuesObj = selectedHeatingTypes.reduce(
      (currentObj, heatingType) => {
        const heatingTypeTonsCo2eValue =
          selectedHeatingTypesTonsCo2eValues[heatingType];
        const heatingTypeTonsCo2eKey =
          selectedHeatingTypesTonsCo2eKeys[heatingType];

        return {
          ...currentObj,
          [heatingTypeTonsCo2eKey]: heatingTypeTonsCo2eValue,
        };
      },
      {}
    );

    return {
      monthlyElectricCarbonTons,
      ...tonsCo2eValuesObj,
    };
  };

  const handleRealtyMoleInfo = ({ sqFootage, realtyMolePropertyId }) => {
    editIndividual("homeSqFootage")(sqFootage);
    return editIndividual("realtyMolePropertyId")(realtyMolePropertyId);
  };

  const handleEditAddress = (value) => {
    const { coordinates } = value || {};
    const { lat, lng } = coordinates || {};

    if (lat && lng) {
      fetchOurApi({
        path: `/airports/nearest-airport/lat:${lat},lng:${lng}`,
        method: "GET",
        callback: (res) => editIndividual("defaultAirport")(res),
      });
    }

    return editIndividual("address")(value);
  };

  useEffect(() => {
    if (!isUtilityUsageKnown && state && zipCode && homeSqFootage) {
      fetchOurApi({
        path: "/calcs/utilities/monthly-home-office-carbon-from-sq-footage",
        method: "POST",
        data: {
          state,
          zipCode,
          homeSqFootage,
          homeOfficeSqFootage: homeSqFootage,
        },
        callback: (res) => setMonthlyHomeTonsCo2e(res),
      });
    }
  }, [isUtilityUsageKnown, state, zipCode, homeSqFootage]);

  useEffect(() => {
    if (isUtilityUsageKnown) {
      fetchOurApi({
        path: "/calcs/individuals/monthly-home-carbon-from-utilities-usage",
        method: "POST",
        data: {
          monthlyElectricityUsage,
          monthlyNaturalGasUsage,
          yearlyPropaneUsage,
          yearlyWoodUsage,
          monthlyHeatingOilUsage,
          zipCode,
        },
        callback: ({ monthlyElectricCarbonTons, ...otherCarbonTons }) => {
          const monthlyElectricCarbonTonsWithRenewables =
            monthlyElectricCarbonTons -
            monthlyElectricCarbonTons * (electricityRenewablesPercentage / 100);

          return setMonthlyHomeTonsCo2e({
            monthlyElectricCarbonTons: monthlyElectricCarbonTonsWithRenewables,
            ...otherCarbonTons,
          });
        },
      });
    }
  }, [
    isUtilityUsageKnown,
    monthlyElectricityUsage,
    monthlyHeatingOilUsage,
    monthlyNaturalGasUsage,
    yearlyPropaneUsage,
    yearlyWoodUsage,
    zipCode,
    electricityRenewablesPercentage,
  ]);

  const buildUtilityUsageInterfaceRow = isUtilityUsageKnown
    ? [
        {
          value: true,
          input: (
            <UtilityUsageInterface
              individual={individual}
              editIndividual={editIndividual}
            />
          ),
        },
      ]
    : [];

  const residenceRows = [
    {
      NextStepInput: PlacesAutocomplete,
      nextStepInputProps: {
        place: address,
        onInputSelect: setAddress(handleEditAddress, handleRealtyMoleInfo),
        inputSelectPropName: "editPlace",
        size: "small",
        label: "What is your home address?",
        helperText: "We only need your physical address, not your suite number",
        textFieldProps: { variant: "filled" },
        id: "home-address-autocomplete",
      },
      value: address,
    },
    {
      value: isValidPets && numPeople >= 1,
      input: (
        <HouseholdMembersInterface
          individual={individual}
          editIndividual={editIndividual}
          setMonthlyPetsTonsCo2e={setMonthlyPetsTonsCo2e}
        />
      ),
    },
    {
      label: "What is the size of your home?",
      value: homeSqFootage === 0 ? "0" : homeSqFootage,
      helperText:
        homeSqFootage <= 0 ||
        (!!homeSqFootage && !numbersRegExpTest(homeSqFootage))
          ? "Please enter a positive number."
          : "Please enter a square footage.",
      editData: editIndividual("homeSqFootage"),
      error:
        homeSqFootage <= 0 ||
        (!!homeSqFootage && !numbersRegExpTest(homeSqFootage)),
    },
    {
      NextStepInput: YesNoQuestion,
      nextStepInputProps: {
        lightBackground: true,
        question:
          "Do you have info on your household's monthly electricity and heating usage?",
        onInputSelect: editIndividual("isUtilityUsageKnown"),
        inputSelectPropName: "setValue",
        value: isUtilityUsageKnown,
      },
      value: isUtilityUsageKnown !== undefined,
    },
    ...buildUtilityUsageInterfaceRow,
  ];

  return {
    residenceSteps: residenceRows.map((row) => ({ ...row, form: "residence" })),
    monthlyHomeTonsCo2e: generateTonsCo2eObjectFromSelectedHeatingTypes(),
    monthlyPetsTonsCo2e,
  };
};

const VehicleInput = ({ individual, editIndividual, idx }) => {
  const [availableFuelTypes, setAvailableFuelTypes] = useState([]);

  const { vehicles, address } = individual;
  const { zipCode } = address || {};
  const {
    simpleVehicleType,
    make,
    model,
    year,
    fuelType,
    vehicleType = null,
    yearlyVehicleMileageValue,
    selectedVehicleMileageCard,
    selectedVehicleFormType,
  } = vehicles[idx];
  const vehicleTypeOptions = rentalCarCategories.map(({ name, type }) => ({
    label: name,
    value: type,
  }));

  const vehicleMileageOptions = [
    {
      value: "belowAverage",
      title: "Below Average",
      subtitle: "6,378 miles/year",
      mileage: 6738,
    },
    {
      value: "average",
      title: "Average",
      subtitle: "13,476 miles/year",
      mileage: 13476,
    },
    {
      value: "aboveAverage",
      title: "Above Average",
      subtitle: "26,952 miles/year",
      mileage: 26952,
    },
    {
      value: "custom",
      title: "Custom",
      mileage: 0,
    },
  ];

  const addVehicleObjToArray = (newVehicleObj) => {
    const newVehiclesArray = vehicles.map((vehicle, vehicleIdx) =>
      vehicleIdx === idx ? newVehicleObj : vehicle
    );

    return editIndividual("vehicles")(newVehiclesArray);
  };

  const editVehicleType = (newVehicleType) => {
    const tonsCo2ePerMile = calcTonsCo2eForVehicleType(newVehicleType);

    const newVehicleObj = {
      simpleVehicleType: newVehicleType,
      tonsCo2ePerMile,
      yearlyVehicleMileageValue,
      selectedVehicleFormType,
      selectedVehicleMileageCard,
    };

    return addVehicleObjToArray(newVehicleObj);
  };

  const getTailpipeEmissionsCall = (vehicle) =>
    fetchOurApi({
      path: "/calcs/cars/tailpipe-emissions",
      method: "POST",
      data: {
        vehicle,
        zipCode,
      },
      callback: ({ vehicleCarbonTonsPerMile }) => vehicleCarbonTonsPerMile || 0,
    });

  const editVehicle = (newValueKey) => async (newValue) => {
    const clearModelInput =
      newValueKey === "make"
        ? {
            model: "",
          }
        : {};
    const newVehicleObj = {
      ...vehicles[idx],
      [newValueKey]: newValue,
      ...clearModelInput,
    };
    const {
      make: newMake,
      model: newModel,
      year: newYear,
      fuelType: newFuelType,
      vehicleType,
    } = newVehicleObj;

    if (newMake && newModel && newYear && newFuelType && !vehicleType) {
      const tonsCo2ePerMile = await getTailpipeEmissionsCall(newVehicleObj);

      return addVehicleObjToArray({
        ...newVehicleObj,
        tonsCo2ePerMile,
      });
    }

    if (newMake && newModel && newYear && !vehicleType) {
      return fetchOurApi({
        path: "/cars/available-fuel-types",
        method: "POST",
        data: { make: newMake, model: newModel, year: newYear },
        callback: async (fuelTypesArray) => {
          if (fuelTypesArray.length === 1) {
            const [fuelType] = fuelTypesArray;
            const tonsCo2ePerMile = await getTailpipeEmissionsCall(
              newVehicleObj
            );

            return addVehicleObjToArray({
              ...newVehicleObj,
              fuelType,
              tonsCo2ePerMile,
            });
          }

          addVehicleObjToArray(newVehicleObj);
          return setAvailableFuelTypes(fuelTypesArray);
        },
      });
    }

    return addVehicleObjToArray(newVehicleObj);
  };

  const handleSetShowHeavyVehicleForm = (newVehicleType) =>
    addVehicleObjToArray({
      vehicleType: newVehicleType,
      yearlyVehicleMileageValue,
    });

  const handleSetSelectedOption = (newOption) => {
    const { mileage = 0 } =
      vehicleMileageOptions.find(({ value }) => value === newOption) || {};

    return addVehicleObjToArray({
      ...vehicles[idx],
      yearlyVehicleMileageValue: mileage,
      selectedVehicleMileageCard: newOption,
    });
  };

  return (
    <Grid item container direction="column" spacing={2}>
      <Grid item container justifyContent="center">
        <Grid item>
          <Tabs
            value={selectedVehicleFormType}
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, newValue) =>
              addVehicleObjToArray({
                ...vehicles[idx],
                selectedVehicleFormType: newValue,
              })
            }
          >
            <Tab label="Vehicle Type" />
            <Tab label="Make/Model/Year" />
          </Tabs>
        </Grid>
      </Grid>
      <Grid item container spacing={1} direction="column">
        {selectedVehicleFormType === 0 ? (
          <>
            <Grid item>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                align="center"
              >
                Hint: for the most accurate results, select Make/Model/Year
              </Typography>
            </Grid>
            <Grid item>
              <Select
                options={vehicleTypeOptions}
                label="Vehicle Type"
                value={simpleVehicleType || ""}
                editValue={editVehicleType}
              />
            </Grid>
          </>
        ) : (
          <>
            {vehicleType === null ? (
              <>
                <Grid item>
                  <VehicleMakeModelSelect
                    type="makes"
                    vehicleValue={make}
                    editValue={editVehicle("make")}
                    idx={idx}
                    handleSetShowHeavyVehicleForm={
                      handleSetShowHeavyVehicleForm
                    }
                  />
                </Grid>
                <Grid item>
                  <VehicleMakeModelSelect
                    type="models"
                    vehicleValue={model}
                    editValue={editVehicle("model")}
                    make={make}
                    idx={idx}
                    handleSetShowHeavyVehicleForm={
                      handleSetShowHeavyVehicleForm
                    }
                  />
                </Grid>
                <Grid item>
                  <VehicleYearSelect
                    year={year}
                    editVehicle={editVehicle("year")}
                    noLabel={false}
                    id={`vehicle-year-select-${idx}`}
                    smallFont
                  />
                </Grid>
                {availableFuelTypes.length > 1 && (
                  <Grid item style={{ width: "100%" }}>
                    <FuelTypesSelect
                      availableFuelTypes={availableFuelTypes}
                      fuelType={fuelType}
                      editVehicle={editVehicle("fuelType")}
                      id={`vehicleFuelSelect-${idx}`}
                    />
                  </Grid>
                )}
              </>
            ) : (
              <>
                <Grid item>
                  <Select
                    size="small"
                    label="Vehicle Type"
                    value={vehicleType || ""}
                    editValue={(newValue) => {
                      if (newValue === "backToRegularVehicles") {
                        return handleSetShowHeavyVehicleForm(null);
                      }

                      return editVehicle("vehicleType")(newValue);
                    }}
                    options={[
                      ...ALT_VEHICLE_TYPES,
                      {
                        label: "Back to regular vehicles",
                        value: "backToRegularVehicles",
                      },
                    ]}
                    smallFont
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="Vehicle Make"
                    value={make || ""}
                    setValue={editVehicle("make")}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="Vehicle Model"
                    value={model || ""}
                    setValue={editVehicle("model")}
                  />
                </Grid>
                <Grid item>
                  <Select
                    size="small"
                    label={"Fuel Type"}
                    value={fuelType || ""}
                    editValue={editVehicle("fuelType")}
                    options={ALT_VEHICLE_FUEL_TYPES}
                    smallFont
                  />
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
      <Grid
        item
        container
        spacing={2}
        justifyContent="center"
        direction="column"
      >
        <Grid item>
          <Typography variant="body1" align="center" color="textPrimary">
            How many miles do you drive this vehicle per year?
          </Typography>
        </Grid>
        <Grid item>
          <GreenCheckButtonCards
            options={vehicleMileageOptions}
            setOption={handleSetSelectedOption}
            selectedOption={selectedVehicleMileageCard}
          />
        </Grid>
        {selectedVehicleMileageCard === "custom" && (
          <Grid item>
            <TextField
              label="Miles driven per year"
              value={
                yearlyVehicleMileageValue === 0
                  ? "0"
                  : yearlyVehicleMileageValue
              }
              setValue={editVehicle("yearlyVehicleMileageValue")}
              helperText={
                yearlyVehicleMileageValue < 0 ||
                (!!yearlyVehicleMileageValue &&
                  !numbersRegExpTest(yearlyVehicleMileageValue))
                  ? "Please enter a non-negative number."
                  : "Please enter the number of miles you drive per year"
              }
              error={
                yearlyVehicleMileageValue < 0 ||
                (!!yearlyVehicleMileageValue &&
                  !numbersRegExpTest(yearlyVehicleMileageValue))
              }
              variant="filled"
              id="custom-vehicle-mileage-input"
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const useTravelStepsRows = ({ individual, editIndividual }) => {
  const [
    monthlyOtherTransportationTonsCo2e,
    setMonthlyOtherTransportationTonsCo2e,
  ] = useState(0);
  const [officeMonthlyCommuteTonsCo2e, setOfficeMonthlyCommuteTonsCo2e] =
    useState(0);
  const [homeWorkMonthlyTonsCo2e, setHomeWorkMonthlyTonsCo2e] = useState({});
  const [officeOneWayDistanceMi, setOfficeOneWayDistanceMi] = useState(0);

  const {
    vehicles = [],
    doYouHaveVehicles,
    selectedOtherTransportation = [],
  } = individual;

  const totalMonthlyVehicleTonsCo2e = vehicles.reduce(
    (currentTonsCo2e, { tonsCo2ePerMile = 0, yearlyVehicleMileageValue = 0 }) =>
      currentTonsCo2e + tonsCo2ePerMile * (yearlyVehicleMileageValue / 12),
    0
  );

  const isAllVehicleInputsComplete = vehicles.reduce(
    (isValid, { tonsCo2ePerMile }) => isValid && !!tonsCo2ePerMile,
    true
  );

  const addAnotherVehicle = () =>
    editIndividual("vehicles")([
      ...vehicles,
      {
        yearlyVehicleMileageValue: 13476,
        selectedVehicleMileageCard: "average",
        selectedVehicleFormType: 0,
      },
    ]);

  const buildVehicleInputsRow = doYouHaveVehicles
    ? [
        {
          value: isAllVehicleInputsComplete,
          input: (
            <Grid
              item
              container
              alignItems="center"
              spacing={2}
              direction="column"
            >
              {vehicles.map((vehicle, idx) => (
                <VehicleInput
                  individual={individual}
                  editIndividual={editIndividual}
                  idx={idx}
                  key={`vehicle-${idx}`}
                />
              ))}
              <Grid item>
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={mergeMainTheme}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => addAnotherVehicle()}
                    >
                      Add Another Vehicle
                    </Button>
                  </ThemeProvider>
                </StyledEngineProvider>
              </Grid>
            </Grid>
          ),
        },
      ]
    : [];

  const otherTransportation = [
    {
      value: "bus",
      title: "Bus",
      averageMonthlyMileage: 480,
    },
    {
      value: "lightRail",
      title: "Light Rail/Subway",
      averageMonthlyMileage: 320,
    },
    {
      value: "regionalRail",
      title: "Commuter Rail",
      averageMonthlyMileage: 224,
    },
    {
      value: "intercityRail",
      title: "Amtrak",
      averageMonthlyMileage: 320,
    },
    {
      value: "carpool",
      title: "Carpooling",
      averageMonthlyMileage: 400,
    },
    {
      value: "walkBike",
      title: "Walk/Bike",
      averageMonthlyMileage: 80,
    },
  ];

  const handleEditOtherTransportation = (selectedTransportation) => {
    editIndividual("selectedOtherTransportation")(selectedTransportation);

    const otherTransporationTonsCo2e = selectedTransportation.map(
      (transportationType) => {
        const { averageMonthlyMileage } = otherTransportation.find(
          ({ value }) => value === transportationType
        );

        return otherTransportationCarbon(
          transportationType,
          averageMonthlyMileage
        );
      }
    );

    return setMonthlyOtherTransportationTonsCo2e(
      otherTransporationTonsCo2e.reduce(
        (totalCo2e, currentCo2e = 0) => totalCo2e + currentCo2e,
        0
      )
    );
  };

  const isAllCommuteInputsComplete = () => {
    const isOfficeFieldsComplete = !!officeWorkAddress && officeDaysPerWk > 0;
    const isHomeFieldsComplete = homeSqFootage > 0 && homeDaysPerWk > 0;

    if (workType === "office") {
      return isOfficeFieldsComplete;
    }

    if (workType === "home") {
      return isHomeFieldsComplete;
    }

    return isOfficeFieldsComplete && isHomeFieldsComplete;
  };

  const {
    workType,
    officeWorkAddress,
    homeSqFootage,
    homeOfficeSqFootage,
    officeDaysPerWk = 0,
    homeDaysPerWk = 0,
    address,
  } = individual;
  const { state, zipCode } = address || {};

  const availableWorkTypes = [
    { value: "office", title: "Office" },
    { value: "hybrid", title: "Hybrid" },
    { value: "home", title: "Home" },
  ];

  useEffect(() => {
    const calculateOfficeWorkEmissions = async () => {
      const { totalMileage: oneWayMileage } = await fetchOurApi({
        path: "/calcs/cars/directions-mileage",
        method: "POST",
        data: {
          to: officeWorkAddress,
          from: address,
        },
        callback: (res) => res,
      });

      setOfficeOneWayDistanceMi(oneWayMileage);

      const oneWayCommuteEmissions = await fetchOurApi({
        path: "/calcs/cars/total-mileage-carbon",
        method: "POST",
        data: { mileage: Number(oneWayMileage) },
        callback: ({ tonsCo2e }) => tonsCo2e,
      });

      return setOfficeMonthlyCommuteTonsCo2e(
        oneWayCommuteEmissions * 2 * officeDaysPerWk
      );
    };

    if (officeWorkAddress && officeDaysPerWk >= 0) {
      calculateOfficeWorkEmissions();
    }
  }, [officeDaysPerWk, officeWorkAddress, address]);

  useEffect(() => {
    if (homeOfficeSqFootage && homeDaysPerWk >= 0) {
      fetchOurApi({
        path: "/calcs/utilities/monthly-home-office-carbon-from-sq-footage",
        method: "POST",
        data: {
          state,
          zipCode,
          homeSqFootage,
          homeOfficeSqFootage,
          daysPerWk: homeDaysPerWk,
        },
        callback: (res) => setHomeWorkMonthlyTonsCo2e(res),
      });
    }
  }, [homeDaysPerWk, homeOfficeSqFootage, homeSqFootage, state, zipCode]);

  const travelRows = [
    {
      NextStepInput: YesNoQuestion,
      nextStepInputProps: {
        lightBackground: true,
        question: "Do you own a vehicle?",
        onInputSelect: editIndividual("doYouHaveVehicles"),
        inputSelectPropName: "setValue",
        value: doYouHaveVehicles,
      },
      value: doYouHaveVehicles !== undefined,
    },
    ...buildVehicleInputsRow,
    {
      value: true,
      input: (
        <Grid container spacing={2} justifyContent="center" direction="column">
          <Grid item>
            <Typography variant="body1" color="textPrimary">
              Select the other forms of transportation that you use to get
              around each month.
            </Typography>
          </Grid>
          <Grid item>
            <GreenCheckButtonCards
              options={otherTransportation}
              setOption={handleEditOtherTransportation}
              selectedOption={selectedOtherTransportation}
              exclusive={false}
            />
          </Grid>
        </Grid>
      ),
    },
    {
      value: isAllCommuteInputsComplete(),
      input: (
        <Grid container spacing={2} alignItems="center" direction="column">
          <Grid item>
            <Typography variant="body1" color="textPrimary">
              Where do you work in a typical week?
            </Typography>
          </Grid>
          <Grid container item>
            <GreenCheckButtonCards
              options={availableWorkTypes}
              setOption={editIndividual("workType")}
              selectedOption={workType}
            />
          </Grid>
          <Grid item container spacing={2} justifyContent="center">
            {(workType === "office" || workType === "hybrid") && (
              <Grid item sm={6} xs={12}>
                <PlacesAutocomplete
                  place={officeWorkAddress}
                  editPlace={setAddress((value) =>
                    editIndividual("officeWorkAddress")(value)
                  )}
                  size="small"
                  label="What is your office address?"
                  helperText="We only need the physical address, not the suite number"
                  textFieldProps={{ variant: "filled" }}
                  id="office-address-autocomplete"
                />
              </Grid>
            )}
            {(workType === "home" || workType === "hybrid") && (
              <Grid item sm={6} xs={12}>
                <TextField
                  label="Home office square footage"
                  value={homeOfficeSqFootage === 0 ? "0" : homeOfficeSqFootage}
                  setValue={editIndividual("homeOfficeSqFootage")}
                  helperText={
                    homeOfficeSqFootage < 0 ||
                    (!!homeOfficeSqFootage &&
                      !numbersRegExpTest(homeOfficeSqFootage))
                      ? "Please enter a non-negative number."
                      : "Enter the square footage of your home office."
                  }
                  error={
                    homeOfficeSqFootage < 0 ||
                    (!!homeOfficeSqFootage &&
                      !numbersRegExpTest(homeOfficeSqFootage))
                  }
                  variant="filled"
                  id="home-office-sq-footage-input"
                />
              </Grid>
            )}
          </Grid>
          <Grid item container spacing={2} justifyContent="center">
            {(workType === "office" || workType === "hybrid") &&
              officeWorkAddress && (
                <Grid item sm={6} xs={12}>
                  <PlusMinusNumericInput
                    value={officeDaysPerWk}
                    editValue={editIndividual("officeDaysPerWk")}
                    label="Days per week @ office"
                  />
                </Grid>
              )}
            {(workType === "home" || workType === "hybrid") &&
              homeOfficeSqFootage && (
                <Grid item sm={6} xs={12}>
                  <PlusMinusNumericInput
                    value={homeDaysPerWk}
                    editValue={editIndividual("homeDaysPerWk")}
                    label="Days per week @ home"
                  />
                </Grid>
              )}
          </Grid>
        </Grid>
      ),
    },
  ];

  return {
    travelSteps: travelRows.map((row) => ({ ...row, form: "travel" })),
    totalMonthlyVehicleTonsCo2e,
    monthlyOtherTransportationTonsCo2e,
    homeWorkMonthlyTonsCo2e,
    officeMonthlyCommuteTonsCo2e,
    officeOneWayDistanceMi,
  };
};

const useFlightsStepsRows = ({ individual, editIndividual }) => {
  const [domesticFlightsTonsCo2e, setDomesticFlightsTonsCo2e] = useState(0);
  const [internationalFlightsTonsCo2e, setInternationalFlightsTonsCo2e] =
    useState(0);

  const { numDomesticFlights, numInternationalFlights } = individual;

  const handleEditFlights = async (flightType, newNumFlights) => {
    editIndividual(flightType)(newNumFlights);

    return await fetchOurApi({
      path: "/calcs/travel/fetch-flights-carbon",
      method: "POST",
      data: {
        distance:
          flightType === "numDomesticFlights"
            ? US_DOMESTIC_FLIGHT_DISTANCE_MI
            : INTERNATIONAL_FLIGHT_DISTANCE_MI,
        passClass: "economy",
      },
      callback: ({ flightCarbonTons }) =>
        flightType === "numDomesticFlights"
          ? setDomesticFlightsTonsCo2e(flightCarbonTons * 2 * newNumFlights)
          : setInternationalFlightsTonsCo2e(
              flightCarbonTons * 2 * newNumFlights
            ),
    });
  };

  return {
    flightsSteps: [
      {
        value:
          numbersRegExpTest(numDomesticFlights) &&
          numDomesticFlights >= 0 &&
          numbersRegExpTest(numInternationalFlights) &&
          numInternationalFlights >= 0,
        input: (
          <Grid
            container
            spacing={2}
            justifyContent="center"
            direction="column"
          >
            <Grid item>
              <PlusMinusNumericInput
                value={numDomesticFlights}
                editValue={(newNumFlights) =>
                  handleEditFlights("numDomesticFlights", newNumFlights)
                }
                label="Number of roundtrip domestic flights"
              />
            </Grid>
            <Grid item>
              <PlusMinusNumericInput
                value={numInternationalFlights}
                editValue={(newNumFlights) =>
                  handleEditFlights("numInternationalFlights", newNumFlights)
                }
                label="Number of roundtrip international flights"
              />
            </Grid>
          </Grid>
        ),
        form: "flights",
      },
    ],
    totalFlightsTonsCo2e:
      domesticFlightsTonsCo2e + internationalFlightsTonsCo2e,
  };
};

const useDietStepsRows = ({ individual, editIndividual }) => {
  const [monthlyDietTypeTonsCo2e, setMonthlyDietTypeTonsCo2e] =
    useState(0.11641666666666667);

  const { dietType, numPeople } = individual;

  const dietOptions = [
    {
      title: "Standard American Diet",
      value: "sad",
      qualities: [
        "High in red/processed meats",
        "Moderate to low in fruits & vegetables",
        "Low in whole grains and high in refined grains",
      ],
    },
    {
      title: "Less Beef, More Leaf",
      value: "had-1",
      qualities: [
        "1/2 red/processed meats",
        "2x fruits & vegetables",
        "4x whole grains and 1/3 refined grains",
      ],
    },
    {
      title: "Healthy Omnivore",
      value: "had-2",
      qualities: [
        "1/4 red/processed meats",
        "2x fruits & vegetables",
        "4x whole grains and 1/3 refined grains",
      ],
    },
    {
      title: "Vegetarian/Vegan",
      value: "had-3",
      qualities: [
        "No red/processed meats",
        "2.5x fruits & vegetables",
        "4x whole grains and 1/3 refined grains",
      ],
    },
  ];
  const { qualities: currentDietQualities } =
    dietOptions.find(({ value }) => value === dietType) || {};

  useEffect(() => {
    editIndividual("dietType")(dietType);

    return setMonthlyDietTypeTonsCo2e(
      monthlyDietTypeCarbon(dietType, numPeople)
    );
  }, [editIndividual, numPeople, dietType]);

  return {
    dietSteps: [
      {
        value: true,
        input: (
          <Grid
            container
            justifyContent="center"
            spacing={2}
            direction="column"
          >
            <Grid item>
              <GreenCheckButtonCards
                options={dietOptions}
                setOption={editIndividual("dietType")}
                selectedOption={dietType}
              />
            </Grid>
            {!!currentDietQualities && (
              <Grid item>
                {dietType !== "sad" && (
                  <Typography variant="body1" color="textPrimary">
                    Compared to the Standard American Diet:
                  </Typography>
                )}
                {currentDietQualities.map((quality, idx) => (
                  <Typography
                    variant="body2"
                    key={`diet-quality-${idx}`}
                    color="textPrimary"
                  >
                    - {quality}
                  </Typography>
                ))}
              </Grid>
            )}
          </Grid>
        ),
        form: "diet",
      },
    ],
    monthlyDietTypeTonsCo2e,
  };
};

const AnnualExpenditureCategoryCard = ({
  category,
  individual,
  uneditedAnnualExpenditures,
  editExpendituresObj,
}) => {
  const { annualExpenditures, numPeople } = individual;
  const {
    annualHouseholdExpenditures,
    annualPerCapitaExpenditures,
    selectedExpenditureCard = "",
  } = annualExpenditures[category];
  const {
    annualHouseholdExpenditures: uneditedAnnualHouseholdExpenditures,
    annualPerCapitaExpenditures: uneditedAnnualPerCapitaExpenditures,
  } = uneditedAnnualExpenditures[category];
  const annualExpenditureValue =
    numPeople > 1 ? annualHouseholdExpenditures : annualPerCapitaExpenditures;
  const uneditedAnnualExpenditureValue =
    numPeople > 1
      ? uneditedAnnualHouseholdExpenditures
      : uneditedAnnualPerCapitaExpenditures;

  const perMonthExpenditureCategories = ["manufacturedProducts", "services"];
  const isCategoryDisplayedPerMonth =
    perMonthExpenditureCategories.includes(category);
  const expenditureValueWithTimeframe = isCategoryDisplayedPerMonth
    ? uneditedAnnualExpenditureValue / 12
    : uneditedAnnualExpenditureValue;
  const timeframe = isCategoryDisplayedPerMonth ? "month" : "year";

  const categoryLabelsAndSubtitles = {
    clothing: {
      title: "Clothing",
      subtitle: "Clothing, footwear, and other apparel services.",
    },
    manufacturedProducts: {
      title: "Manufactured Products",
      subtitle:
        "Housekeeping supplies, furniture, appliances, personal care products, etc.",
    },
    services: {
      title: "Services",
      subtitle: "Utilities, telephone bills, health insurance, education, etc.",
    },
  };
  const { title, subtitle } = categoryLabelsAndSubtitles[category];

  const expenditureCategoryOptions = [
    {
      title: "Below Average",
      subtitle: `$${formatDecimal(
        expenditureValueWithTimeframe / 2
      )}/${timeframe}`,
      value: "belowAverage",
      expenditureValue: uneditedAnnualExpenditureValue / 2,
    },
    {
      title: "Average",
      subtitle: `$${formatDecimal(expenditureValueWithTimeframe)}/${timeframe}`,
      value: "average",
      expenditureValue: uneditedAnnualExpenditureValue,
    },
    {
      title: "Above Average",
      subtitle: `$${formatDecimal(
        expenditureValueWithTimeframe * 2
      )}/${timeframe}`,
      value: "aboveAverage",
      expenditureValue: uneditedAnnualExpenditureValue * 2,
    },
    {
      title: "Custom",
      value: "custom",
      expenditureValue: 0,
    },
  ];

  const generateNewAnnualExpendituresObj = (
    newAnnualExpenditures,
    otherValues = {}
  ) => {
    const valueKey =
      numPeople > 1
        ? "annualHouseholdExpenditures"
        : "annualPerCapitaExpenditures";

    return {
      ...annualExpenditures,
      [category]: {
        ...annualExpenditures[category],
        [valueKey]: Number(newAnnualExpenditures),
        ...otherValues,
      },
    };
  };

  const editAnnualExpenditures = (newAnnualExpenditures) => {
    if (isNaN(newAnnualExpenditures)) {
      return null;
    }

    return editExpendituresObj(
      generateNewAnnualExpendituresObj(Number(newAnnualExpenditures))
    );
  };

  const handleSetExpenditureOption = (newValue) => {
    const { expenditureValue } =
      expenditureCategoryOptions.find(({ value }) => value === newValue) || {};

    return editExpendituresObj(
      generateNewAnnualExpendituresObj(expenditureValue, {
        selectedExpenditureCard: newValue,
      })
    );
  };

  return (
    <Grid container spacing={2} direction="column">
      <Grid item container alignItems="center" direction="column">
        <Grid item>
          <Typography variant="body1" color="textPrimary">
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="center" variant="subtitle2" color="textSecondary">
            {subtitle}
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <GreenCheckButtonCards
          options={expenditureCategoryOptions}
          selectedOption={selectedExpenditureCard}
          setOption={handleSetExpenditureOption}
        />
      </Grid>
      {selectedExpenditureCard === "custom" && (
        <Grid item>
          <TextField
            label={`$ per ${timeframe}`}
            value={
              annualExpenditureValue === 0
                ? "0"
                : isCategoryDisplayedPerMonth
                ? annualExpenditureValue / 12
                : annualExpenditureValue
            }
            setValue={(newValue) =>
              isCategoryDisplayedPerMonth
                ? editAnnualExpenditures(newValue * 12)
                : editAnnualExpenditures(newValue)
            }
            helperText={
              (annualExpenditureValue < 0 ||
                (!!annualExpenditureValue &&
                  !numbersRegExpTest(annualExpenditureValue))) &&
              "Please enter a non-negative number."
            }
            error={
              annualExpenditureValue < 0 ||
              (!!annualExpenditureValue &&
                !numbersRegExpTest(annualExpenditureValue))
            }
            variant="filled"
            id="annual-expenditures-input"
          />
        </Grid>
      )}
    </Grid>
  );
};

const useSpendingStepsRows = ({
  individual,
  editIndividual,
  existingEmail,
  onSurveyFinish,
}) => {
  const [uneditedAnnualExpenditures, setUneditedAnnualExpenditures] = useState(
    {}
  );
  const [expendituresCategoriesTonsCo2e, setExpendituresCategoriesTonsCo2e] =
    useState({});

  const { annualHouseholdIncome, annualExpenditures, numPeople } = individual;

  const totalYearlyExpendituresTonsCo2e = Object.keys(
    expendituresCategoriesTonsCo2e
  ).reduce((total, categoryKey) => {
    const {
      estimatedHouseholdFootprintTonsCo2e = 0,
      estimatedPerCapitaFootprintTonsCo2e = 0,
    } = expendituresCategoriesTonsCo2e[categoryKey];

    if (numPeople === 1) {
      return total + estimatedPerCapitaFootprintTonsCo2e;
    }

    return total + estimatedHouseholdFootprintTonsCo2e;
  }, 0);

  const incomeSliderMarks = Array.from({ length: 31 }).map((val, idx) => {
    const markValue = idx * 10000;

    if (!idx || idx === 30) {
      return {
        value: markValue,
        label: `$${Math.trunc(markValue / 1000)}k`,
      };
    }

    return { value: markValue };
  });

  const editExpendituresObj = (newExpendituresObj) =>
    editIndividual("annualExpenditures")(newExpendituresObj);

  const expenditureCardInputs =
    annualExpenditures && uneditedAnnualExpenditures
      ? Object.keys(annualExpenditures).map((categoryKey, idx) => {
          if (
            existingEmail &&
            idx === Object.keys(annualExpenditures).length - 1
          ) {
            return {
              value: true,
              input: (
                <AnnualExpenditureCategoryCard
                  category={categoryKey}
                  individual={individual}
                  uneditedAnnualExpenditures={uneditedAnnualExpenditures}
                  editExpendituresObj={editExpendituresObj}
                />
              ),
              form: "spending",
              submitButtonText: "Finish",
              onNextStep: onSurveyFinish,
            };
          }

          return {
            value: true,
            NextStepInput: AnnualExpenditureCategoryCard,
            nextStepInputProps: {
              category: categoryKey,
              individual,
              uneditedAnnualExpenditures,
              onInputSelect: editExpendituresObj,
              inputSelectPropName: "editExpendituresObj",
            },
            form: "spending",
          };
        })
      : [];

  useEffect(() => {
    if (annualExpenditures) {
      fetchOurApi({
        path: "/calcs/individuals/expenditures-carbon",
        method: "POST",
        data: {
          expenditures: annualExpenditures,
        },
        callback: (res) => setExpendituresCategoriesTonsCo2e(res),
      });
    }
  }, [annualExpenditures]);

  const handleSliderOnChange = async (newValue) => {
    editIndividual("annualHouseholdIncome")(newValue);

    const annualExpenditures = await fetchOurApi({
      path: "/calcs/individuals/expenditures-from-annual-household-income",
      method: "POST",
      data: {
        annualHouseholdIncome: newValue,
      },
      callback: (res) => res,
    });

    setUneditedAnnualExpenditures(annualExpenditures);
    return editIndividual("annualExpenditures")(annualExpenditures);
  };

  return {
    spendingSteps: [
      {
        value: annualHouseholdIncome,
        input: (
          <Grid container spacing={2} direction="column" alignItems="center">
            <Grid item>
              <Typography variant="body1" color="textPrimary">
                Select the value that is closest to your annual household income
              </Typography>
            </Grid>
            <Grid item container justifyContent="center">
              <Box
                display="flex"
                p={2}
                style={{ width: "85%", height: "100%" }}
              >
                <Slider
                  step={null}
                  value={annualHouseholdIncome}
                  onChange={(e, newValue) => handleSliderOnChange(newValue)}
                  marks={incomeSliderMarks}
                  min={0}
                  max={300000}
                  components={{
                    ValueLabel: PercentageValueLabel,
                  }}
                  valueLabelDisplay="on"
                  valueLabelFormat={(selectedValue) => {
                    if (!selectedValue) {
                      return "$0";
                    }
                    if (selectedValue === 300000) {
                      return ">= $300k";
                    }

                    return `$${Math.trunc(selectedValue / 1000)}k`;
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        ),
        form: "spending",
      },
      ...expenditureCardInputs,
    ],
    totalYearlyExpendituresTonsCo2e,
  };
};

const useEmailStep = ({ individual, editIndividual, onSurveyFinish }) => {
  const { email, name, optIntoMarketingEmails } = individual;

  return [
    {
      value: name && email && emailRegExpTest(email),
      input: (
        <Grid container spacing={2} direction="column" alignItems="center">
          <Grid item container>
            <TextField
              value={name}
              setValue={editIndividual("name")}
              label="What's your first and last name?"
            />
          </Grid>
          <Grid item container>
            <TextField
              value={email}
              setValue={editIndividual("email")}
              label="Email address"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={optIntoMarketingEmails}
                  onChange={() =>
                    editIndividual("optIntoMarketingEmails")(
                      !optIntoMarketingEmails
                    )
                  }
                />
              }
              label={
                <Typography variant="body2" color="textPrimary">
                  I want to receive fun and educational climate info
                  periodically
                </Typography>
              }
            />
          </Grid>
        </Grid>
      ),
      form: "email",
      submitButtonText: "Finish",
      onNextStep: onSurveyFinish,
    },
  ];
};

const MyAclymateSurvey = ({
  setIndividualData,
  setTotalTonsCo2e,
  formStep,
  setFormStep,
  existingEmail,
  existingIndividualId,
}) => {
  const [, setCookie] = useCookies();
  const { theme, isMedium } = useLayoutHelpers();

  const [individual, setIndividual] = useState({
    numPeople: 0,
    numRoommates: 0,
    numDogs: 0,
    numCats: 0,
    numLargeDogs: 0,
    electricityRenewablesPercentage: 0,
    selectedHeatingTypes: [],
    utilityUsage: {
      electricity: {
        value: 917,
        unit: "kWh",
        selectedCard: "average",
      },
      naturalGas: {
        value: 67,
        unit: "therms",
        selectedCard: "average",
      },
      propane: {
        value: 441,
        unit: "gallons",
        selectedCard: "average",
      },
      wood: {
        value: 1.5,
        unit: "cords",
        selectedCard: "average",
      },
      heatingOil: {
        value: 41,
        unit: "gallons",
        selectedCard: "average",
      },
    },
    vehicles: [
      {
        yearlyVehicleMileageValue: 13476,
        selectedVehicleMileageCard: "average",
        selectedVehicleFormType: 0,
      },
    ],
    numDomesticFlights: 0,
    numInternationalFlights: 0,
    dietType: "sad",
    annualHouseholdIncome: 0,
    optIntoMarketingEmails: true,
    email: existingEmail,
  });

  const editIndividual = (field) => (value) =>
    editObjectData(setIndividual, field, value);

  const { residenceSteps, monthlyHomeTonsCo2e, monthlyPetsTonsCo2e } =
    useResidenceStepsRows({
      individual,
      editIndividual,
    });
  const {
    travelSteps,
    totalMonthlyVehicleTonsCo2e,
    monthlyOtherTransportationTonsCo2e,
    homeWorkMonthlyTonsCo2e,
    officeMonthlyCommuteTonsCo2e,
    officeOneWayDistanceMi,
  } = useTravelStepsRows({
    individual,
    editIndividual,
  });
  const { flightsSteps, totalFlightsTonsCo2e } = useFlightsStepsRows({
    individual,
    editIndividual,
  });
  const { dietSteps, monthlyDietTypeTonsCo2e } = useDietStepsRows({
    individual,
    editIndividual,
  });

  const onSurveyFinish = async () => {
    const {
      individualId,
      individual: individualData,
      doesAclymateAccountAlreadyExist,
    } = await fetchOurApi({
      path: "/individuals/individuals-website-survey-submit",
      method: "POST",
      data: {
        individual,
        individualId: existingIndividualId,
        monthlyHomeTonsCo2e,
        homeWorkMonthlyTonsCo2e,
        officeOneWayDistanceMi,
      },
      callback: (res) => res,
    });

    if (!individualId) {
      return null;
    }

    const { email, slug } = individualData;

    setCookie("aclymate-in-slug", slug);
    analyticsTrack("myAclymate Survey Submit", { email });

    return setIndividualData((currentIndividualData) => ({
      id: individualId,
      ...currentIndividualData,
      ...individualData,
      doesAclymateAccountAlreadyExist,
    }));
  };

  const { spendingSteps, totalYearlyExpendituresTonsCo2e } =
    useSpendingStepsRows({
      individual,
      editIndividual,
      existingEmail,
      onSurveyFinish,
    });
  const emailStep = useEmailStep({
    individual,
    editIndividual,
    onSurveyFinish,
  });

  const emissionsData = {
    monthlyHomeTonsCo2e,
    monthlyPetsTonsCo2e,
    totalMonthlyVehicleTonsCo2e,
    totalFlightsTonsCo2e,
    monthlyOtherTransportationTonsCo2e,
    monthlyDietTypeTonsCo2e,
    totalYearlyExpendituresTonsCo2e,
    officeMonthlyCommuteTonsCo2e,
    homeWorkMonthlyTonsCo2e,
  };
  const yearlyTonsCo2eObj = getYearlyTonsCo2eValues(emissionsData);
  const { yearlyTotalTonsCo2e } = yearlyTonsCo2eObj;

  useEffect(
    () => setTotalTonsCo2e(yearlyTotalTonsCo2e),
    [setTotalTonsCo2e, yearlyTotalTonsCo2e]
  );

  const emailForm = !existingEmail
    ? [
        {
          name: "email",
          label: "Email",
          title: "What is your email?",
        },
      ]
    : [];
  const emailSteps = !existingEmail ? emailStep : [];

  const individualSurveyForms = [
    {
      name: "residence",
      label: "Residence",
      title: "Let's start with home sweet home",
    },
    {
      name: "travel",
      label: "Travel",
      title: "Next, how do you get around?",
    },
    {
      name: "flights",
      label: "Flights",
      title: "What flights do you take in a typical year?",
    },
    {
      name: "diet",
      label: "Diet",
      title: "What is your diet on an average day?",
    },
    {
      name: "spending",
      label: "Spending",
      title: "What do you buy?",
    },
    ...emailForm,
  ];

  const individualSurveyRows = [
    ...residenceSteps,
    ...travelSteps,
    ...flightsSteps,
    ...dietSteps,
    ...spendingSteps,
    ...emailSteps,
  ];

  return (
    <Grid container spacing={2} style={{ marginBottom: 0 }}>
      <Grid item container xs={isMedium ? 12 : 7} spacing={2}>
        <Grid item>
          <Typography
            variant="h4"
            component="h1"
            align="center"
            sx={{ fontWeight: 600 }}
          >
            Discover your carbon footprint in minutes!
          </Typography>
        </Grid>
        <Grid item>
          <Box p={2} id="myaclymate-form-container">
            <MultiPartFormLayout
              title="My Carbon Footprint"
              subtitle={`Take a brief survey to learn your personal carbon footprint and what you can do to help fight climate change. 
            ${
              !isMedium
                ? `Watch the "footprint" change as you fill the survey. Hover over the toes to see how much each category contributes to 
            your overall carbon footprint. `
                : " "
            }The information you provide is secure and only used to calculate your carbon footprint.`}
              color={theme.palette.secondary.main}
              forms={individualSurveyForms}
              rows={individualSurveyRows}
              confirmClose={false}
              theme={mainTheme}
              customIcon={
                <FontAwesomeIcon
                  icon={faShoePrints}
                  size="5x"
                  style={{
                    padding: "15px",
                    color: theme.palette.secondary.main,
                    rotate: "270deg",
                  }}
                />
              }
              activeFormStep={formStep}
              setActiveFormStep={setFormStep}
            />
          </Box>
        </Grid>
      </Grid>
      {!isMedium && (
        <Grid item xs={5}>
          <FootprintDisplay
            yearlyTonsCo2eObj={yearlyTonsCo2eObj}
            activeFormStep={formStep}
          />
        </Grid>
      )}
    </Grid>
  );
};
export default MyAclymateSurvey;
