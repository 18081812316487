import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export const findEmissionsSinceLastMonth = (emissions) => {
  if (!emissions?.length) {
    return [];
  }

  const emissionsThisMonth = emissions.filter(({ date }) =>
    dayjs(date).isSame(dayjs(), "month")
  );

  if (emissionsThisMonth.length) {
    return emissionsThisMonth;
  }

  const lastMonth = dayjs().subtract(1, "month");

  return emissions.filter(({ date }) => dayjs(date).isSame(lastMonth, "month"));
};

export const filterByDateRange = ({
  filterStartDate,
  filterEndDate,
  date: emissionDate,
}) => {
  const formattedFilterStartDate = dayjs.isDayjs(filterStartDate)
    ? filterStartDate
    : dayjs(filterStartDate);
  const formattedFilterEndDate = dayjs.isDayjs(filterEndDate)
    ? filterEndDate
    : dayjs(filterEndDate);

  if (
    (!filterStartDate && !filterEndDate) ||
    formattedFilterStartDate?.isSameOrAfter(formattedFilterEndDate)
  ) {
    return true;
  }

  if (!filterEndDate) {
    return formattedFilterStartDate.isSameOrBefore(emissionDate);
  }

  if (!filterStartDate) {
    return formattedFilterEndDate.isSameOrAfter(emissionDate);
  }

  return (
    formattedFilterStartDate.isSameOrBefore(emissionDate) &&
    formattedFilterEndDate.isSameOrAfter(emissionDate)
  );
};
