import React, { useContext, useState } from "react";
import { useParams } from "react-router";

import {
  Box,
  Grid,
  Button,
  CssBaseline,
  Typography,
  Divider,
  Snackbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Tooltip,
  useMediaQuery,
  Alert,
  useTheme,
} from "@mui/material";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CloseIcon from "@mui/icons-material/Close";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faLeaf } from "@fortawesome/free-solid-svg-icons";
import {
  faListCheck,
  faFileChartLine,
  faBadgeCheck,
  faBooks,
  faPodiumStar,
} from "@fortawesome/pro-solid-svg-icons";

import { Popper } from "@aclymatepackages/atoms";

import Link from "../atoms/mui/Link";

import TopBar from "../modules/TopBar";

import {
  PlatformLayoutContextProvider,
  PlatformLayoutContext,
} from "../../helpers/contexts/platformLayout";
import { EmissionsContextProvider } from "../../helpers/contexts/emissions";
import SandboxDataContextProvider from "../../helpers/contexts/sandboxData";
import { useAccountData } from "../../helpers/firebase";
import AdminDataContextProvider from "../../helpers/contexts/adminData";
import { useShowOnboardingPage } from "../../helpers/hooks/companyData";

const AlertSnackbar = ({
  open,
  setOpen,
  onClose,
  alert,
  message,
  action,
  customButton,
  anchorOrigin = { vertical: "top", horizontal: "center" },
  autoHideDuration = 10000,
  ...otherProps
}) => {
  const handleClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }

    return setOpen(false);
  };

  const ActionButtons = () => (
    <>
      {customButton
        ? customButton
        : !!onClose && (
            <Button
              onClick={() => {
                handleClose();
                onClose();
              }}
              size="small"
              id="snackbarActionBtn"
            >
              {action || "Yes"}
            </Button>
          )}
      <IconButton onClick={handleClose} size="small" id="snackbarCloseIconBtn">
        <CloseIcon />
      </IconButton>
    </>
  );

  return (
    <>
      {!!alert ? (
        <Snackbar
          open={open}
          onClose={handleClose}
          anchorOrigin={anchorOrigin}
          autoHideDuration={autoHideDuration}
          style={{ zIndex: 1501 }}
          {...otherProps}
        >
          <Alert
            severity={alert}
            onClose={handleClose}
            action={<ActionButtons />}
          >
            {message}
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          open={open}
          message={message}
          onClose={handleClose}
          anchorOrigin={anchorOrigin}
          autoHideDuration={autoHideDuration}
          style={{ zIndex: 1501 }}
          {...otherProps}
          action={<ActionButtons />}
        />
      )}
    </>
  );
};

const ClimecoLogoPopper = ({ sideNavOpen }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      {anchorEl && (
        <Popper
          isOpen={!!anchorEl}
          anchorEl={anchorEl}
          style={{ maxWidth: "300px" }}
          placement="right-end"
          content={
            <Box p={1}>
              <Typography variant="body2">
                Climeco has partnered with Aclymate to bring your business this
                premium carbon accounting tool with exclusive access to premium
                Climeco Offsets
              </Typography>
            </Box>
          }
        />
      )}
      <Box
        display="flex"
        justifyContent="center"
        p={1}
        onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
        onMouseLeave={() => setAnchorEl(null)}
      >
        {sideNavOpen ? (
          <img
            src="/images/partner-logos/climeco-logo.png"
            style={{ maxWidth: "200px" }}
            alt="climeco logo"
          />
        ) : (
          <img
            src="/images/partner-logos/climeco.png"
            width="56px"
            alt="climeco icon"
          />
        )}
      </Box>
    </>
  );
};

const SideNav = () => {
  const { palette } = useTheme();

  const [showOnboardingPage, showOnboardingPageLoading] =
    useShowOnboardingPage();

  const { sideNavOpen, setSideNavOpen } = useContext(PlatformLayoutContext);

  const [{ referralPartner }] = useAccountData();

  const toggleSideNav = () =>
    setSideNavOpen((open) => {
      window.localStorage.setItem("sideNavOpen", !open);
      return !open;
    });

  const onboardingNavRow = showOnboardingPage
    ? [{ name: "Onboarding", icon: faListCheck, path: "onboarding" }]
    : [];

  const navPages = showOnboardingPageLoading
    ? []
    : [
        ...onboardingNavRow,
        {
          name: "Dashboard",
          icon: faHome,
          path: "dashboard",
        },
        { name: "Events", icon: faPodiumStar, path: "events" },
        { name: "Accounting", icon: faBooks, path: "accounting" },
        {
          name: "Purchase Offsets",
          icon: faLeaf,
          path: "purchase",
        },
        { name: "Reporting", icon: faFileChartLine, path: "reporting" },
        { name: "Certifications Basecamp", icon: faBadgeCheck, path: "badges" },
      ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{
        height: "100vh",
        background: "white",
        width: sideNavOpen ? "240px" : "72px",
        transition: "1s",
      }}
    >
      <Box
        p={1}
        style={{
          boxSizing: "content-box",
          height: sideNavOpen ? "36px" : "56px",
        }}
      >
        {sideNavOpen ? (
          <img
            src="/images/aclymate-full-logo.png"
            alt="RWT logo"
            style={{ maxWidth: "200px" }}
          />
        ) : (
          <img
            src="/images/aclymate-icon.png"
            alt="RWT logo"
            style={{ width: "56px" }}
          />
        )}
      </Box>
      <Divider />
      <Box
        flexGrow={1}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box>
          {sideNavOpen ? (
            <List>
              {navPages.map(({ name, icon, path }, idx) => (
                <Link
                  key={`nav-link-${idx}`}
                  href={`/platform/company/${path}`}
                  id={`${name.replace(" ", "")}Btn`}
                >
                  <ListItem button>
                    <ListItemIcon
                      style={{
                        minWidth: "48px",
                        color: palette.primary.main,
                      }}
                    >
                      <FontAwesomeIcon icon={icon} size="2x" />
                    </ListItemIcon>
                    <ListItemText
                      primary={name}
                      primaryTypographyProps={{
                        variant: "h6",
                        noWrap: true,
                      }}
                    />
                  </ListItem>
                </Link>
              ))}
            </List>
          ) : (
            <Grid container direction="column" alignItems="center">
              {navPages.map(({ name, icon, path }, idx) => (
                <Grid item key={`nav-icon-${idx}`}>
                  <Tooltip title={name}>
                    <span>
                      <Link href={`/platform/company/${path}`}>
                        <IconButton
                          color="primary"
                          id={`${name.replace(" ", "")}Btn`}
                          size="large"
                        >
                          <FontAwesomeIcon icon={icon} size="1x" />
                        </IconButton>
                      </Link>
                    </span>
                  </Tooltip>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
        {referralPartner === "climeco" && <ClimecoLogoPopper />}
      </Box>
      <Divider />
      <Box display="flex" justifyContent="center">
        <IconButton onClick={toggleSideNav} size="large">
          {sideNavOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Box>
    </Box>
  );
};

const Layout = ({ children }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  const { page } = useParams();

  const { snackbarOpen, setSnackbarOpen, snackbarProps } = useContext(
    PlatformLayoutContext
  );

  if (isSmall && process.env.REACT_APP_ENVIRONMENT === "production") {
    return (
      <Box
        p={2}
        style={{ height: `calc(100vh - ${theme.spacing(4)})` }}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <img
          src="/images/aclymate-vertical-logo.png"
          style={{ width: "80%", maxWidth: "350px" }}
          alt="aclymate logo"
        />
        <Box py={4}>
          <Typography variant="subtitle2" align="center" color="textPrimary">
            Aclymate needs to be accessed through a laptop or larger device size
            and will not work on a tablet or phone
          </Typography>
        </Box>
        <Button variant="contained" color="primary" href="https://aclymate.com">
          Go back to Website
        </Button>
      </Box>
    );
  }

  return (
    <>
      <AlertSnackbar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        {...snackbarProps}
      />
      <CssBaseline />
      <Box
        display="flex"
        style={{ height: "100vh", overflow: "hidden" }}
        alignItems="stretch"
      >
        <SideNav />
        <Box
          flexGrow={1}
          style={{ overflow: "hidden", height: "100%" }}
          display="flex"
          flexDirection="column"
        >
          {!["dashboard", "purchase"].includes(page) && <TopBar />}
          <Box flexGrow={1} style={{ overflowY: "auto" }} position="relative">
            {children}
          </Box>
        </Box>
      </Box>
    </>
  );
};

const CompanyLayout = ({ children, ...props }) => (
  <PlatformLayoutContextProvider {...props}>
    <AdminDataContextProvider>
      <SandboxDataContextProvider>
        <EmissionsContextProvider>
          <Layout {...props}>{children}</Layout>
        </EmissionsContextProvider>
      </SandboxDataContextProvider>
    </AdminDataContextProvider>
  </PlatformLayoutContextProvider>
);
export default CompanyLayout;
