import React, { useState } from "react";

import { Grid, IconButton, Tooltip, Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { Autocomplete, TextField } from "@aclymatepackages/atoms";

import InputBlock from "./InputBlock";
import NaicsCodesAutocomplete from "../autocomplete/NaicsCodesAutocomplete";
import ScopeThreeCategorySelect from "../vendors/ScopeThreeCategorySelect";

import { useVendorData } from "../../../helpers/components/vendors";
import { useCachedFirebaseCrud } from "../../../helpers/firebase";
import { useCachedDisplayData } from "../../../helpers/firebase";

const NewVendorForm = ({
  setVendor,
  setShowNewVendorForm,
  transactionSubcategory,
}) => {
  const { newCollectionDoc } = useCachedFirebaseCrud();
  const [vendors] = useCachedDisplayData("vendors");

  const [newVendor, setNewVendor] = useState({ id: "new-vendor" });

  const editNewVendor = (field) => (value) =>
    editObjectData(setNewVendor, field, value);

  const { name = "", naicsCode, scopeThreeCategory } = newVendor;

  const isDuplicateVendorName = !!vendors.find(
    (vendor) => vendor.name.toLowerCase() === name.toLowerCase()
  );

  const isButtonDisabled =
    isDuplicateVendorName ||
    !name ||
    (transactionSubcategory === "spend-based" &&
      (!scopeThreeCategory || !naicsCode));

  const onNewVendorSave = async () => {
    setShowNewVendorForm(false);
    const newVendorCategory = {
      ...newVendor,
      emissionCategory: transactionSubcategory,
    };
    const id = await newCollectionDoc("vendors", newVendorCategory);
    return setVendor({
      ...newVendorCategory,
      id,
    });
  };

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <TextField
          label="Vendor Name"
          value={name}
          setValue={editNewVendor("name")}
          error={isDuplicateVendorName}
          helperText={
            isDuplicateVendorName && "A vendor with this name already exists"
          }
        />
      </Grid>
      {transactionSubcategory === "spend-based" && (
        <>
          <Grid item>
            <NaicsCodesAutocomplete
              naicsCode={naicsCode}
              editVendor={editNewVendor}
            />
          </Grid>
          <Grid item>
            <ScopeThreeCategorySelect
              scopeThreeCategory={scopeThreeCategory}
              setScopeThreeCategory={editNewVendor("scopeThreeCategory")}
            />
          </Grid>
        </>
      )}
      <Grid item container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Tooltip title="Back to select a vendor">
            <IconButton onClick={() => setShowNewVendorForm(false)}>
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Button
            disabled={isButtonDisabled}
            variant="contained"
            color="primary"
            onClick={onNewVendorSave}
          >
            Add Vendor
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const VendorsInput = ({
  vendor,
  setVendor,
  transactionSubcategory,
  ...otherProps
}) => {
  const { displayVendors } = useVendorData();

  const [showNewVendorForm, setShowNewVendorForm] = useState(false);

  const categoryVendors = displayVendors.filter(
    ({ emissionCategory }) => emissionCategory === transactionSubcategory
  );
  const uncategorizedVendors = displayVendors.filter(
    ({ emissionCategory }) => !emissionCategory
  );

  const selectableVendors = [...categoryVendors, ...uncategorizedVendors];

  return (
    <InputBlock
      type="vendors"
      title={
        showNewVendorForm
          ? `Add a new ${transactionSubcategory} vendor`
          : "Tag a vendor for this transaction"
      }
      cardData={vendor}
      {...otherProps}
    >
      {showNewVendorForm ? (
        <NewVendorForm
          setVendor={setVendor}
          setShowNewVendorForm={setShowNewVendorForm}
          transactionSubcategory={transactionSubcategory}
        />
      ) : (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item xs={10}>
            <Autocomplete
              availableOptions={selectableVendors}
              collection="vendors"
              setValue={setVendor}
              value={vendor}
            />
          </Grid>
          <Grid item>
            <Tooltip title="Add a vendor">
              <IconButton
                onClick={() => {
                  setVendor(null);
                  return setShowNewVendorForm(true);
                }}
              >
                <AddCircleIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      )}
    </InputBlock>
  );
};
export default VendorsInput;
